import React, { useState, useEffect } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import { TableItemSumberDayaSection } from "../Section/TableItemSumberDayaSection";
import { SubKegiatanApi } from "api";
import Axios from "axios";
import { TableAsetSection, TableItemMaterialManusiaSection } from "../Section";

export const TabItemSumberDaya = ({ type, data }) => {
  const [dropdown, setDropdown] = useState({
    kategori: [],
    itemSumberDayaMaterial: [],
    itemSumberDayaManusia: [],
    itemSumberDayaPeralatan: [],
    itemSumberDayaOverhead: [],
    itemSumberDayaLainnya: [],
    sumberPengadaan: [],
  });

  const dataMapping = {
    detailMaterial: data?.material
      ? data?.material?.map((val) => ({
          idItemSubKegiatan: val.id_item_sub_kegiatan_material,
          hargaSatuan: val.harga_satuan,
          namaSatuan: val.nama_satuan,
          idSumberPengadaan: val.id_sumber_pengadaan,
          namaSumberPengadaan: val.nama_sumber_pengadaan,
          qty: val.qty_item_sub_kegiatan_material,
          idKategori: val.id_kategori_anggaran,
          namaKategori: val.nama_kategori_anggaran,
          idItemSumberDaya: val.id_item_bahan,
          namaItemSumberDaya: val.nama_item,
        }))
      : [],
    detailManusia: data?.manusia
      ? data?.manusia?.map((val) => ({
          idItemSubKegiatan: val.id_item_sub_kegiatan_manusia,
          hargaSatuan: val.harga_satuan,
          namaSatuan: val.nama_satuan,
          idSumberPengadaan: val.id_sumber_pengadaan,
          namaSumberPengadaan: val.nama_sumber_pengadaan,
          qty: val.qty_item_sub_kegiatan_manusia,
          idKategori: val.id_kategori_anggaran,
          namaKategori: val.nama_kategori_anggaran,
          idItemSumberDaya: val.id_item_upah,
          namaItemSumberDaya: val.nama_item,
        }))
      : [],
    detailPeralatan: data?.peralatan
      ? data?.peralatan?.map((val) => ({
          idItemSubKegiatan: val.id_item_sub_kegiatan_peralatan,
          hargaSatuan: val.harga_satuan,
          namaSatuan: val.nama_satuan,
          idSumberPengadaan: val.id_sumber_pengadaan,
          namaSumberPengadaan: val.nama_sumber_pengadaan,
          qty: val.qty_item_sub_kegiatan_peralatan,
          idKategoriAset: val.id_kategori_aset,
          namaKategoriAset: val.nama_kategori_aset,
          idItemSumberDaya: val.id_item_alat_mesin,
          namaItemSumberDaya: val.nama_item_aset,
          idGrupAset: val.id_grup_aset,
          namaGrupAset: val.nama_grup_aset,
          idJenisAset: val.id_jenis_aset,
          namaJenisAset: val.nama_jenis_aset,
        }))
      : [],
    detailOverhead: data?.overhead
      ? data?.overhead?.map((val) => ({
          idItemSubKegiatan: val.id_item_sub_kegiatan_overhad,
          hargaSatuan: val.harga_satuan,
          namaSatuan: val.nama_satuan,
          idSumberPengadaan: val.id_sumber_pengadaan,
          namaSumberPengadaan: val.nama_sumber_pengadaan,
          qty: val.qty_item_sub_kegiatan_overhead,
          idKategori: val.id_kategori_anggaran,
          namaKategori: val.nama_kategori_anggaran,
          idItemSumberDaya: val.id_item_sumber_daya,
          namaItemSumberDaya: val.nama_item_sumber_daya,
        }))
      : [],
    detailLainnya: data?.lainnya
      ? data?.lainnya?.map((val) => ({
          idItemSubKegiatan: val.id_item_sub_kegiatan_lainnya,
          hargaSatuan: val.harga_satuan,
          namaSatuan: val.nama_satuan,
          idSumberPengadaan: val.id_sumber_pengadaan,
          namaSumberPengadaan: val.nama_sumber_pengadaan,
          qty: val.qty_item_sub_kegiatan_lainnya,
          idKategori: val.id_kategori_anggaran,
          namaKategori: val.nama_kategori_anggaran,
          idItemSumberDaya: val.id_item_sumber_daya,
          namaItemSumberDaya: val.nama_item_sumber_daya,
        }))
      : [],
  };

  return (
    <Card className="p-0 m-0">
      <Tab.Container defaultActiveKey="material">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="material">Sumber Daya Material</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="manusia">Sumber Daya Manusia</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="peralatan">Sumber Daya Peralatan</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="overhead">
                Sumber Daya Biaya Overhead
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="lainnya">Sumber Daya Lainnya</Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Tab.Content>
          <Tab.Pane eventKey="material">
            <div className="py-3 px-2">
              <span style={{ fontSize: "14px" }}>
                <b>List Data Item Sumber Daya Material</b>
              </span>
              <TableItemMaterialManusiaSection
                dropdown={dropdown}
                tabType="material"
                type={type}
                data={dataMapping?.detailMaterial}
              />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="manusia">
            <div className="py-3 px-2">
              <span style={{ fontSize: "14px" }}>
                <b>List Data Item Sumber Daya Manusia</b>
              </span>
              <TableItemMaterialManusiaSection
                dropdown={dropdown}
                tabType="manusia"
                type={type}
                data={dataMapping?.detailManusia}
              />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="peralatan">
            <div className="py-3 px-2">
              <span style={{ fontSize: "14px" }}>
                <b>List Data Item Sumber Daya Peralatan</b>
              </span>
              <TableAsetSection
                dropdown={dropdown}
                tabType="peralatan"
                type={type}
                data={dataMapping?.detailPeralatan}
              />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="overhead">
            <div className="py-3 px-2">
              <span style={{ fontSize: "14px" }}>
                <b>List Data Item Sumber Daya Biaya Overhead</b>
              </span>
              <TableItemSumberDayaSection
                dropdown={dropdown}
                tabType="overhead"
                type={type}
                data={dataMapping?.detailOverhead}
              />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="lainnya">
            <div className="py-3 px-2">
              <span style={{ fontSize: "14px" }}>
                <b>List Data Item Sumber Daya Lainnya</b>
              </span>
              <TableItemSumberDayaSection
                dropdown={dropdown}
                tabType="lainnya"
                type={type}
                data={dataMapping?.detailLainnya}
              />
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Card>
  );
};
