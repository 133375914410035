import { TBody, Table, Th, Tr, Td, ThFixed, TdFixed } from "components";
import Thead from "components/Table/THead";
import { useFormikContext } from "formik";
import React from "react";
import { RupiahConvert } from "utilities";

export const SummarySection = ({ data }) => {
  const dataMapping = {
    detailMaterial: data?.material
      ? data?.material?.map((val) => ({
          idItemSubKegiatan: val.id_item_sub_kegiatan_material,
          hargaSatuan: val.harga_satuan,
          namaSatuan: val.nama_satuan,
          idSumberPengadaan: val.id_sumber_pengadaan,
          namaSumberPengadaan: val.nama_sumber_pengadaan,
          qty: val.qty_item_sub_kegiatan_material,
          idKategori: val.id_kategori_anggaran,
          namaKategori: val.nama_kategori_anggaran,
          idItemSumberDaya: val.id_item_bahan,
          namaItemSumberDaya: val.nama_item,
        }))
      : [],
    detailManusia: data?.manusia
      ? data?.manusia?.map((val) => ({
          idItemSubKegiatan: val.id_item_sub_kegiatan_manusia,
          hargaSatuan: val.harga_satuan,
          namaSatuan: val.nama_satuan,
          idSumberPengadaan: val.id_sumber_pengadaan,
          namaSumberPengadaan: val.nama_sumber_pengadaan,
          qty: val.qty_item_sub_kegiatan_manusia,
          idKategori: val.id_kategori_anggaran,
          namaKategori: val.nama_kategori_anggaran,
          idItemSumberDaya: val.id_item_upah,
          namaItemSumberDaya: val.nama_item,
        }))
      : [],
    detailPeralatan: data?.peralatan
      ? data?.peralatan?.map((val) => ({
          idItemSubKegiatan: val.id_item_sub_kegiatan_peralatan,
          hargaSatuan: val.harga_satuan,
          namaSatuan: val.nama_satuan,
          idSumberPengadaan: val.id_sumber_pengadaan,
          namaSumberPengadaan: val.nama_sumber_pengadaan,
          qty: val.qty_item_sub_kegiatan_peralatan,
          idKategori: val.id_kategori_anggaran,
          namaKategori: val.nama_kategori_anggaran,
          idItemSumberDaya: val.id_item_alat_mesin,
          namaItemSumberDaya: val.nama_item_aset,
        }))
      : [],
    detailOverhead: data?.overhead
      ? data?.overhead?.map((val) => ({
          idItemSubKegiatan: val.id_item_sub_kegiatan_overhad,
          hargaSatuan: val.harga_satuan,
          namaSatuan: val.nama_satuan,
          idSumberPengadaan: val.id_sumber_pengadaan,
          namaSumberPengadaan: val.nama_sumber_pengadaan,
          qty: val.qty_item_sub_kegiatan_overhead,
          idKategori: val.id_kategori_anggaran,
          namaKategori: val.nama_kategori_anggaran,
          idItemSumberDaya: val.id_item_sumber_daya,
          namaItemSumberDaya: val.nama_item_sumber_daya,
        }))
      : [],
    detailLainnya: data?.lainnya
      ? data?.lainnya?.map((val) => ({
          idItemSubKegiatan: val.id_item_sub_kegiatan_lainnya,
          hargaSatuan: val.harga_satuan,
          namaSatuan: val.nama_satuan,
          idSumberPengadaan: val.id_sumber_pengadaan,
          namaSumberPengadaan: val.nama_sumber_pengadaan,
          qty: val.qty_item_sub_kegiatan_lainnya,
          idKategori: val.id_kategori_anggaran,
          namaKategori: val.nama_kategori_anggaran,
          idItemSumberDaya: val.id_item_sumber_daya,
          namaItemSumberDaya: val.nama_item_sumber_daya,
        }))
      : [],
  };

  const subTotalData = (value) =>
    value.reduce((acc, curr) => {
      const total =
        parseFloat(curr?.qty ?? 0) * parseFloat(curr?.hargaSatuan ?? 0);
      return parseFloat(acc) + parseFloat(total);
    }, 0);

  const dataSummary = [
    {
      label: "Sumber Daya Material",
      value: subTotalData(dataMapping?.detailMaterial ?? []),
    },
    {
      label: "Sumber Daya Manusia",
      value: subTotalData(dataMapping?.detailManusia ?? []),
    },
    {
      label: "Sumber Daya Peralatan",
      value: subTotalData(dataMapping?.detailPeralatan ?? []),
    },
    {
      label: "Sumber Daya Overhead",
      value: subTotalData(dataMapping?.detailOverhead ?? []),
    },
    {
      label: "Sumber Daya Lainnya",
      value: subTotalData(dataMapping?.detailLainnya ?? []),
    },
  ];

  const grandTotal = dataSummary?.reduce(
    (acc, curr) => parseFloat(acc) + parseFloat(curr?.value ?? 0),
    0
  );
  return (
    <div>
      <Table>
        <Thead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th>Sumber Daya</Th>
            <ThFixed>Jumlah</ThFixed>
          </Tr>
        </Thead>
        <TBody>
          {dataSummary.map((val, ind) => (
            <Tr key={ind}>
              <TdFixed className="text-center">{ind + 1}</TdFixed>
              <Td>{val.label ?? "-"}</Td>
              <Td className="text-right">
                {
                  RupiahConvert(parseFloat(val.value ?? 0).toString())
                    .getWithComa
                }
              </Td>
            </Tr>
          ))}
          <Tr>
            <Td className="text-right" colSpan="2">
              <b>Total</b>
            </Td>
            <Td className="text-right">
              <b>
                {
                  RupiahConvert(parseFloat(grandTotal ?? 0).toString())
                    .getWithComa
                }
              </b>
            </Td>
          </Tr>
        </TBody>
      </Table>
    </div>
  );
};
