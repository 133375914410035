import Services from "../../services";

class PengakuanHutangApi {
  // API SERVER
  get(params) {
    return Services.get("hrdu/approval_pengeluaran/list", { params });
  }

  getHistory(params) {
    return Services.get("hrdu/approval_pengeluaran/history/list", { params });
  }

  getSingle(params) {
    return Services.get("hrdu/approval_pengeluaran/detail", { params });
  }

  approve(data) {
    return Services.post("hrdu/approval_pengeluaran/approve", data);
  }
}

export default new PengakuanHutangApi();
