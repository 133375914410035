import { CRUDLayout, InputSearch } from "components"
import { Col, Row } from "react-bootstrap"

const SearchTable = ({ 
	// searchConfig,
	// setSearchConfig,
	// setAlertConfig,
	onInputSearchChange

}) => (
	<CRUDLayout.Head>
		<CRUDLayout.HeadSearchSection>
			<Row className="mb-2">
				<Col md={8}>
					<InputSearch
						// value={searchConfig.key}
						// onChange={(e) => {
						// 	const key = e.target.value
						// 	setSearchConfig({
						// 		...searchConfig,
						// 		key: e.target.value,
						// 	})
						// 	setAlertConfig({
						// 		show: key ? true : false,
						// 		variant: "primary",
						// 		text: "Hasil dari pencarian: " + key,
						// 	})
						// }}
						onChange={onInputSearchChange}
					/>
				</Col>
			</Row>
		</CRUDLayout.HeadSearchSection>
	</CRUDLayout.Head>
)

export default SearchTable