export const MODAL_TITLE = {
  CREATE: "Tambah",
  UPDATE: "Ubah",
  DETAIL: "Detail",
};

export const STATUS_APPROVAL = {
  PEN: { label: "PENDING", variant: "outline-secondary" },
  VER: {
    variant: "outline-verified",
    label: "VERIFIED",
  },
  APP: {
    variant: "outline-success",
    label: "APPROVED",
  },
  REV: {
    variant: "outline-revised",
    label: "REVISI",
  },
  REJ: {
    variant: "outline-danger",
    label: "REJECT",
  },
};

export const KODE_MODUL = [
  "PRY",
  "ASM",
  "TNK",
  "SIA",
  "ANG",
  "CRM",
  "PRO",
  "PRD",
  "SLS",
  "COC",
  "INV",
  "MKT",
  "HRDA",
].map((kode) => ({ value: kode, label: kode }));
