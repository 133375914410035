import React, { useState, useEffect, useContext } from 'react'
import Axios from 'axios'
import InfoSection from './InfoSection'
import TableSection from './TableSection'
import { SPKApi } from 'api'
import { DataStatus } from 'components'

const AnalisaBarangJadi = ({ processedData, dataBarangJadi, setDataBarangJadi, modalConfig, setModalConfig }) => {
  const [data, setData] = useState({})
  const [dataListAnalisa, setDataListAnalisa] = useState([])
  const [dataGambar, setDataGambar] = useState([])
  const [dataFile, setDataFile] = useState([])
  const [isFetch, setIsFetch] = useState({ loading: false, success: true })

  const mapAnalisaRAB = (data) => data?.map(item => Object({
    ...item,
    harga: item.harga_satuan
  }))

  const getInitialData = () => {
    setIsFetch({ loading: true, success: true })
    const { id_rab_detail } = processedData

    Axios.all([SPKApi.getAnalisaSPK({ id_rab_detail })])
      .then(Axios.spread((analisa) => {
        const dataAnalisa = analisa?.data?.data ?? {}
        const mapDataListAnalisa = mapAnalisaRAB(dataAnalisa?.analisa_barang_jadi)  // map data jika get single analisa RAE

        let gambar = []
        let file = []
        dataAnalisa?.files?.map(val => {
          const generalFileType = val.path_gambar.split('.').pop()
          if (['jpg', 'png', 'gif', 'jpeg'].includes(generalFileType)) {
            return gambar.push(val)
          }
          return file.push(val)
        })

        setData(dataAnalisa)
        setDataListAnalisa(mapDataListAnalisa)
        setDataGambar(gambar)
        setDataFile(file)

        setIsFetch({ loading: false, success: true })
      }))
      .catch(err => setIsFetch({ loading: false, success: false }))
  }

  useEffect(() => {
    getInitialData()
    return () => { }
  }, [])

  return (
    <>
      {
        isFetch.loading ? <DataStatus loading={isFetch.loading} text='Memuat data ... ' />
          : !isFetch.success && !isFetch.loading ? <DataStatus text='Gagal memuat data!' />
            :
            <>
              <InfoSection
                data={data}
                dataGambar={dataGambar}
                dataFile={dataFile} />

              <TableSection
                data={dataListAnalisa}
                dataBarangJadi={dataBarangJadi}
                setDataBarangJadi={setDataBarangJadi}
                modalConfig={modalConfig}
                setModalConfig={setModalConfig} />
            </>
      }
    </>
  )
}

export default AnalisaBarangJadi
