import React, { useState, useEffect, useContext, memo } from "react";
import { Card, Row, Col, Modal } from "react-bootstrap";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import {
  Alert,
  BackButton,
  DataStatus,
  ActionButton,
  TextArea
} from "components";
import { FormRAE, InfoSection, TableBarangJadi } from "../Section";
import { DateConvert } from "utilities";
import { RAEApi } from "api";

const DetailRAE = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_rae } = useParams();
  const { state } = useLocation();
  const { tab, no_rae, baseline } = state || {}

  const ContentDetailRAE = () => {
    const [dataRAE, setDataRAE] = useState({});
    const [isPageLoading, setIsPageLoading] = useState(false)
    const [isFetchingFailed, setIsFetchingFailed] = useState(false);
    const [dataBarangJadi, setDataBarangJadi] = useState([]);
    const [approveStatus, setApproveStatus] = useState("V");
    const [dataApproval, setDataApproval] = useState([]);
    const [modalConfig, setModalConfig] = useState({
      show: false,
      type: "",
      title: "",
    });
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: "primary",
      text: "",
    });

    const getInitialData = () => {
      setIsPageLoading(true)

      Axios.all([RAEApi.getSingle({ no_rae, baseline })])
        .then(Axios.spread((rae) => {
          const behavior = String(rae?.data?.data?.behavior).toUpperCase()
          const mapDataBarangJadi = rae?.data?.data?.detail?.detail?.map(val => Object({
            id_rae_detail: val.id_rae_detail,
            id_rae: val.id_rae,
            id_barang_jadi: val.id_barang_jadi,
            qty: val.qty_rae,
            harga_satuan: val.harga_satuan_rae,
            kode_item: val.kode_item,
            nama_item: val.nama_item,
            satuan: val.nama_satuan_jual,
            unique: Math.random().toString(36).substring(2, 9)
          }))

          setDataRAE(rae?.data?.data?.detail ?? {})
          setDataBarangJadi(mapDataBarangJadi ?? [])
          setDataApproval(rae?.data?.data?.approval ?? [])
          setApproveStatus(behavior ?? 'V')

        }))
        .catch((err) => {
          setIsFetchingFailed(true);
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal dimuat!",
          });
        })
        .finally(() => setIsPageLoading(false));
    }

    const formInitialValues = {
      id_rae: id_rae,
      tgl_rae: dataRAE?.tgl_rae ?? "",
      no_rae: dataRAE?.no_rae ?? "",
    };
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: formInitialValues,
    });

    useEffect(() => {
      setNavbarTitle("Unit Cost");
      getInitialData();
    }, [])

    const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
      const ModalContent = () => (
        <>
          <Modal.Body className="text-center">
            <p>
              <h6>
                <b>
                  {modalConfig.title === "approve"
                    ? approveStatus === "A"
                      ? "Approve"
                      : "Verify"
                    : modalConfig.title}{" "}
                  dengan catatan :
                </b>
              </h6>
              <span>{values.catatan}</span>
            </p>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <ActionButton
              variant="outline-secondary"
              text="Batal"
              className="px-4"
              onClick={() =>
                setModalConfig({
                  ...modalConfig,
                  show: false,
                })
              }
            />
            <ActionButton
              text={
                modalConfig.type === "APP"
                  ? approveStatus === "A"
                    ? "Approve"
                    : "Verify"
                  : modalConfig.type === "REJ"
                    ? "Reject"
                    : "Revise"
              }
              variant={
                modalConfig.type === "APP"
                  ? "success"
                  : modalConfig.type === "REV"
                    ? "warning"
                    : "danger"
              }
              className="m-1 px-3 text-white"
              onClick={handleSubmit}
              loading={isSubmitting}
            />
          </Modal.Footer>
        </>
      );

      return (
        <Modal
          show={modalConfig.show}
          onHide={() =>
            setModalConfig({
              ...modalConfig,
              show: false,
            })
          }
        >
          <Modal.Header closeButton className="py-2 d-flex align-items-center">
            <Modal.Title>
              <small>{modalConfig.title}</small>
            </Modal.Title>
          </Modal.Header>
          <ModalContent />
        </Modal>
      );
    };

    const FormCard = ({ values, handleChange, validateForm, errors, touched, setTouched, dirty }) => {
      const InfoItem = ({ title1, value1, title2, value2 }) => (
        <div className="col-md-4 mb-4">
          <small>{title1}</small>
          <br />
          <b>{value1}</b>
          <div className="mt-1" />
          <small>{title2}</small>
          <br />
          <b>{value2}</b>
        </div>
      );

      return (
        <Card className="mt-4 mb-5">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Approval Unit Cost</b>
          </Card.Header>
          <Card.Body>
            <div className="d-flex align-content-between flex-wrap">
              {dataApproval.map((val, index) => (
                <InfoItem
                  key={index}
                  title1={
                    val.status_approval == "VER" ? `PEMERIKSA ${val.approval_level}` : "PENGESAH"
                  }
                  value1={val.nama_karyawan ?? "-"}
                  title2={"Catatan"}
                  value2={val.catatan ?? "-"}
                />
              ))}
            </div>
            <hr />
            <div>
              <TextArea
                label="Catatan"
                name="catatan"
                placeholder="Masukan catatan"
                value={values.catatan}
                onChange={handleChange}
                error={errors.catatan && true}
                errorText={errors.catatan}
              />
              <div className="d-flex justify-content-end mt-3">
                <ActionButton
                  text="Reject"
                  variant="danger"
                  className="m-1"
                  onClick={() => {
                    if (dirty) {
                      setModalConfig({
                        show: true,
                        type: "REJ",
                        title: <span className="text-danger">Reject Unit cost</span>,
                      });
                    }
                    validateForm();
                  }}
                />

                {approveStatus !== 'A' && <ActionButton
                  text="Revise"
                  variant="warning"
                  className="m-1 text-white"
                  onClick={() => {
                    if (dirty) {
                      setModalConfig({
                        show: true,
                        type: "REV",
                        title: <span className="text-warning">Revise Unit Cost</span>,
                      });
                    }
                    validateForm();
                  }}
                />}

                <ActionButton
                  text={approveStatus === "A" ? "Approve" : "Verify"}
                  variant="success"
                  className="m-1"
                  onClick={() => {
                    if (dirty) {
                      setModalConfig({
                        show: true,
                        type: "APP",
                        title: <span className="text-success">Approve Unit Cost</span>,
                      });
                    }
                    validateForm();
                  }}
                />
              </div>
            </div>
          </Card.Body>
        </Card>
      );
    };

    const CatatanApproval = () => {
      const InfoItem = ({ title1, value1, title2, value2 }) => (
        <div className="mb-2">
          <small>{title1}</small>
          <br />
          <b>{value1}</b>
          <div className="mt-1" />
          <small>{title2}</small>
          <br />
          <b>{value2}</b>
        </div>
      );

      return (
        <Card className="mt-4 mb-5">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Catatan Approval Unit Cost</b>
          </Card.Header>
          <Card.Body>
            <Row>
              {dataRAE?.stakeholder?.map((val, index) => index !== 0 && val.status_approval !== "PEN" && (
                <Col sm>
                  <InfoItem
                    title1={
                      val.status_approval === "VER" || val.status_approval === "REV" ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}`
                        : val.status_approval === "APP" ? "Pengesah"
                          : "Di Tolak Oleh"
                    }
                    value1={val.nama_karyawan ?? "-"}
                    title2="Catatan"
                    value2={val.catatan ?? "-"}
                  />
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      );
    };

    return (
      <>
        <Card>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Detail Data Unit Cost</b>
            <BackButton onClick={() => history.goBack()} />
          </Card.Header>
          <Card.Body>
            <Alert
              showCloseButton
              show={alertConfig.show}
              variant={alertConfig.variant}
              text={alertConfig.text}
              onClose={() => setAlertConfig({ ...alertConfig, show: false })} />
            {
              isPageLoading ? <DataStatus loading={true} text="Memuat data . . ." />
                : isFetchingFailed ? <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
                  : <>
                    <InfoSection data={dataRAE} />
                    <hr />
                    <FormRAE formik={formik} type='DETAIL' />
                    <hr />
                    <TableBarangJadi
                      dataBarangJadi={dataBarangJadi}
                      setDataBarangJadi={setDataBarangJadi} />
                  </>
            }
          </Card.Body>
        </Card>

        {!isFetchingFailed && !isPageLoading && tab !== 'history' && (
          <Formik
            initialValues={{ catatan: "" }}
            validationSchema={Yup.object().shape({
              catatan: Yup.string().required("Masukan Catatan"),
            })}
            onSubmit={(values) => {
              const finalValues = {
                ...values,
                no_transaksi: dataRAE.no_rae,
                status_approval: modalConfig.type,
                tgl_approval: DateConvert(new Date()).default,
                approval_baseline: dataRAE.baseline,
              };

              RAEApi.save(finalValues)
                .then((res) => {
                  history.push("/human-resource/approval/unit-cost", {
                    alert: {
                      show: true,
                      variant: "primary",
                      text: `${modalConfig.type == "APP"
                        ? "Approve"
                        : modalConfig.type == "REV"
                          ? "Revise"
                          : "Reject"
                        } data berhasil!`,
                    },
                  });
                })
                .catch((err) => {
                  setAlertConfig({
                    show: true,
                    variant: "danger",
                    text: `Simpan approval gagal! (${err?.response?.data?.message ?? ""})`,
                  });
                })
                .finally(() => setModalConfig({ show: false }));
            }}
          >
            {({
              values,
              isSubmitting,
              handleChange,
              handleSubmit,
              errors,
              touched,
              validateForm,
              dirty,
            }) => (
              <>
                <FormCard
                  values={values}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  validateForm={validateForm}
                  dirty={dirty}
                />
                <ModalSection
                  values={values}
                  isSubmitting={isSubmitting}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                />
              </>
            )}
          </Formik>
        )}

        {!isFetchingFailed && !isPageLoading && tab === "history" && <CatatanApproval />}
      </>
    )
  }

  return (
    <ContentDetailRAE />
  )
};

export default DetailRAE;
