import axios from "axios";

export const uploadImageServices = (image) => {
  const { REACT_APP_FILE_BASE_URL } = process.env;
  const url = `${REACT_APP_FILE_BASE_URL}penerimaan_petty_cash`;
  const formData = new FormData();
  formData.append("file", image);
  const token = "Bearer " + sessionStorage.getItem("token");

  return axios.post(url, formData, {
    headers: {
      Authorization: token,
      "Content-Type": "multipart/form-data",
    },
  });
};

// import Axios from "axios";

// const { REACT_APP_BASE_URL } = process.env;

// const Services = Axios.create({
//   baseURL: REACT_APP_BASE_URL,
// });

// //Services.defaults.timeout = 20000;

// Services.interceptors.request.use((config) => {
//   const token = "Bearer " + sessionStorage.getItem("token");

//   config.headers = {
//     Authorization: token,
//     "Content-Type": "application/json",
//   };

//   return config;
// });

// export default Services;
