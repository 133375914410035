import React, { Fragment, useEffect } from 'react';
import { Card, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Formik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { CRUDLayout } from 'components';
import { ButtonBack, ButtonUpdate, DataStatus } from 'components2';
import { useModalConfirm } from 'hooks2';
import RealisasiPettyCashApi from './__RealisasiPettyCashApi__';
import {
  RealisasiPettyCashContent,
  TableSection,
} from './__RealisasiPettyCashComps__';
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
  uploadMultiFilesServices,
} from './__RealisasiPettyCashUtils__';

const RealisasiPettyCashUbah = ({ setNavbarTitle }) => {
  const history = useHistory();
  const modalConfirm = useModalConfirm();
  const { id: id_realisasi_petty_cash } = useParams();
  const { REACT_APP_SHOW_FILE_BASE_URL } = process.env;

  const { data, isLoading, isError } = useQuery({
    queryKey: [
      'single',
      'ubah',
      'realisasi_petty_cash',
      id_realisasi_petty_cash,
    ],
    queryFn: () =>
      RealisasiPettyCashApi.getSingle({ id_realisasi_petty_cash }).then(
        (res) => {
          // map path media
          const mapFiles = res?.data?.detail?.map((rincian) => ({
            ...rincian,
            files: rincian?.media?.map((e) => ({
              nama: e?.path_media?.split('/').pop(),
              link: `${REACT_APP_SHOW_FILE_BASE_URL}/${e.path_media}`,
              path_media: e.path_media,
            })),
          }));

          return { ...res, data: { ...res.data, detail: mapFiles } };
        }
      ),
    enabled: !!id_realisasi_petty_cash,
  });

  const onSubmitForm = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    await modalConfirm.trigger({
      size: 'xl',
      type: 'update',
      customTextHeader: ' ',
      title:
        'Apakah Anda yakin akan mengubah data Realisasi Petty Cash berikut?',
      component: (
        <Formik initialValues={values}>
          <Fragment>
            <RealisasiPettyCashContent action="DETAIL" />
            <hr />
            <TableSection action="CONFIRM" data={values?.detail ?? []} />
          </Fragment>
        </Formik>
      ),
      onSubmit: async () => {
        modalConfirm.loading();

        const realisasiDetail = [...values?.detail];

        // Upload images in item rincian
        const uploadFilesRincian = await Promise.all(
          realisasiDetail.map(async (data) => {
            // separate newly added files and previously saved files
            const files = data.files.reduce(
              (acc, file) => {
                file.data
                  ? acc.newAdded.push(file)
                  : acc.previouslySaved.push(file.path_media);

                return acc;
              },
              { newAdded: [], previouslySaved: [] }
            );

            return await uploadMultiFilesServices(files.newAdded)
              .then((res) => ({
                ...data,
                media: Object.values(res?.data?.data ?? [])
                  .concat(files.previouslySaved)
                  .map((path) => ({ path_media: path })),
              }))
              .catch((err) =>
                alert(
                  `Gagal mengupload files ${err.response.data.message ?? ''}`
                )
              );
          })
        ).then((uploaded) => uploaded);

        const finalValue = formSubmitValueMapper({
          ...values,
          detail: uploadFilesRincian,
        });

        RealisasiPettyCashApi.update(finalValue)
          .then(() =>
            modalConfirm.infoSuccess({
              title: 'Data Realisasi Petty Cash Berhasil Diubah',
              customTextInfoDetail: 'Data telah tersimpan ke database',
              size: 'md',
              onHide: () => {
                resetForm();
                modalConfirm.close();
                history.replace(
                  '/human-resource/transaksi/realisasi-petty-cash'
                );
              },
            })
          )
          .catch(({ response }) => {
            modalConfirm.infoError({
              title: 'Data Realisasi Petty Cash Gagal Diubah',
              customTextInfoTitle: 'Penyebab Kegagalan:',
              customTextInfoDetail: response?.data?.message,
            });
          });
      },
      onHide: () => modalConfirm.close(),
    });
  };

  useEffect(() => setNavbarTitle('Realisasi Petty Cash'), []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadButtonSection>
          <ButtonBack size="md" onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      {isLoading || isError ? (
        <DataStatus
          loading={isLoading}
          text={isLoading ? 'Memuat . . .' : 'Data gagal dimuat'}
        />
      ) : (
        <Formik
          enableReinitialize
          initialValues={formInitialValues(data.data, 'UPDATE')}
          validationSchema={formValidationSchema}
          onSubmit={onSubmitForm}
        >
          {({ values, handleSubmit, isSubmitting }) => (
            <Card className="p-4 mt-4">
              <RealisasiPettyCashContent action="UPDATE" />
              <hr />
              <TableSection action="CREATE" data={values?.detail ?? []} />
              <Row className="mt-4 px-3 d-flex justify-content-end align-items-center">
                <ButtonUpdate
                  loading={isSubmitting}
                  size="md"
                  onClick={() => handleSubmit()}
                />
              </Row>
            </Card>
          )}
        </Formik>
      )}
    </CRUDLayout>
  );
};

export default RealisasiPettyCashUbah;
