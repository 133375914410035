import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { Row, Col, Card } from "react-bootstrap";
import { Formik, useFormikContext } from "formik";
import {
  Input,
  InfoItemHorizontal,
  Select,
  TextArea,
  ButtonBack,
  ButtonCreate,
} from "components2";

import { DataStatus, DatePicker } from "components";
import { TablePermintaanAset } from "./TablePermintaanAset";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { ModalForm } from "./ModalForm";
import * as Yup from "yup";
import { DateConvert } from "utilities";

const InfoMaintenanceRequest = () => {
  const { values } = useFormikContext();
  return (
    <>
      <InfoItemHorizontal
        label="Tgl. Permintaan Aset"
        text={
          values?.tgl_permintaan_aset
            ? DateConvert(new Date(values?.tgl_permintaan_aset)).detail
            : "-"
        }
      />

      <InfoItemHorizontal
        label="No. Permintaan Aset"
        text={values?.no_permintaan_aset ?? "-"}
      />

      <InfoItemHorizontal
        label="Catatan Permintaan"
        text={values?.catatan_permintaan ?? "-"}
      />
    </>
  );
};

const CatatanApprovalCard = ({ dataStakeholder }) => {
  const InfoItem = ({ title1, value1, title2, value2 }) => (
    <div className="mb-2">
      <small>{title1}</small>
      <br />
      <b>{value1}</b>
      <div className="mt-1" />
      <small>{title2}</small>
      <br />
      <b>{value2}</b>
    </div>
  );

  return (
    <Card className="mt-4 mb-5">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Catatan Approval Permintaan Aset</b>
      </Card.Header>
      <Card.Body>
        {dataStakeholder ? (
          <Row>
            {dataStakeholder.map((val, index) => (
              <Col sm>
                <InfoItem
                  title1={
                    val.status_approval === "VER" ||
                    val.status_approval === "REV"
                      ? `Pemeriksa ${
                          val.approval_level !== "0" ? val.approval_level : ""
                        }`
                      : val.status_approval === "APP"
                      ? "Pengesah"
                      : val.status_approval === "PEN"
                      ? "Pembuat"
                      : "Di Tolak Oleh"
                  }
                  value1={val.nama_karyawan ?? "-"}
                  title2="Catatan"
                  value2={val.catatan ?? "-"}
                />
              </Col>
            ))}
          </Row>
        ) : (
          <div className="d-flex justify-content-center">
            <DataStatus text="Tidak ada catatan" />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export const FormPermintaanAsetContent = ({ action }) => {
  const { values, handleSubmit, handleChange, setValues, errors, touched } =
    useFormikContext();
  const history = useHistory();

  const [modalConfig, setModalConfig] = useState({
    show: false,
    data: {},
    type: "add",
    ind: undefined,
  });

  const modalInitialValues = {
    idEntitasAset: modalConfig?.data?.idEntitasAset ?? undefined,
    namaEntitasAset: modalConfig?.data?.namaEntitasAset ?? "",
    idGrupAset: modalConfig?.data?.idGrupAset ?? undefined,
    namaGrupAset: modalConfig?.data?.namaGrupAset ?? "",
    idKategoriAset: modalConfig?.data?.idKategoriAset ?? undefined,
    namaKategoriAset: modalConfig?.data?.namaKategoriAset ?? "",
    idJenisAset: modalConfig?.data?.idJenisAset ?? undefined,
    namaJenisAset: modalConfig?.data?.namaJenisAset ?? "",
    idUnitProduksi: modalConfig?.data?.idUnitProduksi ?? undefined,
    namaUnitProduksi: modalConfig?.data?.namaUnitProduksi ?? "",
    keperluan: modalConfig?.data?.keperluan ?? undefined,
    keperluanLabel: modalConfig?.data?.keperluanLabel ?? "",
    metodePengadaan: modalConfig?.data?.metodePengadaan ?? undefined,
    metodePengadaanLabel: modalConfig?.data?.metodePengadaanLabel ?? "",
    namaAset: modalConfig?.data?.namaAset ?? "",
    keterangan: modalConfig?.data?.keterangan ?? "",
    file: modalConfig?.data?.file ?? [],
  };

  const modalValidationSchema = Yup.object().shape({
    idEntitasAset: Yup.string().required("Pilih entitas aset"),
    idGrupAset: Yup.string().required("Pilih grup aset"),
    idKategoriAset: Yup.string().required("Pilih kategori aset"),
    idJenisAset: Yup.string().required("Pilih jenis aset"),
    idUnitProduksi: Yup.string().required("Pilih unit produksi"),
    keperluan: Yup.string().required("Pilih keperluan"),
    metodePengadaan: Yup.string().required("Pilih metode pengadaan"),
    namaAset: Yup.string().required("Masukan item aset"),
  });

  const modalSubmitHandler = (value, { setSubmitting, resetForm }) => {
    modalConfig.type === "add" &&
      setValues({ ...values, detail: [...values?.detail, value] });
    modalConfig.type === "update" &&
      setValues({
        ...values,
        detail: values?.detail?.map((res, ind) =>
          ind === modalConfig?.ind ? value : res
        ),
      });

    setSubmitting(false);
    resetForm();
    setModalConfig({ ...modalConfig, show: false });
  };

  return (
    <Fragment>
      <Card>
        <Card.Body>
          <InfoMaintenanceRequest />
        </Card.Body>
      </Card>

      <div className="d-flex justify-content-between mt-3">
        <b>Detail Aset Yang Diminta</b>
      </div>
      <Card>
        <Card.Body>
          {action !== "detail" && (
            <div className="d-flex justify-content-end mb-3">
              <ButtonCreate
                text="Tambah"
                variant="primary"
                onClick={() => {
                  setModalConfig({ ...modalConfig, show: true, type: "add" });
                }}
              />
            </div>
          )}
          <TablePermintaanAset
            dataTable={values?.detail}
            action={action}
            setModalConfig={setModalConfig}
            modalConfig={modalConfig}
          />
        </Card.Body>
      </Card>

      {action !== "detail" && (
        <div className="d-flex justify-content-end mt-3">
          <ButtonCreate
            text="Simpan"
            variant={action === "update" ? "success" : "primary"}
            onClick={handleSubmit}
          />
        </div>
      )}

      {modalConfig.show && (
        <Formik
          initialValues={modalInitialValues}
          validationSchema={modalValidationSchema}
          onSubmit={modalSubmitHandler}
        >
          <ModalForm
            setModalConfig={setModalConfig}
            modalConfig={modalConfig}
          />
        </Formik>
      )}
    </Fragment>
  );
};
