import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { CRUDLayout } from 'components';
import { ButtonBack, DataStatus } from 'components2';
import {
  RealisasiPettyCashContent,
  TableSection,
} from './__RealisasiPettyCashComps__';
import { formInitialValues } from './__RealisasiPettyCashUtils__';
import RealisasiPettyCashApi from './__RealisasiPettyCashApi__';

const RealisasiPettyCashDetail = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id: id_realisasi_petty_cash } = useParams();
  const { REACT_APP_SHOW_FILE_BASE_URL } = process.env;
  const { data, isLoading, isError } = useQuery({
    queryKey: ['realisasi_petty_cash', 'detail', id_realisasi_petty_cash],
    queryFn: () =>
      RealisasiPettyCashApi.getSingle({ id_realisasi_petty_cash }).then(
        (res) => {
          const mapFiles = res?.data?.detail?.map((rincian) => ({
            ...rincian,
            files: rincian?.media?.map((e) => ({
              nama: e?.path_media?.split('/').pop(),
              link: `${REACT_APP_SHOW_FILE_BASE_URL}/${e?.path_media}`,
              path_media: e?.path_media,
            })),
          }));

          return { ...res, data: { ...res.data, detail: mapFiles ?? [] } };
        }
      ),
  });

  useEffect(() => setNavbarTitle('Realisasi Petty Cash'), []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadButtonSection>
          <ButtonBack size="md" onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      {isLoading || isError ? (
        <DataStatus
          loading={isLoading}
          text={isLoading ? 'Memuat . . .' : 'Data gagal dimuat'}
        />
      ) : (
        <Formik
          enableReinitialize
          initialValues={formInitialValues(data?.data, 'DETAIL')}
        >
          {({ values }) => (
            <Card className="p-4 mt-4">
              <RealisasiPettyCashContent action="DETAIL" />
              <hr />
              <TableSection action="DETAIL" data={values?.detail ?? []} />
            </Card>
          )}
        </Formik>
      )}
    </CRUDLayout>
  );
};

export default RealisasiPettyCashDetail;
