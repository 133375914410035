export const STATUS_APPROVAL = {
  PEN: { label: "PENDING", variant: "outline-secondary" },
  VER: {
    variant: "outline-verified",
    label: "VERIFIED",
  },
  APP: {
    variant: "outline-success",
    label: "APPROVED",
  },
  REV: {
    variant: "outline-revised",
    label: "REVISI",
  },
  REJ: {
    variant: "outline-danger",
    label: "REJECT",
  },
};

export const FILE_TYPE = {
  images: ["image/jpeg", "image/png"],
};
