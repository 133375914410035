import Services from "../../services";

class PengakuanHutangApi {
  // API SERVER
  get(params) {
    return Services.get("hrdu/approval_faktur_ap/list", { params });
  }

  getHistory(params) {
    return Services.get("hrdu/approval_faktur_ap/history/list", { params });
  }

  getSingle(params) {
    return Services.get("hrdu/approval_faktur_ap/detail", { params });
  }

  approve(data) {
    return Services.post("hrdu/approval_faktur_ap/approve", data);
  }
}

export default new PengakuanHutangApi();
