import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col, Card, Modal } from "react-bootstrap";
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus,
  TextArea,
  Approval,
} from "components";
import { ApprovalStatus, DateConvert, RupiahConvert } from "utilities";
import { PenerimaanPettyCashApi } from "api";

const DetailApprovalPenerimaanPettyCash = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();
  const { tab, no_penerimaan_petty_cash } = state;
  const { REACT_APP_SHOW_FILE_BASE_URL } = process.env;

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [data, setData] = useState([]);
  const [approveStatus, setApproveStatus] = useState("V");
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const imgURLBukti = data?.detail?.foto_penerimaan_petty_cash
    ? `${REACT_APP_SHOW_FILE_BASE_URL}${data?.detail?.foto_penerimaan_petty_cash}`
    : "";

  const getInitialData = () => {
    setIsPageLoading(true);

    PenerimaanPettyCashApi.getSingle({ no_penerimaan_petty_cash })
      .then(({ data }) => {
        const behavior = data.data.behavior ? data.data.behavior : "V";
        setData(data.data);
        setApproveStatus(behavior.toUpperCase());
      })
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const formSubmitHandler = (values) => {
    const today = new Date().toISOString().slice(0, 10);

    const finalValues = {
      no_transaksi: data.detail.no_penerimaan_petty_cash,
      status_approval: modalConfig.type,
      catatan: values.catatan,
      tgl_approval: today,
    };

    PenerimaanPettyCashApi.approve(finalValues)
      .then(() =>
        history.push("/human-resource/approval/penerimaan-petty-cash", {
          alert: {
            show: true,
            variant: "primary",
            text: `Approval berhasil disimpan!`,
          },
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Approval gagal disimpan!",
        })
      )
      .finally(() => {
        setModalConfig({
          ...modalConfig,
          show: false,
        });
        getInitialData();
      });
  };

  useEffect(() => {
    setNavbarTitle("Approval Penerimaan Petty Cash");
    getInitialData();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  const InfoSection = () => {
    const InfoItem = ({ title, value, isPaddingTop = false }) => (
      <tr>
        <td
          className="align-top"
          style={isPaddingTop ? { paddingTop: "10px" } : {}}
        >
          {title}
        </td>
        <td className="pl-4 pr-2 align-top">:</td>
        <td className="align-top">{value}</td>
      </tr>
    );

    return (
      <>
        <Row>
          <Col md>
            <table style={{ fontSize: "14px" }}>
              <tbody>
                <InfoItem
                  title="Tgl. Mutasi Petty Cash"
                  value={
                    data?.detail?.tgl_mutasi_petty_cash
                      ? DateConvert(
                          new Date(data?.detail?.tgl_mutasi_petty_cash)
                        ).detail
                      : "-"
                  }
                />
                <InfoItem
                  title="No. Bukti Mutasi"
                  value={data?.detail?.no_bukti_mutasi ?? "-"}
                />
                <InfoItem
                  title="Nominal Mutasi Petty Cash"
                  value={
                    RupiahConvert(
                      String(parseInt(data?.detail?.nominal_mutasi ?? 0))
                    ).detail
                  }
                />
                <InfoItem
                  title="Nominal Penerimaan Petty Cash"
                  value={
                    RupiahConvert(
                      String(
                        parseInt(
                          data?.detail?.nominal_penerimaan_petty_cash ?? 0
                        )
                      )
                    ).detail
                  }
                />
                <InfoItem
                  title="Masa Berlaku Petty Cash"
                  value={`${
                    data?.detail?.tgl_awal_petty_cash
                      ? DateConvert(new Date(data?.detail?.tgl_awal_petty_cash))
                          .detail
                      : ""
                  } 
                  - 
                  ${
                    data?.detail?.tgl_akhir_petty_cash
                      ? DateConvert(
                          new Date(data?.detail?.tgl_akhir_petty_cash)
                        ).detail
                      : ""
                  }`}
                />
              </tbody>
            </table>
          </Col>
          <Col md>
            <table style={{ fontSize: "14px" }}>
              <tbody>
                <InfoItem
                  title="Tgl. Penerimaan Petty Cash"
                  value={
                    data?.detail?.tgl_penerimaan_petty_cash
                      ? DateConvert(
                          new Date(data?.detail?.tgl_penerimaan_petty_cash)
                        ).detail
                      : "-"
                  }
                />
                <InfoItem
                  title="No. Penerimaan Petty Cash"
                  value={data?.detail?.no_penerimaan_petty_cash ?? "-"}
                />
                <InfoItem
                  title="Pekerja"
                  value={`${data?.detail?.nama_karyawan_penerima ?? ""} - ${
                    data?.detail?.nama_jabatan_penerima ?? ""
                  } `}
                />
                <InfoItem
                  title="Unit Organisasi"
                  value={data?.detail?.nama_unit_organisasi ?? "-"}
                />
                <InfoItem
                  title="Unit Produksi"
                  value={data?.detail?.nama_unit_produksi ?? "-"}
                />
              </tbody>
            </table>
          </Col>
        </Row>

        <Row className="my-3" style={{ fontSize: "14px" }}>
          <Col>
            <div style={{ display: "block" }}>Bukti Penerimaan Petty Cash</div>
            <img
              className="mt-2"
              style={{ minWidth: 150, maxWidth: 200, height: "auto" }}
              src={imgURLBukti}
              alt="bukti penerimaan petty cash"
            />
          </Col>
        </Row>

        <TextArea
          label="Keterangan"
          value={data?.detail?.keterangan ?? "-"}
          readOnly
          rows="3"
        />
      </>
    );
  };

  const ModalSection = ({ formik }) => {
    const { values, isSubmitting, handleSubmit } = formik;

    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>{modalConfig.title} dengan catatan :</b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={ApprovalStatus(modalConfig.type, approveStatus).text}
            variant={ApprovalStatus(modalConfig.type, approveStatus).variant}
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Penerimaan Petty Cash</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />

          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat!" />
          ) : (
            <InfoSection />
          )}
        </Card.Body>
      </Card>
      {!isFetchingFailed && !isPageLoading && (
        <Formik
          initialValues={{ catatan: "" }}
          validationSchema={Yup.object().shape({
            catatan: Yup.string().required("Masukan catatan"),
          })}
          onSubmit={formSubmitHandler}
        >
          {(formik) => (
            <>
              <Approval
                values={formik.values}
                handleChange={formik.handleChange}
                dirty={formik.dirty}
                validateForm={formik.validateForm}
                errors={formik.errors}
                touched={formik.touched}
                approveStatus={approveStatus}
                data={data?.approval ? data.approval : []}
                tab={tab}
                setModalConfig={setModalConfig}
                title="Penerimaan Petty Cash"
              />
              <ModalSection formik={formik} />
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default DetailApprovalPenerimaanPettyCash;
