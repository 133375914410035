import React, { Fragment, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useFormikContext } from "formik";
import {
  Input,
  InfoItemVertical,
  TextArea,
  DatePicker,
  InputCurrency,
} from "components2";
import { dateConvert, rupiahConvert } from "utilities2";
import { generateNoPettyCash, FILE_TYPE } from "../__PenerimaPettyCashUtils__";
import { CustomUploadFile } from ".";
import { InfoItemHorizontal } from "components";
import { DateConvert, RupiahConvert } from "utilities";

export const PenerimaanPettyCashContent = ({ action }) => {
  const { values, errors, touched, setValues, handleChange } =
    useFormikContext();

  const handleChangeFile = (e) => {
    const file = e.target.files[0] ?? null;
    const isImage = FILE_TYPE.images.includes(file.type);

    if (!file || !isImage) {
      return alert("File harus berupa gambar (.jpg | .jpeg | .png)");
    }

    const name = file.name;
    const url = URL.createObjectURL(file);
    setValues({ ...values, bukti: { data: file, name, url } });
  };

  useEffect(async () => {
    if (action === "CREATE") {
      const TODAY = new Date();
      const noPettyCash = await generateNoPettyCash(TODAY);

      setValues({
        ...values,
        no_penerimaan_petty_cash: noPettyCash,
        tgl_penerimaan_petty_cash: TODAY,
      });
    }
  }, [action]);

  if (action === "DETAIL") {
    return (
      <Fragment>
        <Row>
          <Col>
            <InfoItemHorizontal
              label="Tgl. Pengajuan Petty Cash"
              text={
                values?.tgl_pengajuan_petty_cash
                  ? DateConvert(new Date(values?.tgl_pengajuan_petty_cash))
                      .detail
                  : "-"
              }
            />
            <InfoItemHorizontal
              label="No. Pengajuan Petty Cash"
              text={values?.no_pengajuan_petty_cash ?? "-"}
            />
            <InfoItemHorizontal
              label="Nominal Pengajuan Petty Cash"
              text={
                RupiahConvert(
                  parseFloat(values?.nominal_pengajuan ?? 0).toString()
                ).getWithComa
              }
            />
            <InfoItemHorizontal
              label="Unit Produksi"
              text={values?.nama_unit_produksi ?? "-"}
            />
            <InfoItemHorizontal label="Divisi" text={values.kode_modul} />
          </Col>
          <Col>
            <InfoItemHorizontal
              label="Tgl. Mutasi Petty Cash"
              text={
                values?.tgl_pengajuan_petty_cash
                  ? DateConvert(new Date(values?.tgl_pengajuan_petty_cash))
                      .detail
                  : "-"
              }
            />
            <InfoItemHorizontal
              label="No. Mutasi Petty Cash"
              text={values?.no_bukti_mutasi ?? "-"}
            />
            <InfoItemHorizontal
              label="Nominal Mutasi Petty Cash"
              text={
                RupiahConvert(
                  parseFloat(values?.nominal_mutasi ?? 0).toString()
                ).getWithComa
              }
            />
            <InfoItemHorizontal
              label="Tgl. Awal Petty Cash"
              text={
                values?.tgl_awal_petty_cash
                  ? DateConvert(new Date(values?.tgl_awal_petty_cash)).detail
                  : "-"
              }
            />
            <InfoItemHorizontal
              label="Tgl. Akhir Petty Cash"
              text={
                values?.tgl_akhir_petty_cash
                  ? DateConvert(new Date(values?.tgl_akhir_petty_cash)).detail
                  : "-"
              }
            />
          </Col>
        </Row>
        <hr />

        <Row>
          <Col md="3">
            <InfoItemVertical
              label="Tgl. Penerimaan Petty Cash"
              text={dateConvert().getSlashDMY(
                new Date(values?.tgl_penerimaan_petty_cash)
              )}
            />
          </Col>
          <Col md="3">
            <InfoItemVertical
              label="No. Penerimaan Petty Cash"
              text={values?.no_penerimaan_petty_cash}
            />
          </Col>
          <Col md="3">
            <InfoItemVertical
              label="Nominal Penerimaan Petty Cash"
              text={rupiahConvert().getWithComa(
                String(parseInt(values?.nominal_penerimaan_petty_cash))
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InfoItemVertical label="Keterangan" text={values?.keterangan} />
          </Col>
        </Row>
        <Row>
          <Col>
            <small style={{ fontSize: 12, display: "block" }}>
              Bukti Penerimaan Petty Cash
            </small>
            <img
              style={{ minWidth: 150, maxWidth: 200, height: "auto" }}
              src={values.bukti.url}
              alt="bukti penerimaan petty cash"
            />
          </Col>
        </Row>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Row>
        <Col>
          <InfoItemHorizontal
            label="Tgl. Pengajuan Petty Cash"
            text={
              values?.tgl_pengajuan_petty_cash
                ? DateConvert(new Date(values?.tgl_pengajuan_petty_cash)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="No. Pengajuan Petty Cash"
            text={values?.no_pengajuan_petty_cash ?? "-"}
          />
          <InfoItemHorizontal
            label="Nominal Pengajuan Petty Cash"
            text={
              RupiahConvert(
                parseFloat(values?.nominal_pengajuan ?? 0).toString()
              ).getWithComa
            }
          />
          <InfoItemHorizontal
            label="Unit Produksi"
            text={values?.nama_unit_produksi ?? "-"}
          />
          <InfoItemHorizontal label="Divisi" text={values.kode_modul} />
        </Col>
        <Col>
          <InfoItemHorizontal
            label="Tgl. Mutasi Petty Cash"
            text={
              values?.tgl_pengajuan_petty_cash
                ? DateConvert(new Date(values?.tgl_pengajuan_petty_cash)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="No. Mutasi Petty Cash"
            text={values?.no_bukti_mutasi ?? "-"}
          />
          <InfoItemHorizontal
            label="Nominal Pengajuan Petty Cash"
            text={
              RupiahConvert(parseFloat(values?.nominal_mutasi ?? 0).toString())
                .getWithComa
            }
          />
          <InfoItemHorizontal
            label="Tgl. Awal Petty Cash"
            text={
              values?.tgl_awal_petty_cash
                ? DateConvert(new Date(values?.tgl_awal_petty_cash)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="Tgl. Akhir Petty Cash"
            text={
              values?.tgl_akhir_petty_cash
                ? DateConvert(new Date(values?.tgl_akhir_petty_cash)).detail
                : "-"
            }
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <DatePicker
            label="Tgl. Penerimaan Petty Cash"
            dateFormat="dd/MM/yyyy"
            selected={
              values.tgl_penerimaan_petty_cash
                ? new Date(values.tgl_penerimaan_petty_cash)
                : ""
            }
            onChange={async (date) => {
              if (action === "CREATE") {
                const noPettyCash = await generateNoPettyCash(date);

                setValues({
                  ...values,
                  no_penerimaan_petty_cash: noPettyCash,
                  tgl_penerimaan_petty_cash: date,
                });
                return;
              }
              setValues({ ...values, tgl_penerimaan_petty_cash: date });
            }}
            error={
              errors.tgl_penerimaan_petty_cash &&
              touched.tgl_penerimaan_petty_cash &&
              true
            }
            errorText={errors.tgl_penerimaan_petty_cash}
          />
        </Col>

        <Col>
          <Input
            disabled
            label="No. Penerimaan Petty Cash"
            className="d-flex flex-column"
            value={values.no_penerimaan_petty_cash}
          />
        </Col>

        <Col>
          <InputCurrency
            disabled
            label="Nominal Penerimaan Petty Cash"
            className="d-flex flex-column text-right"
            value={parseFloat(values.nominal_penerimaan_petty_cash ?? 0)}
          />
        </Col>
      </Row>

      <TextArea
        label="Keterangan"
        placeholder="Masukkan Keterangan"
        name="keterangan"
        value={values.keterangan}
        onChange={handleChange}
        error={Boolean(errors.keterangan && touched.keterangan)}
        errorText={Boolean(errors.keterangan) && errors.keterangan}
      />

      <CustomUploadFile
        label="Bukti Penerimaan Petty Cash"
        name="bukti"
        fileName={values?.bukti?.name ?? ""}
        onChange={handleChangeFile}
        error={Boolean(errors.bukti && touched.bukti)}
        errorText={errors.bukti}
      />

      <img
        className="mt-3"
        style={{ minWidth: 150, maxWidth: 200, height: "auto" }}
        src={values?.bukti?.url ?? ""}
        alt=""
      />
    </Fragment>
  );
};
