import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Input, ActionButton, TextArea, Alert } from "components";
import { ProfilApi } from "api";

const FormDataKontak = ({ dataKaryawan }) => {
  const [showAlert, setShowAlert] = useState({
    show: false,
    text: "",
    variant: "primary",
  });

  const formInitialValues = {
    id_karyawan: dataKaryawan.id_karyawan,
    no_telp: dataKaryawan.no_telp,
    no_hp: dataKaryawan.no_hp,
    email: dataKaryawan.email,
    kontak_darurat: dataKaryawan.kontak_darurat,
    keterangan_kontak_darurat: dataKaryawan.keterangan_kontak_darurat,
  };

  const formValidationSchema = Yup.object().shape({
    no_telp: Yup.number()
      .required("Masukan nomor telephone")
      .typeError("Nomor telephone harus berupa angka")
      .nullable(),
    no_hp: Yup.number()
      .typeError("Nomor handphone harus berupa angka")
      .nullable(),
    kontak_darurat: Yup.string().required("Masukan kontak darurat").nullable(),
  });

  const formSubmitHandler = (values, { setSubmitting }) => {
    ProfilApi.updateDataKontak(values)
      .then(() => {
        setShowAlert({
          show: true,
          variant: "primary",
          text: "Ubah data sukses!",
        });
      })
      .catch((err) => {
        setShowAlert({
          show: true,
          variant: "danger",
          text: `Ubah data gagal! (${err})`,
        });
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={formValidationSchema}
      onSubmit={formSubmitHandler}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit} className="py-2">
          <Alert
            show={showAlert.show}
            showCloseButton={true}
            text={showAlert.text}
            variant={showAlert.variant}
            onClose={() =>
              setShowAlert({
                ...showAlert,
                show: false,
              })
            }
          />
          <Input
            type="text"
            label="No. Telp"
            placeholder="Masukan nomor telephone"
            name="no_telp"
            value={values.no_telp}
            error={errors.no_telp && touched.no_telp && true}
            errorText={errors.no_telp}
            onChange={handleChange}
          />
          <Input
            type="text"
            label="No. HP"
            placeholder="Masukan nomor handphone"
            name="no_hp"
            value={values.no_hp}
            error={errors.no_hp && touched.no_hp && true}
            errorText={errors.no_hp}
            onChange={handleChange}
          />
          <Input
            type="email"
            label="Email"
            placeholder="Masukan email"
            name="email"
            value={values.email}
            error={errors.email && touched.email && true}
            errorText={errors.email}
            onChange={handleChange}
          />
          <Input
            type="text"
            label="Kontak Darurat"
            placeholder="Masukan kontak darurat"
            name="kontak_darurat"
            value={values.kontak_darurat}
            error={errors.kontak_darurat && touched.kontak_darurat && true}
            errorText={errors.kontak_darurat}
            onChange={handleChange}
          />
          <TextArea
            label="Keterangan Kontak Darurat"
            placeholder="Masukan keterangan kontak darurat"
            name="keterangan_kontak_darurat"
            value={values.keterangan_kontak_darurat}
            error={
              errors.keterangan_kontak_darurat &&
              touched.keterangan_kontak_darurat &&
              true
            }
            errorText={errors.keterangan_kontak_darurat}
            onChange={handleChange}
          />
          <div className="d-flex justify-content-end mt-3">
            <ActionButton
              type="submit"
              variant="success"
              text="Ubah Data Kontak"
              loading={isSubmitting}
            />
          </div>
        </form>
      )}
    </Formik>
  );
};

export default FormDataKontak;
