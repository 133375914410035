import React, { useEffect, useState } from "react";
import { Alert, SelectSearch, Input, ReactNumberFormat } from "components";
import { DecimalConvert, RupiahConvert } from "utilities";

const TableBarangJadi = ({
  dataBarangJadi,
  setDataBarangJadi,
  diskon,
  ppn,
  setFieldValue,
  type = "CREATE",
}) => {
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const decimalConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, "");
    const convert = newValue.substring(0, 5);
    return convert;
  };

  const calculateItemProfit = (harga_satuan) => {
    const harga = parseFloat(harga_satuan ?? 0);
    return harga + (harga * 10) / 100;
  };

  const grandTotal = dataBarangJadi?.reduce((acc, { harga_satuan, qty }) => {
    const subtotal = harga_satuan * parseFloat(qty ?? 0);
    return parseFloat(acc + subtotal);
  }, 0);

  const calculateDiskon = () => {
    const checkDiskon = diskon ? parseFloat(diskon) : 0;
    const totalDiskon = (checkDiskon * grandTotal) / 100;
    const totalSetelahDiskon = grandTotal - totalDiskon;

    return {
      totalDiskon: parseFloat(totalDiskon),
      totalSetelahDiskon: parseFloat(totalSetelahDiskon),
    };
  };

  const calculatePPN = () => {
    const checkPPN = ppn ? parseFloat(ppn) : 0;
    const totalSetelahDiskon = calculateDiskon().totalSetelahDiskon;
    const totalPPN = (checkPPN * totalSetelahDiskon) / 100;
    const totalSetelahPPN = totalSetelahDiskon + totalPPN;

    return {
      totalPPN: parseFloat(totalPPN),
      totalSetelahPPN: parseFloat(totalSetelahPPN),
    };
  };

  useEffect(() => {
    console.log("dataBarangJadi", dataBarangJadi);

    return () => {};
  }, [dataBarangJadi]);

  return (
    <>
      <div className="py-2 p-1">
        <b>List Barang Jadi</b>
      </div>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      <table
        className="table table-sm table-bordered"
        style={{ fontSize: "14px" }}>
        <thead className="text-center bg-light">
          <tr>
            <th className="align-middle" style={{ width: 30 }}>
              No.
            </th>
            <th className="align-middle" style={{ width: 100 }}>
              Kode Barang Jadi
            </th>
            <th className="align-middle" style={{ width: 250 }}>
              Nama Barang Jadi
            </th>
            <th className="align-middle" style={{ width: 280 }}>
              Gudang
            </th>
            <th className="align-middle">Qty. RAB</th>
            <th className="align-middle">Satuan</th>
            <th className="align-middle">Harga Satuan</th>
            <th className="align-middle">Sub Total</th>
          </tr>
        </thead>
        <tbody>
          {dataBarangJadi?.map((val, index) => {
            const checkQty = val.qty ? parseFloat(val.qty) : 0;
            const harga = val.harga_satuan ? parseFloat(val.harga_satuan) : 0;
            const harga_profit = calculateItemProfit(harga);
            const subTotal = parseFloat(checkQty * val.harga_satuan);
            const mapGudang = val?.gudang?.map((item) =>
              Object({
                ...item,
                value: item.id_gudang,
                label: item.nama_gudang,
              })
            );

            return (
              <tr key={index}>
                <td className="text-center">{index + 1}</td>
                <td>{val.kode_item}</td>
                <td>{val.nama_item}</td>
                <td>
                  {type === "DETAIL" ? (
                    val.nama_gudang
                  ) : (
                    <SelectSearch
                      wrapperStyle={{ width: 280 }}
                      placeholder="Pilih Gudang"
                      defaultValue={
                        val.id_gudang
                          ? mapGudang.find(
                              (item) => item.value === val.id_gudang
                            )
                          : ""
                      }
                      option={mapGudang}
                      onChange={(e) => {
                        const newData = dataBarangJadi?.map((item, idx) =>
                          index === idx ? { ...item, id_gudang: e.value } : item
                        );
                        setDataBarangJadi(newData);
                      }}
                    />
                  )}
                </td>
                <td className="text-right">
                  {DecimalConvert(val.qty).getWithComa}
                </td>
                <td>{val.satuan}</td>
                <td className="text-right">
                  {
                    RupiahConvert(parseFloat(val.harga_satuan ?? 0).toString())
                      .getWithComa
                  }
                </td>
                <td className="text-right">
                  {RupiahConvert(subTotal?.toString()).getWithComa}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          {/* Total */}
          <tr className="bg-light">
            <td colSpan={7} className="text-right py-2 align-middle">
              <b>Total</b>
            </td>
            <td className="text-right align-middle">
              <b>{RupiahConvert(String(grandTotal)).getWithComa}</b>
            </td>
          </tr>

          {/* DISKON */}
          <tr>
            <td colSpan={7} className="text-right py-2 align-middle">
              <div className="d-flex align-items-center justify-content-end">
                <b>Diskon (%)</b>
                <div style={{ width: 80, paddingLeft: 10 }}>
                  {/* <Input
                    size="sm"
                    noMargin
                    readOnly={type === "DETAIL"}
                    placeholder="Diskon %"
                    className="text-right"
                    value={diskon}
                    onChange={(e) => {
                      const value = e.target.value;
                      const convert = decimalConvert(value);
                      setFieldValue("diskon", convert);
                    }}
                  /> */}
                  <ReactNumberFormat
                    disabled
                    className="text-right"
                    value={parseFloat(diskon)}
                    // onChange={(e) => setFieldValue("ppn", e)}
                  />
                </div>
              </div>
            </td>
            <td className="text-right align-middle">
              <b>
                {
                  RupiahConvert(String(calculateDiskon().totalDiskon))
                    .getWithComa
                }
              </b>
            </td>
          </tr>

          {/* TOTAL SETELAH DISKON */}
          <tr className="bg-light">
            <td colSpan={7} className="text-right py-2 align-middle">
              <b>Total Setelah Dikurangi Diskon</b>
            </td>
            <td className="text-right align-middle">
              <b>
                {
                  RupiahConvert(String(calculateDiskon().totalSetelahDiskon))
                    .getWithComa
                }
              </b>
            </td>
          </tr>

          {/* PPN */}
          <tr>
            <td colSpan={7} className="text-right py-2 align-middle">
              <div className="d-flex align-items-center justify-content-end">
                <b>PPN (%)</b>
                <div style={{ width: 80, paddingLeft: 10 }}>
                  {/* <Input
                    size="sm"
                    noMargin
                    readOnly={type === "DETAIL"}
                    placeholder="PPN %"
                    className="text-right"
                    value={ppn}
                    onChange={(e) => {
                      const value = e.target.value;
                      const convert = decimalConvert(value);
                      setFieldValue("ppn", convert);
                    }}
                  /> */}
                  <ReactNumberFormat
                    disabled
                    className="text-right"
                    value={parseFloat(ppn)}
                    // onChange={(e) => setFieldValue("ppn", e)}
                  />
                </div>
              </div>
            </td>
            <td className="text-right align-middle">
              <b>
                {RupiahConvert(String(calculatePPN().totalPPN)).getWithComa}
              </b>
            </td>
          </tr>

          {/* TOTAL SETELAH PPN */}
          <tr className="bg-light">
            <td colSpan={7} className="text-right py-2 align-middle">
              <b>Total Setelah Ditambah PPN</b>
            </td>
            <td className="text-right align-middle">
              <b>
                {
                  RupiahConvert(String(calculatePPN().totalSetelahPPN))
                    .getWithComa
                }
              </b>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
};

export default TableBarangJadi;
