import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { Table, Th, Td, ThFixed, TdFixed, DataStatus } from "components";
import { DateConvert, DecimalConvert } from "utilities";
import { RAEApi } from "api";

const ModalPeluang = ({ modalPeluangConfig, setModalPeluangConfig }) => {
  const [dataInfoPeluang, setDataInfoPeluang] = useState({});
  const [dataTablePeluang, setDataTablePeluang] = useState([]);
  const [fetchPeluangStatus, setFetchPeluangStatus] = useState({
    loading: true,
    success: false,
  });
  const { id_peluang } = modalPeluangConfig.data_peluang;

  const getDataPeluang = (id_peluang) => {
    setFetchPeluangStatus({
      loading: true,
      success: false,
    });

    RAEApi.getSinglePeluang({ id_peluang })
      .then((res) => {
        const dataInfo = res.data.data ?? {};
        const dataTable = res.data.data.detail ?? {};

        setDataInfoPeluang(dataInfo);
        setDataTablePeluang(dataTable);
        setFetchPeluangStatus({
          loading: false,
          success: true,
        });
      })
      .catch(() => {
        setFetchPeluangStatus({
          loading: false,
          success: false,
        });
      });
  };

  useEffect(() => {
    if (id_peluang) {
      getDataPeluang(id_peluang);
    } else {
      setFetchPeluangStatus({
        loading: false,
        success: false,
      });
    }
  }, []);

  const InfoItemModal = ({ label, text, className, style, width }) => (
    <div className={`d-flex align-items-top ${className}`} style={style}>
      <div style={{ width: width ? width : 150, fontSize: 14 }}>{label}</div>
      <div className="pl-3 pr-2" style={{ fontSize: 14 }}>
        :
      </div>
      <div style={{ fontSize: 14 }}>{text}</div>
    </div>
  );

  if (fetchPeluangStatus.loading || !fetchPeluangStatus.success) {
    return (
      <Modal
        size="lg"
        show={modalPeluangConfig.show}
        onHide={() =>
          setModalPeluangConfig({
            ...modalPeluangConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton>
          <b>Detail Peluang</b>
        </Modal.Header>
        <Modal.Body>
          {fetchPeluangStatus.loading ? (
            <DataStatus loading text="Memuat data . . ." />
          ) : (
            <DataStatus text="Data gagal dimuat" />
          )}
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal
      size="lg"
      show={modalPeluangConfig.show}
      onHide={() =>
        setModalPeluangConfig({
          ...modalPeluangConfig,
          show: false,
        })
      }
    >
      <Modal.Header closeButton>
        <b>Detail Peluang</b>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md>
            <InfoItemModal
              label="Tgl. Peluang"
              text={
                dataInfoPeluang.tgl_peluang
                  ? DateConvert(new Date(dataInfoPeluang.tgl_peluang)).detail
                  : "-"
              }
            />
            <InfoItemModal
              label="No. Peluang"
              text={dataInfoPeluang.no_peluang ?? "-"}
            />
          </Col>
          <Col md>
            <InfoItemModal
              label="Peringkat Peluang"
              text={dataInfoPeluang.nama_peringkat_peluang ?? "-"}
            />
            <InfoItemModal
              label="Customer"
              text={dataInfoPeluang.nama_customer ?? "-"}
            />
            <InfoItemModal
              label="Proyek"
              text={dataInfoPeluang.nama_proyek ?? "-"}
            />
            <InfoItemModal label="ATT" text={dataInfoPeluang.att ?? "-"} />
          </Col>
        </Row>
        <hr />
        <div className="my-2">
          <b>Barang Jadi</b>
        </div>
        <Table>
          <thead>
            <tr>
              <ThFixed>No</ThFixed>
              <Th style={{ maxWidth: 80 }} className="py-2">
                Kode Barang Jadi
              </Th>
              <Th style={{ minWidth: 200 }}>Nama Barang Jadi</Th>
              <Th>Jenis Barang Jadi</Th>
              <Th>Satuan</Th>
              <Th>Qty</Th>
            </tr>
          </thead>
          <tbody>
            {dataTablePeluang && dataTablePeluang.length > 0 ? (
              dataTablePeluang.map((val, index) => (
                <tr>
                  <TdFixed>{index + 1}</TdFixed>
                  <Td>{val.kode_item}</Td>
                  <Td>{val.nama_item}</Td>
                  <Td>{val.nama_jenis}</Td>
                  <Td>{val.nama_satuan_jual}</Td>
                  <Td textRight>{DecimalConvert(val.qty).getWithComa}</Td>
                </tr>
              ))
            ) : (
              <tr>
                <Td colSpan={6}>
                  <DataStatus text="Tidak data data" />
                </Td>
              </tr>
            )}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default ModalPeluang;
