// React
import React, { useState, useEffect } from "react"

// Router
import { useLocation } from "react-router-dom"

// Components
import { CRUDLayout, Alert, DataStatus } from "components"

// API
import { JobAnalisaBarangJadiApi } from "api"

// View Components
import { DataTable, SearchTable } from '../tables'

const ApprovalTab = () => {
  // Hooks
  const location = useLocation()

  // States
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [data, setData] = useState([])
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })
  const [searchTime, setSearchTime] = useState(null);

  const getInitialData = () => {
    setIsPageLoading(true)

    JobAnalisaBarangJadiApi.get({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    }).then(({ data }) => {
      setData(data.data)
      setPaginationConfig({
        ...paginationConfig,
        dataCount: data.data_count,
        totalPage: data.total_page,
      })
      // searchConfig.status && setAlertConfig({
      //   show: true,
      //   variant: "primary",
      //   text: `Hasil dari pencarian: ${searchConfig.key}`,
      // })
    }).catch(() => {
      setAlertConfig({
        show: true,
        variant: "danger",
        text: "Data gagal dimuat!",
      })
    }).finally(() => setIsPageLoading(false))
  }

  const onInputSearchChange = (e) => {
    const key = e.target.value
    if(searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: e.target.value,
        })
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        })
      }, 750)
    )
  }

  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        })
      }
    }
  }

  useEffect(() => {
    getInitialData()
    checkAlert()

    return () => {
      setIsPageLoading(false)
    }
  }, [searchConfig.key, paginationConfig.page, paginationConfig.dataLength])

  return (
    <CRUDLayout>
      {/* head section */}
      <SearchTable 
        // searchConfig={searchConfig} 
        // setSearchConfig={setSearchConfig}
        // setAlertConfig={setAlertConfig}
        onInputSearchChange={onInputSearchChange}
      />

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : data ? (
        data.length > 0 ? (
          <DataTable 
            data={data} 
            searchConfig={searchConfig} 
            paginationConfig={paginationConfig} 
            setPaginationConfig={setPaginationConfig} 
          />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}
    </CRUDLayout>
  )
}

export default ApprovalTab
