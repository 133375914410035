import React from "react";

const TdFixed = ({ children, colSpan, rowSpan, textRight, className }) => {
  return (
    <td
      colSpan={colSpan}
      rowSpan={rowSpan}
      className={`p-1 px-2 ${textRight && "text-right"} ${className}`}
      style={{
        fontSize: "12px",
      }}
    >
      {children}
    </td>
  );
};

export default TdFixed;
