// Router
import { useHistory } from "react-router-dom"

// Components
import { 
	ActionButton, Pagination, Table, TBody, Td, TdFixed, 
	Th, THead, ThFixed, Tr 
} from "components"
import { PageNumber } from "utilities"

const DataTable = ({ 
	data, 
	searchConfig, 
	isHistory,
	paginationConfig, 
	setPaginationConfig 
}) => {
	// Hooks
	const history = useHistory()

	const getStatusApprovalLabel = (status_approval) => {
    switch (status_approval) {
      case "APP":
        return "APPROVED"
      case "REJ":
        return "REJECT"
      case "REV":
        return "REVISI"
      case "VER":
        return "VERIFIED"
      default:
        return "PENDING"
    }
  }

	return (
		<>
			<Table>
				<THead>
					<Tr>
						<ThFixed>No</ThFixed>
						<Th>Pembuat Pengajuan</Th>
						<Th>Jabatan Pembuat Pengajuan</Th>
						<ThFixed>Kode Barang Jadi</ThFixed>
						<Th style={{ minWidth: '250px' }}>Nama Barang Jadi</Th>
						<Th style={{ width: '150px' }}>Nama Varian</Th>
						<Th style={{ width: '150px' }}>Jenis Barang Jadi</Th>
						<ThFixed>Status Approval</ThFixed>
						<Th style={{ width: "8.5rem" }}>Detail Pengajuan</Th>
					</Tr>
				</THead>
				<TBody>
					{data.map((val, index) => (
						<Tr key={index}>
							<TdFixed>
								{PageNumber(paginationConfig.page, paginationConfig.dataLength, index)}
							</TdFixed>
							<Td>{val.nama_karyawan_pengaju ? val.nama_karyawan_pengaju : "-"}</Td>
							<Td>{val.nama_jabatan_pengaju ? val.nama_jabatan_pengaju : "-"}</Td>
							<TdFixed>{val.kode_item ?? "-"}</TdFixed>
              <Td>{val.nama_item ?? "-"}</Td>
              <Td>{val.nama_varian ?? "-"}</Td>
              <Td>{val.nama_jenis ?? "-"}</Td>
							<TdFixed>
								{val.status_approval ? getStatusApprovalLabel(val.status_approval) : "PENDING"}
							</TdFixed>
							<Td>
								<ActionButton
									size="sm"
									text="Lihat Detail"
									className="col"
									onClick={() => history.push(`/human-resource/approval/job-mix-design/${isHistory ? "history" : "detail"}/${val.id_jobmix_design}`)}
								/>
							</Td>
						</Tr>
					))}
				</TBody>
			</Table>
			{!searchConfig.status && (
				<Pagination
					dataLength={paginationConfig.dataLength}
					dataNumber={
						paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1
					}
					dataPage={
						paginationConfig.dataCount < paginationConfig.dataLength
							? paginationConfig.dataCount
							: paginationConfig.page * paginationConfig.dataLength
					}
					dataCount={paginationConfig.dataCount}
					currentPage={paginationConfig.page}
					totalPage={paginationConfig.totalPage}
					onPaginationChange={({ selected }) =>
						setPaginationConfig({
							...paginationConfig,
							page: selected + 1,
						})
					}
					onDataLengthChange={(e) =>
						setPaginationConfig({
							...paginationConfig,
							page: 1,
							dataLength: e.target.value,
						})
					}
				/>
			)}
		</>
	)
}

export default DataTable