import React, { useEffect, useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import {
  CRUDLayout,
  Pagination,
  Table,
  Td,
  TdFixed,
  Th,
  ThFixed,
} from "components";
import {
  ButtonBack,
  ButtonCreate,
  ButtonFilter,
  DataStatus,
  InputSearch,
} from "components2";
import { dateConvert, rupiahConvert, tableNumber } from "utilities2";
import RealisasiPettyCashApi from "./__RealisasiPettyCashApi__";
import { ModalFilter, ModalFormRincian } from "./__RealisasiPettyCashComps__";
import { Formik } from "formik";

const PenerimaanRealisasiPettyCashList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const [pagination, setPagination] = useState({
    q: "",
    page: 1,
    per_page: 10,
  });

  const [filter, setFilter] = useState({
    q: "",
    page: 1,
    per_page: 10,
    tgl_penerimaan_petty_cash_start: undefined,
    tgl_penerimaan_petty_cash_end: undefined,
    tgl_mutasi_petty_cash_start: undefined,
    tgl_mutasi_petty_cash_end: undefined,
    tgl_awal_petty_cash_start: undefined,
    tgl_awal_petty_cash_end: undefined,
    tgl_akhir_petty_cash_start: undefined,
    tgl_akhir_petty_cash_end: undefined,
    id_unit_produksi: undefined,
    active: false,
  });

  const [modalFilter, setModalFilter] = useState(false);

  const getPenerimaan = useQuery(
    ["penerimaan_realisasi_petty_cash", "list", pagination, filter],
    () => RealisasiPettyCashApi.getListPenerimaan({ ...pagination, ...filter })
  );

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setPagination({
      ...pagination,
      q: searchKey,
    });
  };

  const onFilterButtonClickkHandler = () => setModalFilter(!modalFilter);

  const onCreateButtonClickHandler = (id) =>
    history.push(`/human-resource/transaksi/realisasi-petty-cash/tambah/${id}`);

  const onPaginationChange = ({ selected }) =>
    setPagination({ ...pagination, page: selected + 1 });

  const onPaginationDataLengthChange = (e) =>
    setPagination({ ...pagination, page: 1, per_page: e.target.value });

  const formInitialValuesFilter = {
    tgl_penerimaan_petty_cash_start: filter?.tgl_penerimaan_petty_cash_start,
    tgl_penerimaan_petty_cash_end: filter?.tgl_penerimaan_petty_cash_end,
    tgl_mutasi_petty_cash_start: filter?.tgl_mutasi_petty_cash_start,
    tgl_awal_petty_cash_end: filter?.tgl_awal_petty_cash_end,
    tgl_akhir_petty_cash_start: filter?.tgl_akhir_petty_cash_start,
    tgl_akhir_petty_cash_end: filter?.tgl_akhir_petty_cash_start,
    id_unit_produksi: filter?.id_unit_produksi,
  };

  const formSubmitHandlerFilter = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setFilter({
      ...filter,
      ...values,
      active: checkActive,
      page: 1,
    });
    onFilterButtonClickkHandler();
  };

  useEffect(() => setNavbarTitle("Realisasi Petty Cash"), []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-0">
            <InputSearch onChange={debounce(searchHandler, 1500)} />
            <ButtonFilter
              size="sm"
              className="text-nowrap ml-3"
              onClick={onFilterButtonClickkHandler}
              active={filter?.active}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <ButtonBack size="md" onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {getPenerimaan.isLoading || getPenerimaan.isError ? (
        <DataStatus
          loading={getPenerimaan.isLoading}
          text={getPenerimaan.isLoading ? "Memuat . . ." : "Data gagal dimuat"}
        />
      ) : (
        <>
          <div className="mt-2">
            <small className="font-weight-bold">
              List Data Penerimaan Petty Cash
            </small>
          </div>

          <Table>
            <thead>
              <tr>
                <ThFixed rowSpan={2}>No.</ThFixed>
                <Th rowSpan={2} style={{ width: 90 }} className="p-1">
                  Informasi Penerimaan Petty Cash
                </Th>
                <Th rowSpan={2} style={{ minWidth: 100 }} className="p-1">
                  Informasi Bukti Mutasi
                </Th>
                <Th rowSpan={2} style={{ minWidth: 150 }} className="p-1">
                  Unit Produksi
                </Th>
                <Th rowSpan={2} style={{ minWidth: 150 }} className="p-1">
                  Nominal Penerimaan Petty Cash
                </Th>
                <Th colSpan={2} style={{ minWidth: 150 }} className="p-1">
                  Masa Berlaku Petty Cash
                </Th>
                <Th rowSpan={2} style={{ width: 100 }} className="p-1">
                  Sisa Masa Berlaku Petty Cash
                </Th>
                <ThFixed rowSpan={2}>Aksi</ThFixed>
              </tr>
              <tr>
                <Th>Tgl. Awal Petty Cash</Th>
                <Th>Tgl. Akhir Petty Cash</Th>
              </tr>
            </thead>
            <tbody>
              {getPenerimaan?.data?.data?.length > 0 ? (
                getPenerimaan?.data?.data?.map((e, index) => (
                  <tr key={index}>
                    <TdFixed>
                      {tableNumber({
                        pageCurrent: pagination.page,
                        dataLength: pagination.per_page,
                        index,
                      })}
                    </TdFixed>
                    <Td>
                      <div>
                        {dateConvert().getSlashDMY(
                          new Date(e.tgl_penerimaan_petty_cash)
                        )}
                      </div>
                      <div>{e.no_penerimaan_petty_cash}</div>
                    </Td>
                    <Td>
                      <div>
                        {dateConvert().getSlashDMY(
                          new Date(e.tgl_mutasi_petty_cash)
                        )}
                      </div>
                      <div>{e.no_bukti_mutasi}</div>
                    </Td>
                    <Td>{e.nama_unit_produksi}</Td>
                    <Td textRight>
                      {rupiahConvert().getWithComa(
                        e.nominal_penerimaan_petty_cash ?? 0
                      )}
                    </Td>
                    <Td>
                      {dateConvert().getSlashDMY(
                        new Date(e.tgl_awal_petty_cash)
                      )}
                    </Td>
                    <Td>
                      {dateConvert().getSlashDMY(
                        new Date(e.tgl_akhir_petty_cash)
                      )}
                    </Td>
                    <Td textCenter>{e.sisa_durasi} Hari</Td>
                    <TdFixed>
                      <ButtonGroup>
                        <ButtonCreate
                          icon
                          noText
                          onClick={() =>
                            onCreateButtonClickHandler(
                              e.id_penerimaan_petty_cash
                            )
                          }
                        />
                      </ButtonGroup>
                    </TdFixed>
                  </tr>
                ))
              ) : (
                <tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </tr>
              )}
            </tbody>
          </Table>

          {getPenerimaan?.data?.data?.length > 0 && (
            <Pagination
              dataLength={pagination?.per_page}
              dataNumber={
                pagination?.page * pagination?.per_page -
                pagination?.per_page +
                1
              }
              dataPage={
                pagination?.dataCount < pagination?.per_page
                  ? pagination?.dataCount
                  : pagination?.page * pagination?.per_page
              }
              dataCount={getPenerimaan?.data?.data_count}
              currentPage={pagination?.page}
              totalPage={getPenerimaan?.data?.total_page}
              onPaginationChange={onPaginationChange}
              onDataLengthChange={onPaginationDataLengthChange}
            />
          )}
        </>
      )}

      {modalFilter && (
        <Formik
          enableReinitialize
          initialValues={formInitialValuesFilter}
          onSubmit={formSubmitHandlerFilter}
        >
          <ModalFilter
            data={filter}
            setData={setFilter}
            toggle={onFilterButtonClickkHandler}
            preFormPage
          />
        </Formik>
      )}
    </CRUDLayout>
  );
};

export default PenerimaanRealisasiPettyCashList;
