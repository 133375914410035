import React, { useState } from 'react'
import { Card, Nav } from 'react-bootstrap'
import { TabInfo, TabGambar, TabFile } from './tabs'

const InfoSection = ({ data, dataGambar, dataFile }) => {
  const [infoTabs, setInfoTabs] = useState('info')

  const onTabsChangeHandler = (e, newPage) => {
    e.preventDefault();
    setInfoTabs(newPage);
  };

  const tabsConfig = [
    {
      tab: "info",
      label: "Informasi Umum",
      component: () => (
        <TabInfo data={data} />
      ),
    },
    {
      tab: 'gambar',
      label: 'Gambar',
      component: () => (
        <TabGambar
          type='detail'
          dataGambar={dataGambar}
        />
      )
    },
    {
      tab: 'file',
      label: 'File',
      component: () => (
        <TabFile
          type='detail'
          dataFile={dataFile}
        />
      )
    },
  ]
  return (
    <Card className="pb-3 mt-3">
      <Card.Header>
        {/* Tab Header */}
        <Nav variant="tabs" defaultActiveKey={`#${infoTabs}`}>
          {tabsConfig.map((val, index) => (
            <Nav.Item key={`${index}-${val.label}`}>
              <Nav.Link href={`#${val.tab}`} onClick={(e) => onTabsChangeHandler(e, val.tab)}>
                {val.label}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </Card.Header>

      <Card.Body>
        {tabsConfig.map(({ tab, component: Component }, index) => tab === infoTabs && <Component key={index} />)}
      </Card.Body>
    </Card>)
}

export default InfoSection
