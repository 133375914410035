import { dateConvert } from '@bhawanadevteam/react-core';

export const formSubmitValueMapper = (data = {}) => {
  return {
    tgl_pengembalian_sisa_petty_cash: dateConvert().getDashYMD(
      data?.tgl_pengembalian_sisa_petty_cash
    ),
    id_realisasi_petty_cash: data?.id_realisasi_petty_cash,
    nominal_sisa_petty_cash: data?.sisa_petty_cash,
  };
};
