//  TAMPILAN APPROVAL PURCHASE REQUEST TERBARU

import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  ReadButton,
  UpdateButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  Checkbox,
  ApprovalStatusButton,
  FilterButton,
} from "components";
import { DateConvert, PageNumber as TableNumber } from "utilities";
import { PurchaseRequestApi } from "api";
import { ModalFilter } from "../Section";

const HistoryApprovalPurchaseRequest = () => {
  const history = useHistory();
  const location = useLocation();

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [showModalFilter, setShowModalFilter] = useState(false);

  // STATE DATA
  const [dataPR, setDataPR] = useState([]);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.history?.filter?.active,
      tgl_purchase_request_mulai:
        location?.state?.history?.filter?.tgl_purchase_request_mulai,
      tgl_purchase_request_selesai:
        location?.state?.history?.filter?.tgl_purchase_request_selesai,
      tgl_pemakaian_mulai:
        location?.state?.history?.filter?.tgl_pemakaian_mulai,
      tgl_pemakaian_selesai:
        location?.state?.history?.filter?.tgl_pemakaian_selesai,
      keperluan: location?.state?.history?.filter?.keperluan,
      status_approval: location?.state?.history?.filter?.status_approval,
      item_barang: location?.state?.history?.filter?.item_barang,
      karyawan_pengaju: location?.state?.approval?.filter?.karyawan_pengaju,
      jabatan: location?.state?.approval?.filter?.jabatan,
    },
    pagination: {
      page: location?.state?.history?.filter?.page ?? "1",
      dataLength: location?.state?.history?.filter?.dataLength ?? "10",
      totalPage: location?.state?.history?.filter?.totalPage ?? "1",
      dataCount: location?.state?.history?.filter?.dataCount ?? "0",
    },
  });

  // CONFIG
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [searchTime, setSearchTime] = useState(null);

  // REQUEST DATA SERVER
  const getInitialData = () => {
    setIsPageLoading(true);

    PurchaseRequestApi.getHistory({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_purchase_request_mulai:
        dataFilter?.filter?.tgl_purchase_request_mulai,
      tgl_purchase_request_selesai:
        dataFilter?.filter?.tgl_purchase_request_selesai,
      tgl_pemakaian_mulai: dataFilter?.filter?.tgl_pemakaian_mulai,
      tgl_pemakaian_selesai: dataFilter?.filter?.tgl_pemakaian_selesai,
      keperluan: dataFilter?.filter?.keperluan,
      status_approval: dataFilter?.filter?.status_approval,
      item_barang: dataFilter?.filter?.item_barang,
      karyawan_pengaju: dataFilter?.filter?.karyawan_pengaju,
      jabatan: dataFilter?.filter?.jabatan,
    })
      .then((data) => {
        setDataPR(data?.data?.data ?? []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          },
        });
        // searchConfig.status &&
        //   setAlertConfig({
        //     show: true,
        //     variant: "primary",
        //     text: `Hasil dari pencarian: ${searchConfig.key}`,
        //   });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;
    if(searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: e.target.value,
        });
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        });
      }, 750)
    )
  }

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const generateStatus = (status) => {
    if (status.toUpperCase() === "REV") {
      return {
        variant: "outline-revised",
        label: "REVISION",
      };
    }

    if (status.toUpperCase() === "APP") {
      return {
        variant: "outline-approved",
        label: "APPROVED",
      };
    }

    if (status.toUpperCase() === "VER") {
      return {
        variant: "outline-green-light",
        label: "VERIFIED",
      };
    }

    if (status.toUpperCase() === "REJ") {
      return {
        variant: "outline-danger",
        label: "REJECT",
      };
    }

    return {
      variant: "outline-dark",
      label: "PENDING",
    };
  };

  const decimalConvert = (value) => {
    const convertToNumber = parseFloat(value);
    const checkConvert =
      convertToNumber === NaN ? 0 : parseFloat(String(convertToNumber));

    return new Intl.NumberFormat("id-ID").format(checkConvert);
  };

  useEffect(() => {
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_purchase_request_mulai,
    dataFilter?.filter?.tgl_purchase_request_selesai,
    dataFilter?.filter?.tgl_pemakaian_mulai,
    dataFilter?.filter?.tgl_pemakaian_selesai,
    dataFilter?.filter?.keperluan,
    dataFilter?.filter?.status_approval,
    dataFilter?.filter?.item_barang,
    dataFilter?.filter?.karyawan_pengaju,
    dataFilter?.filter?.jabatan,
  ]);

  const PageContent = () => {
    const ItemBarangCollapse = ({ data }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false);

      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data.map((brg, index) => {
              if (isReadMoreClick) {
                return (
                  <li key={index} index={index}>
                    {`${brg.nama_item} ${
                      brg.qty ? decimalConvert(brg.qty) : ""
                    } ${brg.nama_satuan ? brg.nama_satuan : ""}` ?? "-"}
                  </li>
                );
              } else {
                return (
                  index <= 1 && (
                    <li key={index} index={index}>
                      {`${brg.nama_item} ${
                        brg.qty ? decimalConvert(brg.qty) : ""
                      } ${brg.nama_satuan ? brg.nama_satuan : ""}` ?? "-"}
                    </li>
                  )
                );
              }
            })}
          </ul>
          {data.length > 2 ? (
            <div
              className="text-primary"
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                // fontWeight: "bold",
              }}
              onClick={() => {
                setisReadMoreClick((prev) => !prev);
              }}
            >
              {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
            </div>
          ) : (
            ""
          )}
        </>
      );
    };
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <Th style={{ minWidth: "180px" }}>Informasi Pembuat Pengajuan</Th>
            <ThFixed>Informasi Purchase Request</ThFixed>
            <Th style={{ minWidth: "350px" }}>Item Barang</Th>
            <ThFixed>Keperluan</ThFixed>
            <ThFixed>Tgl. Pemakaian</ThFixed>
            <ThFixed>Status Approval</ThFixed>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {dataPR.map((val, index) => (
            <Tr key={index}>
              <TdFixed>
                {TableNumber(
                  dataFilter?.pagination?.page,
                  dataFilter?.pagination?.dataLength,
                  index
                )}
              </TdFixed>
              <Td>
                <div>
                  {val.nama_karyawan ?? val.nama_karyawan_pengaju ?? "-"}
                </div>
                <div>{val.nama_jabatan ?? val.nama_jabatan_pengaju ?? "-"}</div>
              </Td>
              <TdFixed>
                <div className="text-left">
                  {val.tgl_purchase_request
                    ? DateConvert(new Date(val.tgl_purchase_request)).custom
                    : "-"}
                </div>
                <div className="text-left">
                  {val.no_purchase_request ? val.no_purchase_request : "-"}
                </div>
              </TdFixed>
              <Td>
                {val.item_barang ? (
                  val.item_barang.length > 0 ? (
                    <ItemBarangCollapse data={val.item_barang} />
                  ) : (
                    "-"
                  )
                ) : (
                  "-"
                )}
              </Td>
              <TdFixed>
                <div className="text-left">
                  {val.keperluan.charAt(0).toUpperCase() +
                    val.keperluan.slice(1) ?? "-"}
                </div>
              </TdFixed>
              <TdFixed>
                {val.tgl_pemakaian
                  ? DateConvert(new Date(val.tgl_pemakaian)).custom
                  : "-"}
              </TdFixed>
              <TdFixed>
                {/* {val.status_approval ? getStatusApprovalLabel(val.status_approval) : "PENDING"} */}
                <ApprovalStatusButton
                  variant={generateStatus(val.status_approval).variant}
                >
                  {generateStatus(val.status_approval).label}
                </ApprovalStatusButton>
              </TdFixed>
              <Td>
                <div className="d-flex justify-content-center">
                  <ReadButton
                    onClick={() =>
                      history.push(
                        "/human-resource/approval/purchase-request/detail/" +
                          val.id_purchase_request,
                        {
                          no_purchase_request: val.no_purchase_request,
                          tab: "history",
                        }
                      )
                    }
                  />
                </div>
              </Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount <
              dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page *
                  dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              placeholder="Cari RAE"
              // value={searchConfig.key}
              onChange={onInputSearchChange}
            />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection></CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataPR ? (
        dataPR.length > 0 ? (
          <PageContent />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}
      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default HistoryApprovalPurchaseRequest;
