import { InfoItemHorizontal } from "components";
import { useFormikContext } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { DateConvert } from "utilities";

export const InfoPPASection = ({ data }) => {
  const { values } = useFormikContext();
  return (
    <Row>
      <Col lg="6">
        <InfoItemHorizontal
          label="Tgl. PPA"
          text={data.tgl_ppa ? DateConvert(new Date(data.tgl_ppa)).detail : "-"}
        />
        <InfoItemHorizontal label="No. PPA" text={data.no_ppa ?? "-"} />
        <InfoItemHorizontal
          label="Deskripsi"
          text={data.deskripsi_ppa ?? "-"}
        />
      </Col>
    </Row>
  );
};
