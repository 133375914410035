import { calcJumlah } from './calcJumlah';

export const formSubmitValueMapper = (data = {}) => {
  const { jumlah } = calcJumlah({ data: data.detail ?? [] });

  return {
    status_approval: 'PEN',
    id_realisasi_petty_cash: data?.id_realisasi_petty_cash,
    tgl_realisasi_petty_cash: data.tgl_realisasi_petty_cash,
    id_penerimaan_petty_cash: data.id_penerimaan_petty_cash,
    kode_modul: data.kode_modul,
    nominal_realisasi_petty_cash: jumlah,
    detail: data?.detail?.map((d) => ({
      // id_realisasi_petty_cash_detail : d.id_realisasi_petty_cash_detail,
      nama_item: d.nama_item,
      qty: d.qty,
      id_satuan: d.id_satuan,
      harga_satuan: d.harga_satuan,
      keterangan: d.keterangan,
      media: d.media ?? [],
    })),
  };
};
