import { Fragment, useMemo, useState } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { Formik, useFormikContext } from 'formik';
import { Table, Td, Th, ThFixed } from 'components';
import {
  ButtonCreate,
  ButtonDelete,
  ButtonDetail,
  ButtonUpdate,
} from 'components2';
import { rupiahConvert } from 'utilities2';
import * as Yup from 'yup';
import { calcJumlah } from '../utils';
import { ModalFormRincian } from './ModalFormRincian';

export const TableSection = ({ action = 'CREATE', data = [] }) => {
  // action : CONFIRM | DETAIL
  const [modal, setModal] = useState({
    show: false,
    type: 'CREATE', // CREATE |  UPDATE
    data: { index: null },
  });
  const { values, setValues } = useFormikContext();
  const petty_cash = values.nominal_penerimaan_petty_cash;
  let saldo_petty_cash = petty_cash;
  const isCreate = modal.type === 'CREATE';

  const initialValuesRincian = {
    index: modal?.data?.index,
    id_realisasi_petty_cash_detail: modal.data.id_realisasi_petty_cash_detail,
    nama_item: isCreate ? '' : modal.data.nama_item,
    qty: isCreate ? '' : modal.data.qty,
    id_satuan: isCreate ? '' : modal.data.id_satuan,
    nama_satuan: isCreate ? '' : modal.data.nama_satuan,
    harga_satuan: isCreate ? '' : modal.data.harga_satuan,
    keterangan: isCreate ? '' : modal.data.keterangan,
    files: isCreate ? [] : modal.data?.files ?? [],
  };

  const validationSchemaRincian = Yup.object().shape({
    nama_item: Yup.string().required('Masukkan Item'),
    qty: Yup.string().required('Masukkan Qty.'),
    id_satuan: Yup.string().required('Pilih Satuan'),
    harga_satuan: Yup.string().required('Masukkan Harga Satuan'),
    keterangan: Yup.string().required('Masukkan Keterangan'),
  });

  const jumlah = useMemo(
    () => calcJumlah({ data, petty_cash }),
    [data, petty_cash]
  );

  const checkSaldo = ({ harga_satuan = 0, qty = 0, index = null }) => {
    const itemPrice = Math.round(harga_satuan) * Math.round(qty);

    if (isCreate && !index) {
      return jumlah.sisa_petty_cash >= itemPrice;
    } else {
      const itemBeforeUpdate = data[index];
      const itemPriceBeforeUpdate =
        Math.round(itemBeforeUpdate.harga_satuan) *
        Math.round(itemBeforeUpdate.qty);
      const sisa_petty_cash = jumlah.sisa_petty_cash + itemPriceBeforeUpdate;

      return sisa_petty_cash >= itemPrice;
    }
  };

  const onSubmitRincian = (val, { resetForm, setFieldError }) => {
    const isSaldoAvail = checkSaldo({
      harga_satuan: val.harga_satuan,
      qty: val.qty,
      index: val.index,
    });

    if (isSaldoAvail) {
      if (isCreate) {
        setValues({ ...values, detail: [...values.detail, val] });
        resetForm();
        setModal({ show: false, type: 'CREATE', data: {} });
        return;
      }

      const updatedRincian = [...values.detail];
      updatedRincian[modal.data.index] = val;

      setValues((prev) => ({ ...prev, detail: updatedRincian }));
      setModal({ show: false, type: 'CREATE', data: {} });
      resetForm();
    } else {
      setFieldError('jumlah', 'Saldo tidak mencukupi');
    }
  };

  const onRemoveItem = (index) => {
    const newData = [...values.detail].filter((_, idx) => idx !== index);
    setValues((prev) => ({
      ...prev,
      detail: newData,
    }));
  };

  return (
    <Fragment>
      {action !== 'CONFIRM' && action !== 'DETAIL' && (
        <div className="d-flex justify-content-end mt-4">
          <ButtonCreate
            size="md"
            text="Tambah Rincian"
            onClick={() => setModal({ show: true, type: 'CREATE', data: {} })}
          />
        </div>
      )}
      <div className="font-weight-bold mb-1">List Data Rincian Petty Cash</div>
      <Table>
        <thead>
          <tr>
            <ThFixed>No.</ThFixed>
            <Th style={{ minWidth: 100 }} className="p-1">
              Item
            </Th>
            <Th style={{ width: 10 }} className="p-1">
              Qty.
            </Th>
            <Th style={{ width: 100 }} className="p-1">
              Satuan
            </Th>
            <Th style={{ width: 150 }} className="p-1">
              Harga
            </Th>
            <Th style={{ width: 150 }} className="p-1">
              Jumlah
            </Th>
            <Th style={{ width: 150 }} className="p-1">
              Saldo Petty Cash
            </Th>
            <Th style={{ width: 250 }} className="p-1">
              Keterangan
            </Th>
            {action !== 'CONFIRM' && <ThFixed rowSpan={2}>Aksi</ThFixed>}
          </tr>
        </thead>
        <tbody>
          <tr>
            <Td textRight colSpan={6} className="font-weight-bold">
              Saldo Petty Cash
            </Td>
            <Td textRight className="font-weight-bold">
              {rupiahConvert().getWithComa(String(saldo_petty_cash))}
            </Td>
            <Td>{values.keterangan}</Td>
            {action !== 'CONFIRM' && <Td></Td>}
          </tr>

          {data?.map((e, index) => {
            const jumlah = Math.round(e.qty ?? 0) * Math.round(e.harga_satuan);
            const item_saldo = saldo_petty_cash - jumlah;
            saldo_petty_cash = item_saldo;

            return (
              <tr key={index + e.nama_item}>
                <Td>{index + 1}</Td>
                <Td>{e.nama_item}</Td>
                <Td textRight>{e.qty}</Td>
                <Td>{e.nama_satuan}</Td>
                <Td textRight>
                  {rupiahConvert().getWithComa(String(e.harga_satuan))}
                </Td>
                <Td textRight>{rupiahConvert().getWithComa(String(jumlah))}</Td>
                <Td textRight>
                  {rupiahConvert().getWithComa(String(item_saldo))}
                </Td>
                <Td>{e.keterangan}</Td>
                {action !== 'CONFIRM' && action !== 'DETAIL' && (
                  <Td>
                    <ButtonGroup>
                      <ButtonUpdate
                        icon
                        noText
                        onClick={() =>
                          setModal({
                            show: true,
                            type: 'UPDATE',
                            data: { ...e, index },
                          })
                        }
                      />
                      <ButtonDelete
                        icon
                        noText
                        onClick={() => onRemoveItem(index)}
                      />
                    </ButtonGroup>
                  </Td>
                )}

                {action === 'DETAIL' && (
                  <Td>
                    <ButtonGroup>
                      <ButtonDetail
                        icon
                        noText
                        onClick={() =>
                          setModal({
                            show: true,
                            type: 'DETAIL',
                            data: { ...e, index },
                          })
                        }
                      />
                    </ButtonGroup>
                  </Td>
                )}
              </tr>
            );
          })}

          <tr>
            <Td colSpan={4} className="font-weight-bold text-right">
              Jumlah
            </Td>
            <Td textRight className="font-weight-bold">
              {rupiahConvert().getWithComa(String(jumlah.harga_satuan))}
            </Td>
            <Td textRight className="font-weight-bold">
              {rupiahConvert().getWithComa(String(jumlah.jumlah))}
            </Td>
            <Td textRight className="font-weight-bold">
              {rupiahConvert().getWithComa(String(jumlah.sisa_petty_cash))}
            </Td>
            <Td colSpan={2}></Td>
          </tr>
        </tbody>
      </Table>
      <Formik
        enableReinitialize
        initialValues={initialValuesRincian}
        validationSchema={validationSchemaRincian}
        onSubmit={onSubmitRincian}
      >
        <ModalFormRincian
          show={modal.show}
          type={modal.type}
          onHide={() => setModal({ show: false, type: 'CREATE', data: {} })}
        />
      </Formik>
    </Fragment>
  );
};
