export const calcJumlah = ({ data = [], petty_cash = 0 }) =>
  data?.reduce(
    (result, { qty, harga_satuan }) => {
      const itemJumlah = Math.round(qty ?? 0) * parseFloat(harga_satuan ?? 0);

      return {
        harga_satuan:
          parseFloat(result.harga_satuan) + parseFloat(harga_satuan),
        jumlah: result.jumlah + itemJumlah,
        sisa_petty_cash: result.sisa_petty_cash - itemJumlah,
      };
    },
    {
      harga_satuan: 0,
      jumlah: 0,
      sisa_petty_cash: petty_cash,
    }
  );
