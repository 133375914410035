import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Modal } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus,
  Input,
  DatePicker,
  TextArea,
} from "../../../components";
import { DateConvert, DecimalConvert, RupiahConvert } from "../../../utilities";
import { PermintaanAsetApi } from "../../../api";
import { FormPermintaanAsetContent } from "./PermintaanAsetComps";
import { formInitialValues } from "./PermintaanAsetUtils";

const DetailPermintaanAset = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();
  const no_permintaan_aset = state?.no_permintaan_aset;
  const tab = state?.tab ?? "";

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataDeliveryOrder, setDataDeliveryOrder] = useState([]);
  const [approveStatus, setApproveStatus] = useState("V");
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PermintaanAsetApi.getSingle({ no_permintaan_aset: no_permintaan_aset })
      .then(({ data }) => {
        const behavior = data.data.behavior ? data.data.behavior : "V";
        setDataDeliveryOrder(data.data);
        setApproveStatus(behavior.toUpperCase());
      })
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const whenNoSuratJalan = () => {
    setIsPageLoading(false);
    setIsFetchingFailed(true);
  };

  const formSubmitHandler = (values) => {
    const today = new Date().toISOString().slice(0, 10);

    const checkStatus = () => {
      if (modalConfig.type === "revise") {
        return "REV";
      }

      if (modalConfig.type === "approve") {
        return "APP";
      }

      if (modalConfig.type === "reject") {
        return "REJ";
      }
    };

    const finalValues = {
      no_transaksi: no_permintaan_aset,
      status_approval: checkStatus(),
      catatan: values.catatan,
      tgl_approval: today,
      approval_baseline: dataDeliveryOrder.detail.baseline,
    };

    PermintaanAsetApi.approve(finalValues)
      .then(() =>
        history.push("/human-resource/approval/permintaan-aset", {
          alert: {
            show: true,
            variant: "primary",
            text: `Approval berhasil disimpan!`,
          },
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Approval gagal disimpan!",
        })
      )
      .finally(() => {
        setModalConfig({
          ...modalConfig,
          show: false,
        });
        getInitialData();
      });
  };

  useEffect(() => {
    setNavbarTitle("Approval Permintaan Aset");
    getInitialData();
    // no_permintaan_aset ? getInitialData() : whenNoSuratJalan();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  const InfoSection = () => {
    const InfoItem = ({ title, value, isPaddingTop = false }) => (
      <tr>
        <td
          className="align-top"
          style={isPaddingTop ? { paddingTop: "10px" } : {}}
        >
          {title}
        </td>
        <td className="pl-4 pr-2 align-top">:</td>
        <td className="align-top">{value}</td>
      </tr>
    );

    return <Row></Row>;
  };

  const TableDOSection = () => {
    const dataTableDO = dataDeliveryOrder?.detail?.detail
      ? dataDeliveryOrder.detail.detail
      : [];

    const Th = (props) => (
      <th
        {...props}
        className={`${props.className} align-middle`}
        style={{
          ...props.style,
          fontSize: "14px",
        }}
      >
        {props.children}
      </th>
    );

    const Td = (props) => (
      <td
        {...props}
        className={props.className}
        style={{
          ...props.style,
          fontSize: "14px",
        }}
      >
        {props.children}
      </td>
    );

    return (
      <>
        <Table bordered hovered size="sm">
          <thead className="bg-light text-center">
            <tr>
              <Th className="text-center" style={{ width: 30 }}>
                No
              </Th>
              <Th className="text-center" style={{ width: 100 }}>
                Kode Barang
              </Th>
              <Th className="text-center">Item Barang</Th>
              <Th className="text-center">Qty. DO</Th>
              <Th className="text-center">Qty. Terkirim</Th>
              <Th className="text-center">Qty. Sisa DO</Th>
              <Th className="text-center">Qty. Permintaan Aset</Th>
              <Th className="text-center">Satuan Jual</Th>
              <Th className="text-center">Qty. Satuan Pakai</Th>
              <Th className="text-center">Satuan Pakai</Th>
            </tr>
          </thead>
          <tbody>
            {dataTableDO.length > 0 ? (
              dataTableDO.map((val, index) => (
                <tr key={index}>
                  <Td className="text-center">{index + 1}</Td>
                  <Td className="px-1">{val.kode_barang}</Td>
                  <Td className="px-1">{val.nama_barang}</Td>
                  <Td className="px-1 text-right">
                    {DecimalConvert(val.qty_do ?? 0).getWithComa}
                  </Td>
                  <Td className="px-1 text-right">
                    {DecimalConvert(val.qty_terkirim ?? 0).getWithComa}
                  </Td>
                  <Td className="px-1 text-right">
                    {
                      DecimalConvert(val.qty_do ?? 0 - val.qty_terkirim ?? 0)
                        .getWithComa
                    }
                  </Td>
                  <Td className="px-1 text-right">
                    {DecimalConvert(val.qty_sj ?? 0).getWithComa}
                  </Td>
                  <Td className="px-1">{val.satuan_jual}</Td>
                  <Td className="px-1 text-right">
                    {DecimalConvert(val.qty_satuan_pakai ?? 0).getWithComa}
                  </Td>
                  <Td className="px-1">{val.nama_satuan}</Td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className="bg-light text-center py-5">
                  <b>Tidak ada data</b>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </>
    );
  };

  const ModalSection = ({ formik }) => {
    const { values, isSubmitting, handleSubmit } = formik;

    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <h6>
            <b>
              {modalConfig.title === "approve"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.title}{" "}
              dengan catatan :
            </b>
          </h6>
          <span>{values.catatan}</span>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "approve"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.type
            }
            variant={
              modalConfig.type === "approve"
                ? "success"
                : modalConfig.type === "revise"
                ? "warning"
                : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  const FormCard = ({ formik }) => {
    const { values, errors, touched, dirty, validateForm, handleChange } =
      formik;
    const dataPengaju = dataDeliveryOrder?.approval
      ? dataDeliveryOrder.approval
      : [];

    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Approval Detail Permintaan Aset</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {dataPengaju.map((val, index) => (
              <Col sm>
                <InfoItem
                  title1={
                    val.status_approval == "VER"
                      ? `Pemeriksa ${val.approval_level}`
                      : "Pengesah"
                  }
                  value1={val.nama_karyawan ?? "-"}
                  title2="Catatan"
                  value2={val.catatan ?? "-"}
                />
              </Col>
            ))}
          </Row>
          {dataPengaju.length > 1 && <hr />}
          {tab !== "history" && (
            <div>
              <TextArea
                label="Catatan"
                name="catatan"
                placeholder="Masukan catatan"
                value={values.catatan}
                onChange={handleChange}
                error={errors.catatan && true}
                errorText={errors.catatan}
              />
              <div className="d-flex justify-content-end mt-3">
                {approveStatus === "A" ? (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "reject",
                            title: (
                              <span className="text-danger">
                                Reject Detail Permintaan Aset
                              </span>
                            ),
                          });
                        }

                        validateForm();
                      }}
                    />
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "approve",
                            title: (
                              <span className="text-success">
                                {approveStatus === "A" ? "Approve" : "Verify"}{" "}
                                Detail Permintaan Aset
                              </span>
                            ),
                          });
                        }

                        validateForm();
                      }}
                    />
                  </>
                ) : (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "reject",
                            title: (
                              <span className="text-danger">
                                Reject Detail Permintaan Aset
                              </span>
                            ),
                          });
                        }

                        validateForm();
                      }}
                    />
                    <ActionButton
                      text="Revise"
                      variant="warning"
                      className="m-1 text-white"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "revise",
                            title: (
                              <span className="text-warning">
                                Revise Detail Permintaan Aset
                              </span>
                            ),
                          });
                        }

                        validateForm();
                      }}
                    />
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "approve",
                            title: (
                              <span className="text-success">
                                {approveStatus === "A" ? "Approve" : "Verify"}{" "}
                                Detail Permintaan Aset
                              </span>
                            ),
                          });
                        }

                        validateForm();
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <b>Detail Data Permintaan Aset</b>
        <BackButton onClick={() => history.goBack()} />
      </div>

      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : isFetchingFailed ? (
        <DataStatus text="Data gagal dimuat!" />
      ) : (
        <>
          <Formik initialValues={formInitialValues(dataDeliveryOrder?.detail)}>
            <FormPermintaanAsetContent action="detail" />
          </Formik>
        </>
      )}

      {!isFetchingFailed && !isPageLoading && (
        <Formik
          initialValues={{
            catatan: "",
          }}
          validationSchema={Yup.object().shape({
            catatan: Yup.string().required("Masukan catatan"),
          })}
          onSubmit={formSubmitHandler}
        >
          {(formik) => (
            <>
              <FormCard formik={formik} />
              <ModalSection formik={formik} />
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default DetailPermintaanAset;
