import Services from "services";

class ProfilApi {
  getPage(params) {
    return Services.get("/hrdu/profile/page/", { params });
  }

  getOne(id) {
    return Services.get("/hrdu/profile?id_karyawan=" + id);
  }

  getJenjangPendidikan() {
    return Services.get("/hrdu/jenjang_pendidikan/dropdown");
  }

  getBank() {
    return Services.get("/hrdu/bank/dropdown");
  }

  getKeluarga(id) {
    return Services.get("/hrdu/profile/data_keluarga?id_karyawan=" + id);
  }

  getProvinsi() {
    return Services.get("/hrdu/profile/dropdown_provinsi");
  }

  getKabupaten(id) {
    return Services.get("/hrdu/profile/dropdown_kabupaten?id_provinsi=" + id);
  }

  getKecamatan(id) {
    return Services.get("/hrdu/profile/dropdown_kecamatan?id_kabupaten=" + id);
  }

  getDesa(id) {
    return Services.get("/hrdu/profile/dropdown_desa?id_kecamatan=" + id);
  }

  getBank() {
    return Services.get("/hrdu/profile/dropdown_bank");
  }

  getJenjangPendidikan() {
    return Services.get("/hrdu/profile/dropdown_jenjang_pendidikan");
  }

  search(key) {
    return Services.get("/hrdu/profile/page/?per_page=10&page=1&q=" + key);
  }

  resetPassword(value) {
    return Services.put("/hrdu/profile/reset_password", value);
  }

  checkUsername(values) {
    return Services.post("/hrdu/profile/cek_username", { username: values });
  }

  checkKtp(values) {
    return Services.post("/hrdu/profile/cek_ktp", { no_ktp: values });
  }

  checkKtp2(values) {
    return Services.post("/hrdu/profile/cek_ktp", values);
  }

  createDataPribadi(values) {
    return Services.post("/hrdu/profile", values);
  }

  createDataKepegawaian(values) {
    return Services.post("/hrdu/profile/data_kepegawaian", values);
  }

  updateDataPribadi(values) {
    return Services.put("/hrdu/profile", values);
  }

  updateDataKontak(values) {
    return Services.post("/hrdu/profile/kontak", values);
  }

  updateDataAlamat(values) {
    return Services.put("/hrdu/profile/alamat", values);
  }

  updateDataKepegawaian(values) {
    return Services.put("/hrdu/profile/data_kepegawaian", values);
  }

  updateDataBank(values) {
    return Services.put("/hrdu/profile/bank", values);
  }

  updateDataKeluarga(values) {
    return Services.post("/hrdu/profile/keluarga", values);
  }

  updateDataPendidikan(values) {
    return Services.put("/hrdu/profile/data_pendidikan", values);
  }

  updateKeluarga(values) {
    return Services.put("/hrdu/profile/keluarga", { ...values });
  }

  deleteKeluarga(values) {
    return Services.post("/hrdu/profile/keluarga_delete", values);
  }

  delete(value) {
    return Services.post("/hrdu/profile/delete", value);
  }

  checkNPWP(values) {
    return Services.post("/hrdu/profile/cek_no_npwp", { no_npwp: values });
  }

  getUnitProduksi() {
    return Services.get("/hrdu/profile/unit_produksi");
  }

  checkNPWP2(values) {
    return Services.post("/hrdu/profile/cek_no_npwp", values);
  }

  getJenis() {
    return Services.get("/hrdu/profile/jenis_pegawai");
  }

  getStatus(value) {
    return Services.get(
      "/hrdu/profile/status_pegawai?id_jenis_pekerja=" + value
    );
  }

  getStatusDetail(value) {
    return Services.get(
      "/hrdu/profile/status_pegawai_detail?id_status_pekerja=" + value
    );
  }

  getNo(id, status) {
    return Services.get(
      `/hrdu/profile/no_baru?id_karyawan=${id}&id_status_pekerja=${status}`
    );
  }

  getLokasi() {
    return Services.get(`/hrdu/lokasi_kerja/dropdown`);
  }

  updateUser(values) {
    return Services.put("/hrdu/profile/username", values);
  }

  show(values) {
    return Services.put("/hrdu/profile/show", values);
  }

  hide(values) {
    return Services.put("/hrdu/profile/hide", values);
  }

  async getDropdownUnitOrganisasi() {
    const fetch = await Services.get("/hrdu/unit_organisasi");
    return fetch?.data?.data?.map((val) => ({
      label: val.nama_unit_organisasi,
      value: val.id_unit_organisasi,
    }));
  }
  async getDropdownJabatan() {
    const fetch = await Services.get("/hrdu/jabatan");
    return fetch?.data?.data?.map((val) => ({
      label: val.nama_jabatan,
      value: val.id_jabatan,
    }));
  }
}

export default new ProfilApi();
