export const formInitialValues = (data = {}, action = "CREATE") => {
  const isCreate = action === "CREATE";
  const TODAY = new Date();

  return Object({
    id_pengajuan_petty_cash: isCreate
      ? undefined
      : data?.id_pengajuan_petty_cash,
    tgl_pengajuan_petty_cash: isCreate ? TODAY : data?.tgl_pengajuan_petty_cash,
    no_pengajuan_petty_cash: isCreate ? "" : data?.no_pengajuan_petty_cash,
    id_penerima_petty_cash: data?.id_penerima_petty_cash ?? "",
    keperluan: isCreate ? "" : data?.keperluan,
    id_unit_produksi: data?.id_unit_produksi ?? "",
    nama_unit_produksi: data?.nama_unit_produksi ?? "",
    nominal_pengajuan: isCreate ? "0" : data?.nominal_pengajuan,
    limit_petty_cash: data?.limit_petty_cash ?? "0",
    akumulasi_diajukan: data?.akumulasi_diajukan ?? "0",
    akumulasi_penerimaan: data?.akumulasi_penerimaan ?? "0",
    maksimal_pengajuan: data?.maksimal_pengajuan ?? "0",
    nama_proyek: data?.nama_proyek ?? "",
    kode_modul: data?.kode_modul ?? "",
    status_approval: "PEN",
    belum_disetujui: data?.belum_disetujui,
    history: data?.history,
  });
};
