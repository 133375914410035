import {
  IoCheckboxOutline,
  IoSpeedometerOutline,
  IoPersonCircleOutline,
  IoCashOutline,
} from "react-icons/io5";
import Logo from "../assets/image/LogoAJB.png";
import { Profil, UbahProfile } from "../pages/Profil";
import {
  PurchaseRequest,
  MainApprovalRAP,
  DetailRAP,
  MainApprovalSPK,
  DetailApprovalSPK,
  MainApprovalDeliveryOrder,
  DetailApprovalDeliveryOrder,
  MainApprovalFakturPenjualan,
  DetailFakturPenjualan,
  MainApprovalKegiatan,
  DetailKegiatan,
  MainApprovalMutasiAntarGudang,
  DetailMutasiAntarGudang,
  MainApprovalPenerimaanBarang,
  DetailPenerimaanBarang,
  MainApprovalPenerimaanFaktur,
  DetailApprovalPenerimaanFaktur,
  MainApprovalPPA,
  DetailPPA,
  MainApprovalPurchaseOrder,
  DetailApprovalPurchaseOrder,
  MainApprovalPurchaseRequest,
  DetailPurchaseRequest,
  MainApprovalSalesOrder,
  DetailSalesOrder,
  MainApprovalSeleksiVendor,
  DetailApprovalSeleksiVendor,
  MainApprovalSuratJalan,
  DetailSuratJalan,
  MainApprovalPenawaran,
  DetailPenawaran,
  MainApprovalRAB,
  DetailRAB,
  MainApprovalRAE,
  DetailRAE,
  MainApprovalPeluang,
  DetailPeluang,
  MainApprovalSOSPK,
  DetailApprovalSOSPK,
  MainApprovalJobMixDesign,
  DetailJobMixDesign,
  MainApprovalAnalisaBarangJadi,
  DetailAnalisaBarangJadi,
  MainApprovalInfoPeluang,
  DetailInfoPeluang,
  MainApprovalPengajuanPettyCash,
  DetailApprovalPengajuanPettyCash,
  MainApprovalPenerimaPettyCash,
  DetailApprovalPenerimaPettyCash,
  MainApprovalPenerimaanPettyCash,
  DetailApprovalPenerimaanPettyCash,
  MainApprovalRealisasiPettyCash,
  DetailApprovalRealisasiPettyCash,
  // SIA
  MainApprovalPengakuanHutang,
  DetailPengakuanHutang,
  MainApprovalJurnalUmum,
  DetailJurnalUmum,
  MainApprovalVoucherPembayaran,
  DetailVoucherPembayaran,
  MainApprovalPengakuanPiutang,
  DetailPengakuanPiutang,
  MainApprovalInvoice,
  DetailInvoice,
  MainApprovalPenyesuaianHutang,
  DetailPenyesuaianHutang,
  MainApprovalPenyesuaianPiutang,
  DetailPenyesuaianPiutang,
  MainApprovalVoucherGC,
  DetailVoucherGC,
  MainApprovalNonVoucherInvoice,
  DetailNonVoucherInvoice,
  MainApprovalInvoiceGC,
  DetailInvoiceGC,
  MainApprovalBayarDPVendor,
  DetailBayarDPVendor,
  MainApprovalPenerimaanDPCustomer,
  DetailPenerimaanDPCustomer,
  MainApprovalProgram,
  DetailProgram,
  MainApprovalPermintaanAset,
  DetailPermintaanAset,
  MainApprovalSeleksiVendorAset,
  DetailSeleksiVendorAset,
  MainApprovalPurchaseOrderAset,
  DetailApprovalPurchaseOrderAset,
  MainApprovalPenerimaanAset,
  DetailApprovalPenerimaanAset,
  MainApprovalInputFakturPenjualan,
  DetailInputFakturPenjualan,
  MainApprovalInputBiayaAngkut,
  DetailInputBiayaAngkut,
  MainApprovalInputPenerimaanBarang,
  DetailInputPenerimaanBarang,
} from "../pages/Approval";

import {
  PengajuanPettyCashList,

  // Penerima Petty Cash
  PenerimaanPettyCashList,
  PenerimaanMutasiPettyCashList,
  PenerimaanPettyCashTambah,
  PenerimaanPettyCashDetail,
  PenerimaanPettyCashUbah,

  // Realisasi Petty Cash
  RealisasiPettyCashList,
  PenerimaanRealisasiPettyCashList,
  RealisasiPettyCashTambah,
  RealisasiPettyCashUbah,
  RealisasiPettyCashDetail,

  // Pengembalian Sisa Petty Cash
  PengembalianSisaPettyCashList,
  RealisasiPengembalianSisaPettyCashList,
  PengembalianSisaPettyCashTambah,
  PengembalianSisaPettyCashDetail,
  PengajuanPettyCashCreate,
} from "pages/Transaksi";
import {
  DetailSubKegiatan,
  MainApprovalSubKegiatan,
} from "pages/Approval/ApprovalSubKegiatan";

const generateSpecialHAK = (prefix) => {
  const moduleAlias = [
    "PRY",
    "ASM",
    "TNK",
    "SIA",
    "ANG",
    "CRM",
    "PRO",
    "PRD",
    "SLS",
    "COC",
    "INV",
    "MKT",
  ];

  if (prefix) {
    return moduleAlias.map((modul) => `${prefix}${modul}`);
  }

  return [];
};

export default {
  LOGO: Logo,
  MODUL: "HRD User",

  /* 
    MENU REQUIREMENTS
      > TEXT  = REQUIRED,
      > LINK  = REQUIRED,
      > EXACT = OPTIONAL (TRUE/FALSE),
      > TYPE  = REQUIRED (MENU/DROPDOWN)
      > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
      > LOGO  = OPTIONAL 
  */

  MENU: [
    {
      text: "Dashboard",
      link: "/",
      type: "menu",
      exact: true,
      icon: <IoSpeedometerOutline />,
      hak: [
        "HRDU",
        "ASM",
        "MKT",
        "CRM",
        "INV",
        "ANG",
        "HRDA",
        "HRDU_APR_KEG",
        "HRDU_APR_PR",
        "HRDU_APR_REGRAB",
        "HRDU_APR_REGPNW",
        "HRDU_APR_PVSPR",
        "HRDU_APR_REGPRO",
        "HRDU_APR_PENPO",
        "HRDU_APR_REGFPNJ",
        "HRDU_APR_PENFPNJ",
        "HRDU_APR_REGDO",
        "HRDU_APR_REGPO",
        "HRDU_APR_REGRAE",
        "HRDU_APR_REGRAP",
        "HRDU_APR_REGSJ",
        "HRDU_APR_REGSO",
        "HRDU_APR_REGSPK",
        "HRDU_APR_RK",
        "HRDU_APR_PPA",
        "HRDU_APR_MAG",
        "HRDU_APR_REGPLG",
        "HRDU_APR_REGSSPK",
        "HRDU_APR_REGJMD",
        "HRDU_APR_REGABJ",
        // SIA
        "HRDU_APR_REGGL",
        "HRDU_APR_REGAPPH",
        "HRDU_APR_REGAPPVC",
        "HRDU_APR_REGAPPSH",
        "HRDU_APR_REGARPP",
        "HRDU_APR_REGARPIV",
        "HRDU_APR_REGARPNP",
        "HRDU_APR_REGGCPLKB",
        "HRDU_APR_REGGCPNKB",
        "HRDU_APR_REGGCNON",
        "HRDU_APR_REGGCBDPV",
        "HRDU_APR_REGGCPDPK",
      ]
        .concat([
          generateSpecialHAK("HRDU_APR_REGPC"),
          generateSpecialHAK("HRDU_APR_REGPNPC"),
          generateSpecialHAK("HRDU_APR_REGRPC"),
        ])
        .flat(),
    },
    {
      text: "Approval",
      type: "dropdown",
      icon: <IoCheckboxOutline />,
      hak: [
        "HRDU",
        "ASM",
        "MKT",
        "CRM",
        "INV",
        "ANG",
        "HRDA",
        "HRDU_APR_KEG",
        "HRDU_APR_PR",
        "HRDU_APR_REGRAB",
        "HRDU_APR_REGPNW",
        "HRDU_APR_PVSPR",
        "HRDU_APR_REGPRO",
        "HRDU_APR_PENPO",
        "HRDU_APR_REGFPNJ",
        "HRDU_APR_PENFPNJ",
        "HRDU_APR_REGDO",
        "HRDU_APR_REGPO",
        "HRDU_APR_REGRAE",
        "HRDU_APR_REGRAP",
        "HRDU_APR_REGSJ",
        "HRDU_APR_REGSO",
        "HRDU_APR_REGSPK",
        "HRDU_APR_RK",
        "HRDU_APR_PPA",
        "HRDU_APR_MAG",
        "HRDU_APR_REGPLG",
        "HRDU_APR_REGSSPK",
        "HRDU_APR_REGJMD",
        "HRDU_APR_REGABJ",
        // SIA
        "HRDU_APR_REGGL",
        "HRDU_APR_REGAPPH",
        "HRDU_APR_REGAPPVC",
        "HRDU_APR_REGAPPSH",
        "HRDU_APR_REGARPP",
        "HRDU_APR_REGARPIV",
        "HRDU_APR_REGARPNP",
        "HRDU_APR_REGGCPLKB",
        "HRDU_APR_REGGCPNKB",
        "HRDU_APR_REGGCNON",
        "HRDU_APR_REGGCBDPV",
        "HRDU_APR_REGGCPDPK",
      ],
      menu: [
        {
          text: "Program",
          link: "/human-resource/approval/program",
          hak: ["HRDU", "HRDU_APR_PRG"],
        },
        {
          text: "Kegiatan",
          link: "/human-resource/approval/kegiatan",
          hak: ["HRDU", "HRDU_APR_KEG", "HRDU_APR_RK"],
        },
        {
          text: "Sub Kegiatan",
          link: "/human-resource/approval/sub-kegiatan",
          hak: ["HRDU", "HRDU_APR_RSK"],
        },
        {
          text: "PPA",
          link: "/human-resource/approval/ppa",
          hak: ["HRDU", "HRDU_APR_PPA"],
        },
        {
          text: "Info Peluang",
          link: "/human-resource/approval/info-peluang",
          hak: ["HRDU", "HRDU_APR_REGIPLG"],
        },
        {
          text: "Peluang",
          link: "/human-resource/approval/peluang",
          hak: ["HRDU", "HRDU_APR_REGPLG"],
        },
        // {
        //   text: "Unit Cost",
        //   link: "/human-resource/approval/unit-cost",
        //   hak: ["HRDU", "HRDU_APR_REGRAE"],
        // },
        {
          text: "RAB",
          link: "/human-resource/approval/rab",
          hak: ["HRDU", "HRDU_APR_REGRAB"],
        },
        {
          text: "RAP",
          link: "/human-resource/approval/rap",
          hak: ["HRDU", "HRDU_APR_REGRAP"],
        },
        {
          text: "Penawaran",
          link: "/human-resource/approval/penawaran",
          hak: ["HRDU", "HRDU_APR_REGPNW"],
        },
        {
          text: "Purchase Order",
          link: "/human-resource/approval/purchase-order",
          hak: ["HRDU", "HRDU_APR_REGPO"],
        },
        {
          text: "Purchase Request (PR)",
          link: "/human-resource/approval/purchase-request",
          hak: ["HRDU", "HRDU_APR_PR"],
        },
        {
          text: "Penerimaan Barang",
          link: "/human-resource/approval/penerimaan-barang",
          hak: ["HRDU", "HRDU_APR_PENPO"],
        },
        {
          text: "Seleksi Vendor",
          link: "/human-resource/approval/seleksi-vendor",
          hak: ["HRDU", "HRDU_APR_PVSPR"],
        },
        {
          text: "Sales Order",
          link: "/human-resource/approval/sales-order",
          hak: ["HRDU", "HRDU_APR_REGSO"],
        },
        {
          text: "Surat Perjanjian Kerja",
          link: "/human-resource/approval/surat-perjanjian-kerja",
          hak: ["HRDU", "HRDU_APR_REGSPK"],
        },
        {
          text: "Faktur Penjualan",
          link: "/human-resource/approval/faktur-penjualan",
          hak: ["HRDU", "HRDU_APR_REGFPNJ"],
        },
        // {
        //   text: "Penerimaan Faktur",
        //   link: "/human-resource/approval/penerimaan-faktur",
        //   hak: ["HRDU", "HRDU_APR_PENFPNJ"],
        // },
        {
          text: "Delivery Order",
          link: "/human-resource/approval/delivery-order",
          hak: ["HRDU", "HRDU_APR_REGDO"],
        },
        {
          text: "Surat Jalan",
          link: "/human-resource/approval/surat-jalan",
          hak: ["HRDU", "HRDU_APR_REGSJ"],
        },
        {
          text: "Mutasi Antar Gudang",
          link: "/human-resource/approval/mutasi-antar-gudang",
          hak: ["HRDU", "HRDU_APR_MAG"],
        },
        {
          text: "Sales Order SPK",
          link: "/human-resource/approval/sales-order-spk",
          hak: ["HRDU", "HRDU_APR_REGSSPK"],
        },
        {
          text: "Job Mix Design",
          link: "/human-resource/approval/job-mix-design",
          hak: ["HRDU", "HRDU_APR_REGJMD"],
        },
        {
          text: "Analisa Barang Jadi",
          link: "/human-resource/approval/analisa-barang-jadi",
          hak: ["HRDU", "HRDU_APR_REGABJ"],
        },
        {
          text: "Jurnal Umum",
          link: "/human-resource/approval/jurnal-umum",
          hak: ["SUPA", "HRDU_APR_REGGL"],
        },
        {
          text: "Pengakuan Hutang",
          link: "/human-resource/approval/pengakuan-hutang",
          hak: ["SUPA", "HRDU_APR_REGAPPH"],
        },

        {
          text: "Voucher",
          link: "/human-resource/approval/voucher",
          hak: ["SUPA", "HRDU_APR_REGAPPVC"],
        },
        {
          text: "Penyesuaian Voucher",
          link: "/human-resource/approval/penyesuaian-hutang",
          hak: ["SUPA", "HRDU_APR_REGAPPSH"],
        },
        {
          text: "Pengakuan Piutang",
          link: "/human-resource/approval/pengakuan-piutang",
          hak: ["SUPA", "HRDU_APR_REGARPP"],
        },
        {
          text: "Invoice",
          link: "/human-resource/approval/invoice",
          hak: ["SUPA", "HRDU_APR_REGARPIV"],
        },
        {
          text: "Penyesuaian Invoice",
          link: "/human-resource/approval/penyesuaian-piutang",
          hak: ["SUPA", "HRDU_APR_REGARPNP"],
        },
        {
          text: "Pembayaran Voucher",
          link: "/human-resource/approval/pembayaran-voucher",
          hak: ["SUPA", "HRDU_APR_REGGCPLKB"],
        },
        {
          text: "Pembayaran Invoice",
          link: "/human-resource/approval/pembayaran-invoice",
          hak: ["SUPA", "HRDU_APR_REGGCPNKB"],
        },
        {
          text: "Non Voucher Invoice",
          link: "/human-resource/approval/non-voucher-invoice",
          hak: ["SUPA", "HRDU_APR_REGGCNON"],
        },
        {
          text: "Bayar DP Vendor",
          link: "/human-resource/approval/bayar-dp-vendor",
          hak: ["SUPA", "HRDU_APR_REGGCBDPV"],
        },
        {
          text: "Penerimaan DP Customer",
          link: "/human-resource/approval/penerimaan-dp-customer",
          hak: ["SUPA", "HRDU_APR_REGGCPDPK"],
        },
        {
          text: "Pengajuan Petty Cash",
          link: "/human-resource/approval/pengajuan-petty-cash",
          hak: ["SUPA"].concat(generateSpecialHAK("HRDU_APR_REGPC")),
        },
        {
          text: "Penerima Petty Cash",
          link: "/human-resource/approval/penerima-petty-cash",
          hak: ["SUPA", "HRDU_APR_REGPEC"],
        },
        {
          text: "Penerimaan Petty Cash",
          link: "/human-resource/approval/penerimaan-petty-cash",
          hak: ["SUPA"].concat(generateSpecialHAK("HRDU_APR_REGPNPC")),
        },

        {
          text: "Permintaan Aset",
          link: "/human-resource/approval/permintaan-aset",
          hak: ["SUPA", "HRDU_APR_RPA"],
        },

        {
          text: "Seleksi Vendor Aset",
          link: "/human-resource/approval/seleksi-vendor-aset",
          hak: ["SUPA", "HRDU_APR_RSVA"],
        },

        {
          text: "Purchase Order Aset",
          link: "/human-resource/approval/purchase-order-aset",
          hak: ["HRDU", "HRDU_APR_RPOA"],
        },

        {
          text: "Penerimaan Aset",
          link: "/human-resource/approval/penerimaan-aset",
          hak: ["SUPA", "HRDU_APR_RPAS"],
        },

        {
          text: "Input Biaya Angkut",
          link: "/human-resource/approval/input-biaya-angkut",
          hak: ["SUPA", "HRDU_APR_REGAPPHBA"],
        },

        {
          text: "Input Faktur Penjualan",
          link: "/human-resource/approval/input-faktur-penjualan",
          hak: ["SUPA", "HRDU_APR_REGAPPFP"],
        },

        {
          text: "Input Penerimaan Barang",
          link: "/human-resource/approval/input-penerimaan-barang",
          hak: ["SUPA", "HRDU_APR_REGAPPHP"],
        },

        // {
        //   text: "Realisasi Petty Cash",
        //   link: "/human-resource/approval/realisasi-petty-cash",
        //   hak: ["SUPA"].concat(generateSpecialHAK("HRDU_APR_REGRPC")),
        // },
      ],
    },
    {
      text: "Transaksi",
      type: "dropdown",
      icon: <IoCashOutline />,
      hak: ["SUPA", "HRDU"],
      menu: [
        {
          text: "Pengajuan Petty Cash",
          link: "/human-resource/transaksi/pengajuan-petty-cash",
          hak: ["SUPA", "HRDU_TRN_PJC"],
        },
        {
          text: "Penerimaan Petty Cash",
          link: "/human-resource/transaksi/penerimaan-petty-cash",
          hak: ["SUPA", "HRDU_TRN_PNP"],
        },
        {
          text: "Realisasi Petty Cash",
          link: "/human-resource/transaksi/realisasi-petty-cash",
          hak: ["SUPA", "HRDU_TRN_RPC"],
        },
        {
          text: "Pengembalian Sisa Petty Cash",
          link: "/human-resource/transaksi/pengembalian-sisa-petty-cash",
          hak: ["SUPA", "HRDU_TRN_PSPC"],
        },
      ],
    },
    {
      text: "Profil",
      type: "dropdown",
      icon: <IoPersonCircleOutline />,
      hak: [
        "HRDU",
        "ASM",
        "MKT",
        "CRM",
        "INV",
        "ANG",
        "HRDA",
        "HRDU_APR_KEG",
        "HRDU_APR_PR",
        "HRDU_APR_REGRAB",
        "HRDU_APR_REGPNW",
        "HRDU_APR_PVSPR",
        "HRDU_APR_REGPRO",
        "HRDU_APR_PENPO",
        "HRDU_APR_REGFPNJ",
        "HRDU_APR_PENFPNJ",
        "HRDU_APR_REGDO",
        "HRDU_APR_REGPO",
        "HRDU_APR_REGRAE",
        "HRDU_APR_REGRAP",
        "HRDU_APR_REGSJ",
        "HRDU_APR_REGSO",
        "HRDU_APR_REGSPK",
        "HRDU_APR_RK",
        "HRDU_APR_PPA",
        "HRDU_APR_MAG",
        "HRDU_APR_REGPLG",
        "HRDU_APR_REGSSPK",
        "HRDU_APR_REGJMD",
        "HRDU_APR_REGABJ",
        // SIA
        "HRDU_APR_REGGL",
        "HRDU_APR_REGAPPH",
        "HRDU_APR_REGAPPVC",
        "HRDU_APR_REGAPPSH",
        "HRDU_APR_REGARPP",
        "HRDU_APR_REGARPIV",
        "HRDU_APR_REGARPNP",
        "HRDU_APR_REGGCPLKB",
        "HRDU_APR_REGGCPNKB",
        "HRDU_APR_REGGCNON",
        "HRDU_APR_REGGCBDPV",
        "HRDU_APR_REGGCPDPK",
      ],
      menu: [
        {
          text: "Akun Saya",
          link: "/profil",
          hak: [
            "HRDU",
            "ASM",
            "MKT",
            "CRM",
            "INV",
            "ANG",
            "HRDA",
            "HRDU_APR_KEG",
            "HRDU_APR_PR",
            "HRDU_APR_REGRAB",
            "HRDU_APR_REGPNW",
            "HRDU_APR_PVSPR",
            "HRDU_APR_REGPRO",
            "HRDU_APR_PENPO",
            "HRDU_APR_REGFPNJ",
            "HRDU_APR_PENFPNJ",
            "HRDU_APR_REGDO",
            "HRDU_APR_REGPO",
            "HRDU_APR_REGRAE",
            "HRDU_APR_REGRAP",
            "HRDU_APR_REGSJ",
            "HRDU_APR_REGSO",
            "HRDU_APR_REGSPK",
            "HRDU_APR_RK",
            "HRDU_APR_PPA",
            "HRDU_APR_MAG",
            "HRDU_APR_REGPLG",
            "HRDU_APR_REGSSPK",
            "HRDU_APR_REGJMD",
            "HRDU_APR_REGABJ",
            // SIA
            "HRDU_APR_REGGL",
            "HRDU_APR_REGAPPH",
            "HRDU_APR_REGAPPVC",
            "HRDU_APR_REGAPPSH",
            "HRDU_APR_REGARPP",
            "HRDU_APR_REGARPIV",
            "HRDU_APR_REGARPNP",
            "HRDU_APR_REGGCPLKB",
            "HRDU_APR_REGGCPNKB",
            "HRDU_APR_REGGCNON",
            "HRDU_APR_REGGCBDPV",
            "HRDU_APR_REGGCPDPK",
          ],
        },
        {
          text: "Ubah Akun",
          link: "/ubah-profil",
          hak: [
            "HRDU",
            "ASM",
            "MKT",
            "CRM",
            "INV",
            "ANG",
            "HRDA",
            "HRDU_APR_KEG",
            "HRDU_APR_PR",
            "HRDU_APR_REGRAB",
            "HRDU_APR_REGPNW",
            "HRDU_APR_PVSPR",
            "HRDU_APR_REGPRO",
            "HRDU_APR_PENPO",
            "HRDU_APR_REGFPNJ",
            "HRDU_APR_PENFPNJ",
            "HRDU_APR_REGDO",
            "HRDU_APR_REGPO",
            "HRDU_APR_REGRAE",
            "HRDU_APR_REGRAP",
            "HRDU_APR_REGSJ",
            "HRDU_APR_REGSO",
            "HRDU_APR_REGSPK",
            "HRDU_APR_RK",
            "HRDU_APR_PPA",
            "HRDU_APR_MAG",
            "HRDU_APR_REGPLG",
            "HRDU_APR_REGSSPK",
            "HRDU_APR_REGJMD",
            "HRDU_APR_REGABJ",
            // SIA
            "HRDU_APR_REGGL",
            "HRDU_APR_REGAPPH",
            "HRDU_APR_REGAPPVC",
            "HRDU_APR_REGAPPSH",
            "HRDU_APR_REGARPP",
            "HRDU_APR_REGARPIV",
            "HRDU_APR_REGARPNP",
            "HRDU_APR_REGGCPLKB",
            "HRDU_APR_REGGCPNKB",
            "HRDU_APR_REGGCNON",
            "HRDU_APR_REGGCBDPV",
            "HRDU_APR_REGGCPDPK",
          ],
        },
      ],
    },
  ],

  ROUTES: [
    {
      exact: true,
      route: "/profil",
      hak: [
        "HRDU",
        "ASM",
        "MKT",
        "CRM",
        "INV",
        "ANG",
        "HRDA",
        "HRDU_APR_KEG",
        "HRDU_APR_PR",
        "HRDU_APR_REGRAB",
        "HRDU_APR_REGPNW",
        "HRDU_APR_PVSPR",
        "HRDU_APR_REGPRO",
        "HRDU_APR_PENPO",
        "HRDU_APR_REGFPNJ",
        "HRDU_APR_PENFPNJ",
        "HRDU_APR_REGDO",
        "HRDU_APR_REGPO",
        "HRDU_APR_REGRAE",
        "HRDU_APR_REGRAP",
        "HRDU_APR_REGSJ",
        "HRDU_APR_REGSO",
        "HRDU_APR_REGSPK",
        "HRDU_APR_RK",
        "HRDU_APR_PPA",
        "HRDU_APR_MAG",
        "HRDU_APR_REGPLG",
        "HRDU_APR_REGSSPK",
        "HRDU_APR_REGJMD",
        "HRDU_APR_REGABJ",
      ],
      page: Profil,
    },
    {
      exact: true,
      route: "/ubah-profil",
      hak: [
        "HRDU",
        "ASM",
        "MKT",
        "CRM",
        "INV",
        "ANG",
        "HRDA",
        "HRDU_APR_KEG",
        "HRDU_APR_PR",
        "HRDU_APR_REGRAB",
        "HRDU_APR_REGPNW",
        "HRDU_APR_PVSPR",
        "HRDU_APR_REGPRO",
        "HRDU_APR_PENPO",
        "HRDU_APR_REGFPNJ",
        "HRDU_APR_PENFPNJ",
        "HRDU_APR_REGDO",
        "HRDU_APR_REGPO",
        "HRDU_APR_REGRAE",
        "HRDU_APR_REGRAP",
        "HRDU_APR_REGSJ",
        "HRDU_APR_REGSO",
        "HRDU_APR_REGSPK",
        "HRDU_APR_RK",
        "HRDU_APR_PPA",
        "HRDU_APR_MAG",
        "HRDU_APR_REGPLG",
        "HRDU_APR_REGSSPK",
        "HRDU_APR_REGJMD",
        "HRDU_APR_REGABJ",
      ],
      page: UbahProfile,
    },
    {
      exact: true,
      route: "/human-resource/approval/program",
      hak: ["HRDU", "HRDU_APR_PRG"],
      page: MainApprovalProgram,
    },
    {
      exact: true,
      route: "/human-resource/approval/program/detail/:id",
      hak: ["HRDU", "HRDU_APR_PRG"],
      page: DetailProgram,
    },
    {
      exact: true,
      route: "/human-resource/approval/kegiatan",
      hak: ["HRDU", "HRDU_APR_KEG", "HRDU_APR_RK"],
      page: MainApprovalKegiatan,
    },
    {
      exact: true,
      route: "/human-resource/approval/kegiatan/detail/:id",
      hak: ["HRDU", "HRDU_APR_KEG", "HRDU_APR_RK"],
      page: DetailKegiatan,
    },
    {
      exact: true,
      route: "/human-resource/approval/sub-kegiatan",
      hak: ["HRDU", "HRDU_APR_RSK"],
      page: MainApprovalSubKegiatan,
    },
    {
      exact: true,
      route: "/human-resource/approval/sub-kegiatan/detail/:id",
      hak: ["HRDU", "HRDU_APR_RSK"],
      page: DetailSubKegiatan,
    },
    {
      exact: true,
      route: "/human-resource/approval/ppa",
      hak: ["HRDU", "HRDU_APR_PPA"],
      page: MainApprovalPPA,
    },
    {
      exact: true,
      route: "/human-resource/approval/ppa/detail/:id",
      hak: ["HRDU", "HRDU_APR_PPA"],
      page: DetailPPA,
    },
    {
      exact: true,
      route: "/human-resource/approval/unit-cost",
      hak: ["HRDU", "HRDU_APR_REGRAE"],
      page: MainApprovalRAE,
    },
    {
      exact: true,
      route: "/human-resource/approval/unit-cost/detail/:id_rae",
      hak: ["HRDU", "HRDU_APR_REGRAE"],
      page: DetailRAE,
    },
    {
      exact: true,
      route: "/human-resource/approval/rab",
      hak: ["HRDU", "HRDU_APR_REGRAB"],
      page: MainApprovalRAB,
    },
    {
      exact: true,
      route: "/human-resource/approval/rab/detail/:id_rab",
      hak: ["HRDU", "HRDU_APR_REGRAB"],
      page: DetailRAB,
    },
    {
      exact: true,
      route: "/human-resource/approval/rap",
      hak: ["HRDU", "HRDU_APR_REGRAP"],
      page: MainApprovalRAP,
    },
    {
      exact: true,
      route: "/human-resource/approval/rap/detail",
      hak: ["HRDU", "HRDU_APR_REGRAP"],
      page: DetailRAP,
    },
    {
      exact: true,
      route: "/human-resource/approval/info-peluang",
      hak: ["HRDU", "HRDU_APR_REGIPLG"],
      page: MainApprovalInfoPeluang,
    },
    {
      exact: true,
      route: "/human-resource/approval/info-peluang/detail/:id_peluang",
      hak: ["HRDU", "HRDU_APR_REGIPLG"],
      page: DetailInfoPeluang,
    },
    {
      exact: true,
      route: "/human-resource/approval/peluang",
      hak: ["HRDU", "HRDU_APR_REGPLG"],
      page: MainApprovalPeluang,
    },
    {
      exact: true,
      route: "/human-resource/approval/peluang/detail/:id_peluang",
      hak: ["HRDU", "HRDU_APR_REGPLG"],
      page: DetailPeluang,
    },
    {
      exact: true,
      route: "/human-resource/approval/penawaran",
      hak: ["HRDU", "HRDU_APR_REGPNW"],
      page: MainApprovalPenawaran,
    },
    {
      exact: true,
      route: "/human-resource/approval/penawaran/detail/:id_penawaran",
      hak: ["HRDU", "HRDU_APR_REGPNW"],
      page: DetailPenawaran,
    },
    {
      exact: true,
      route: "/human-resource/approval/purchase-request",
      hak: ["HRDU", "HRDU_APR_PR"],
      page: MainApprovalPurchaseRequest,
    },
    {
      exact: true,
      route: "/human-resource/approval/purchase-request/detail/:id",
      hak: ["HRDU", "HRDU_APR_PR"],
      page: DetailPurchaseRequest,
    },
    {
      exact: true,
      route: "/human-resource/approval/penerimaan-barang",
      hak: ["HRDU", "HRDU_APR_PENPO"],
      page: MainApprovalPenerimaanBarang,
    },
    {
      exact: true,
      route: "/human-resource/approval/penerimaan-barang/detail/:id",
      hak: ["HRDU", "HRDU_APR_PENPO"],
      page: DetailPenerimaanBarang,
    },
    {
      exact: true,
      route: "/human-resource/approval/seleksi-vendor",
      hak: ["HRDU", "HRDU_APR_PVSPR"],
      page: MainApprovalSeleksiVendor,
    },
    {
      exact: true,
      route: "/human-resource/approval/seleksi-vendor/detail",
      hak: ["HRDU", "HRDU_APR_PVSPR"],
      page: DetailApprovalSeleksiVendor,
    },
    {
      exact: true,
      route: "/human-resource/approval/purchase-order",
      hak: ["HRDU", "HRDU_APR_REGPO"],
      page: MainApprovalPurchaseOrder,
    },
    {
      exact: true,
      route: "/human-resource/approval/purchase-order/detail",
      hak: ["HRDU", "HRDU_APR_REGPO"],
      page: DetailApprovalPurchaseOrder,
    },
    {
      exact: true,
      route: "/human-resource/approval/sales-order",
      hak: ["HRDU", "HRDU_APR_REGSO"],
      page: MainApprovalSalesOrder,
    },
    {
      exact: true,
      route: "/human-resource/approval/sales-order/detail/:id",
      hak: ["HRDU", "HRDU_APR_REGSO"],
      page: DetailSalesOrder,
    },
    {
      exact: true,
      route: "/human-resource/approval/surat-perjanjian-kerja",
      hak: ["HRDU", "HRDU_APR_REGSPK"],
      page: MainApprovalSPK,
    },
    {
      exact: true,
      route: "/human-resource/approval/surat-perjanjian-kerja/detail/:id_spk",
      hak: ["HRDU", "HRDU_APR_REGSPK"],
      page: DetailApprovalSPK,
    },
    {
      exact: true,
      route: "/human-resource/approval/faktur-penjualan",
      hak: ["HRDU", "HRDU_APR_REGFPNJ"],
      page: MainApprovalFakturPenjualan,
    },
    {
      exact: true,
      route: "/human-resource/approval/faktur-penjualan/detail/:id",
      hak: ["HRDU", "HRDU_APR_REGFPNJ"],
      page: DetailFakturPenjualan,
    },
    {
      exact: true,
      route: "/human-resource/approval/delivery-order",
      hak: ["HRDU", "HRDU_APR_REGDO"],
      page: MainApprovalDeliveryOrder,
    },
    {
      exact: true,
      route: "/human-resource/approval/delivery-order/detail",
      hak: ["HRDU", "HRDU_APR_REGDO"],
      page: DetailApprovalDeliveryOrder,
    },
    {
      exact: true,
      route: "/human-resource/approval/surat-jalan",
      hak: ["HRDU", "HRDU_APR_REGSJ"],
      page: MainApprovalSuratJalan,
    },
    {
      exact: true,
      route: "/human-resource/approval/surat-jalan/detail/:id",
      hak: ["HRDU", "HRDU_APR_REGSJ"],
      page: DetailSuratJalan,
    },
    // {
    //   exact: true,
    //   route: "/human-resource/approval/penerimaan-faktur",
    //   hak: ["HRDU", "HRDU_APR_PENFPNJ"],
    //   page: MainApprovalPenerimaanFaktur,
    // },
    // {
    //   exact: true,
    //   route: "/human-resource/approval/penerimaan-faktur/detail",
    //   hak: ["HRDU", "HRDU_APR_PENFPNJ"],
    //   page: DetailApprovalPenerimaanFaktur,
    // },
    {
      exact: true,
      route: "/human-resource/approval/mutasi-antar-gudang",
      hak: ["HRDU", "HRDU_APR_MAG"],
      page: MainApprovalMutasiAntarGudang,
    },
    {
      exact: true,
      route: "/human-resource/approval/mutasi-antar-gudang/detail/:id",
      hak: ["HRDU", "HRDU_APR_MAG"],
      page: DetailMutasiAntarGudang,
    },
    {
      exact: true,
      route: "/human-resource/approval/sales-order-spk",
      hak: ["HRDU", "HRDU_APR_REGSSPK"],
      page: MainApprovalSOSPK,
    },
    {
      exact: true,
      route:
        "/human-resource/approval/sales-order-spk/detail/:id_sales_order_spk",
      hak: ["HRDU", "HRDU_APR_REGSSPK"],
      page: DetailApprovalSOSPK,
    },
    {
      exact: true,
      route: "/human-resource/approval/job-mix-design",
      hak: ["HRDU", "HRDU_APR_REGJMD"],
      page: MainApprovalJobMixDesign,
    },
    {
      exact: true,
      route:
        "/human-resource/approval/job-mix-design/:tab(detail|history)/:id_jobmix_design",
      hak: ["HRDU", "HRDU_APR_REGJMD"],
      page: DetailJobMixDesign,
    },
    {
      exact: true,
      route: "/human-resource/approval/analisa-barang-jadi",
      hak: ["HRDU", "HRDU_APR_REGABJ"],
      page: MainApprovalAnalisaBarangJadi,
    },
    {
      exact: true,
      route:
        "/human-resource/approval/analisa-barang-jadi/:tab(detail|history)/:id_analisa_barang_jadi",
      hak: ["HRDU", "HRDU_APR_REGABJ"],
      page: DetailAnalisaBarangJadi,
    },

    // Approval Pengakuan Hutang
    {
      exact: true,
      route: "/human-resource/approval/pengakuan-hutang",
      hak: ["HRDU", "HRDU_APR_REGAPPH"],
      page: MainApprovalPengakuanHutang,
    },
    {
      exact: true,
      route: "/human-resource/approval/pengakuan-hutang/detail/:id",
      hak: ["HRDU", "HRDU_APR_REGAPPH"],
      page: DetailPengakuanHutang,
    },

    // Approval Jurnal Umum
    {
      exact: true,
      route: "/human-resource/approval/jurnal-umum",
      hak: ["HRDU", "HRDU_APR_REGGL"],
      page: MainApprovalJurnalUmum,
    },
    {
      exact: true,
      route: "/human-resource/approval/jurnal-umum/detail/:id",
      hak: ["HRDU", "HRDU_APR_REGGL"],
      page: DetailJurnalUmum,
    },

    // Approval Voucher Pembayaran
    {
      exact: true,
      route: "/human-resource/approval/voucher",
      hak: ["HRDU", "HRDU_APR_REGAPPVC"],
      page: MainApprovalVoucherPembayaran,
    },
    {
      exact: true,
      route: "/human-resource/approval/voucher/detail/:id",
      hak: ["HRDU", "HRDU_APR_REGAPPVC"],
      page: DetailVoucherPembayaran,
    },

    // Approval Penyesuaian Hutang
    {
      exact: true,
      route: "/human-resource/approval/penyesuaian-hutang",
      hak: ["HRDU", "HRDU_APR_REGAPPSH"],
      page: MainApprovalPenyesuaianHutang,
    },
    {
      exact: true,
      route: "/human-resource/approval/penyesuaian-hutang/detail/:id",
      hak: ["HRDU", "HRDU_APR_REGAPPSH"],
      page: DetailPenyesuaianHutang,
    },

    // Approval Pengakuan Piutang
    {
      exact: true,
      route: "/human-resource/approval/pengakuan-piutang",
      hak: ["HRDU", "HRDU_APR_REGARPP"],
      page: MainApprovalPengakuanPiutang,
    },
    {
      exact: true,
      route: "/human-resource/approval/pengakuan-piutang/detail/:id",
      hak: ["HRDU", "HRDU_APR_REGARPP"],
      page: DetailPengakuanPiutang,
    },

    // Approval Invoice
    {
      exact: true,
      route: "/human-resource/approval/invoice",
      hak: ["HRDU", "HRDU_APR_REGARPIV"],
      page: MainApprovalInvoice,
    },
    {
      exact: true,
      route: "/human-resource/approval/invoice/detail/:id",
      hak: ["HRDU", "HRDU_APR_REGARPIV"],
      page: DetailInvoice,
    },

    // Approval Penyesuaian Piutang
    {
      exact: true,
      route: "/human-resource/approval/penyesuaian-piutang",
      hak: ["HRDU", "HRDU_APR_REGARPNP"],
      page: MainApprovalPenyesuaianPiutang,
    },
    {
      exact: true,
      route: "/human-resource/approval/penyesuaian-piutang/detail/:id",
      hak: ["HRDU", "HRDU_APR_REGARPNP"],
      page: DetailPenyesuaianPiutang,
    },
    // Approval Pembayaran Voucher
    {
      exact: true,
      route: "/human-resource/approval/pembayaran-voucher",
      hak: ["HRDU", "HRDU_APR_REGGCPLKB"],
      page: MainApprovalVoucherGC,
    },
    {
      exact: true,
      route: "/human-resource/approval/pembayaran-voucher/detail/:id",
      hak: ["HRDU", "HRDU_APR_REGGCPLKB"],
      page: DetailVoucherGC,
    },

    // Approval Pembayaran Invoice
    {
      exact: true,
      route: "/human-resource/approval/pembayaran-invoice",
      hak: ["HRDU", "HRDU_APR_REGGCPNKB"],
      page: MainApprovalInvoiceGC,
    },
    {
      exact: true,
      route: "/human-resource/approval/pembayaran-invoice/detail/:id",
      hak: ["HRDU", "HRDU_APR_REGGCPNKB"],
      page: DetailInvoiceGC,
    },

    // Approval Non Voucher Invoice
    {
      exact: true,
      route: "/human-resource/approval/non-voucher-invoice",
      hak: ["HRDU", "HRDU_APR_REGGCNON"],
      page: MainApprovalNonVoucherInvoice,
    },
    {
      exact: true,
      route: "/human-resource/approval/non-voucher-invoice/detail/:id",
      hak: ["HRDU", "HRDU_APR_REGGCNON"],
      page: DetailNonVoucherInvoice,
    },
    // Approval Bayar DP Vendor
    {
      exact: true,
      route: "/human-resource/approval/bayar-dp-vendor",
      hak: ["HRDU", "HRDU_APR_REGGCBDPV"],
      page: MainApprovalBayarDPVendor,
    },
    {
      exact: true,
      route: "/human-resource/approval/bayar-dp-vendor/detail/:id",
      hak: ["HRDU", "HRDU_APR_REGGCBDPV"],
      page: DetailBayarDPVendor,
    },

    // Approval Penerimaan DP Customer
    {
      exact: true,
      route: "/human-resource/approval/penerimaan-dp-customer",
      hak: ["HRDU", "HRDU_APR_REGGCPDPK"],
      page: MainApprovalPenerimaanDPCustomer,
    },
    {
      exact: true,
      route: "/human-resource/approval/penerimaan-dp-customer/detail/:id",
      hak: ["HRDU", "HRDU_APR_REGGCPDPK"],
      page: DetailPenerimaanDPCustomer,
    },
    // Approval Pengajuan Petty Cash
    {
      exact: true,
      route: "/human-resource/approval/pengajuan-petty-cash",
      hak: ["HRDU"].concat(generateSpecialHAK("HRDU_APR_REGPC")),
      page: MainApprovalPengajuanPettyCash,
    },
    {
      exact: true,
      route: "/human-resource/approval/pengajuan-petty-cash/detail/:id",
      hak: ["HRDU"].concat(generateSpecialHAK("HRDU_APR_REGPC")),
      page: DetailApprovalPengajuanPettyCash,
    },

    // Approval Penerima Petty Cash
    {
      exact: true,
      route: "/human-resource/approval/penerima-petty-cash",
      hak: ["HRDU", "HRDU_APR_REGPEC"],
      page: MainApprovalPenerimaPettyCash,
    },
    {
      exact: true,
      route: "/human-resource/approval/penerima-petty-cash/detail/:id",
      hak: ["HRDU", "HRDU_APR_REGPEC"],
      page: DetailApprovalPenerimaPettyCash,
    },

    // Approval Realisasi Petty Cash
    {
      exact: true,
      route: "/human-resource/approval/realisasi-petty-cash",
      hak: ["HRDU"].concat(generateSpecialHAK("HRDU_APR_REGRPC")),
      page: MainApprovalRealisasiPettyCash,
    },
    {
      exact: true,
      route: "/human-resource/approval/realisasi-petty-cash/detail/:id",
      hak: ["HRDU"].concat(generateSpecialHAK("HRDU_APR_REGRPC")),
      page: DetailApprovalRealisasiPettyCash,
    },

    // Approval Penerimaan Petty Cash
    {
      exact: true,
      route: "/human-resource/approval/penerimaan-petty-cash",
      hak: ["HRDU"].concat(generateSpecialHAK("HRDU_APR_REGPNPC")),
      page: MainApprovalPenerimaanPettyCash,
    },
    {
      exact: true,
      route: "/human-resource/approval/penerimaan-petty-cash/detail/:id",
      hak: ["HRDU"].concat(generateSpecialHAK("HRDU_APR_REGPNPC")),
      page: DetailApprovalPenerimaanPettyCash,
    },

    // Approval Permintaan Aset
    {
      exact: true,
      route: "/human-resource/approval/permintaan-aset",
      hak: ["HRDU", "HRDU_APR_RPA"],
      page: MainApprovalPermintaanAset,
    },
    {
      exact: true,
      route: "/human-resource/approval/permintaan-aset/detail/:id",
      hak: ["HRDU", "HRDU_APR_RPA"],
      page: DetailPermintaanAset,
    },

    // Approval Seleksi Vendor Aset
    {
      exact: true,
      route: "/human-resource/approval/seleksi-vendor-aset",
      hak: ["HRDU", "HRDU_APR_RSVA"],
      page: MainApprovalSeleksiVendorAset,
    },
    {
      exact: true,
      route: "/human-resource/approval/seleksi-vendor-aset/detail/:id",
      hak: ["HRDU", "HRDU_APR_RSVA"],
      page: DetailSeleksiVendorAset,
    },

    // Approval Purchase Order Aset
    {
      exact: true,
      route: "/human-resource/approval/purchase-order-aset",
      hak: ["HRDU", "HRDU_APR_RPOA"],
      page: MainApprovalPurchaseOrderAset,
    },
    {
      exact: true,
      route: "/human-resource/approval/purchase-order-aset/detail",
      hak: ["HRDU", "HRDU_APR_RPOA"],
      page: DetailApprovalPurchaseOrderAset,
    },

    // Approval Penerimaan Aset
    {
      exact: true,
      route: "/human-resource/approval/penerimaan-aset",
      hak: ["HRDU", "HRDU_APR_RPAS"],
      page: MainApprovalPenerimaanAset,
    },
    {
      exact: true,
      route: "/human-resource/approval/penerimaan-aset/detail/:id",
      hak: ["HRDU", "HRDU_APR_RPAS"],
      page: DetailApprovalPenerimaanAset,
    },

    // TRANSAKSI

    // Pengajuan Petty Cash
    {
      exact: true,
      route: "/human-resource/transaksi/pengajuan-petty-cash",
      hak: ["HRDU", "HRDU_TRN_PJC"],
      page: PengajuanPettyCashList,
    },
    {
      exact: true,
      route: "/human-resource/transaksi/pengajuan-petty-cash/tambah/:id",
      hak: ["HRDU", "HRDU_TRN_PJC"],
      page: PengajuanPettyCashCreate,
    },

    // Penerimaan Petty Cash
    {
      exact: true,
      route: "/human-resource/transaksi/penerimaan-petty-cash",
      hak: ["HRDU", "HRDU_TRN_PNP"],
      page: PenerimaanPettyCashList,
    },
    {
      exact: true,
      route:
        "/human-resource/transaksi/penerimaan-petty-cash/mutasi-petty-cash",
      hak: ["HRDU", "HRDU_TRN_PNP"],
      page: PenerimaanMutasiPettyCashList,
    },
    {
      exact: true,
      route: "/human-resource/transaksi/penerimaan-petty-cash/tambah",
      hak: ["HRDU", "HRDU_TRN_PNP"],
      page: PenerimaanPettyCashTambah,
    },
    {
      exact: true,
      route: "/human-resource/transaksi/penerimaan-petty-cash/ubah/:id",
      hak: ["HRDU", "HRDU_TRN_PNP"],
      page: PenerimaanPettyCashUbah,
    },
    {
      exact: true,
      route: "/human-resource/transaksi/penerimaan-petty-cash/detail/:id",
      hak: ["HRDU", "HRDU_TRN_PNP"],
      page: PenerimaanPettyCashDetail,
    },

    // Realisasi Petty Cash
    {
      exact: true,
      route: "/human-resource/transaksi/realisasi-petty-cash",
      hak: ["HRDU", "HRDU_TRN_RPC"],
      page: RealisasiPettyCashList,
    },
    {
      exact: true,
      route:
        "/human-resource/transaksi/realisasi-petty-cash/penerimaan-petty-cash",
      hak: ["HRDU", "HRDU_TRN_RPC"],
      page: PenerimaanRealisasiPettyCashList,
    },
    {
      exact: true,
      route: "/human-resource/transaksi/realisasi-petty-cash/tambah/:id",
      hak: ["HRDU", "HRDU_TRN_RPC"],
      page: RealisasiPettyCashTambah,
    },

    {
      exact: true,
      route: "/human-resource/transaksi/realisasi-petty-cash/ubah/:id",
      hak: ["HRDU", "HRDU_TRN_RPC"],
      page: RealisasiPettyCashUbah,
    },
    {
      exact: true,
      route: "/human-resource/transaksi/realisasi-petty-cash/detail/:id",
      hak: ["HRDU", "HRDU_TRN_RPC"],
      page: RealisasiPettyCashDetail,
    },

    // Pengembalian Sisa Petty Cash
    {
      exact: true,
      route: "/human-resource/transaksi/pengembalian-sisa-petty-cash",
      hak: ["HRDU", "HRDU_TRN_PSPC"],
      page: PengembalianSisaPettyCashList,
    },
    {
      exact: true,
      route:
        "/human-resource/transaksi/pengembalian-sisa-petty-cash/realisasi-petty-cash",
      hak: ["HRDU", "HRDU_TRN_PSPC"],
      page: RealisasiPengembalianSisaPettyCashList,
    },
    {
      exact: true,
      route:
        "/human-resource/transaksi/pengembalian-sisa-petty-cash/tambah/:id",
      hak: ["HRDU", "HRDU_TRN_PSPC"],
      page: PengembalianSisaPettyCashTambah,
    },
    {
      exact: true,
      route:
        "/human-resource/transaksi/pengembalian-sisa-petty-cash/detail/:id",
      hak: ["HRDU", "HRDU_TRN_PSPC"],
      page: PengembalianSisaPettyCashDetail,
    },

    // Approval Input Biaya Angkut
    {
      exact: true,
      route: "/human-resource/approval/input-biaya-angkut",
      hak: ["HRDU", "HRDU_APR_REGAPPHBA"],
      page: MainApprovalInputBiayaAngkut,
    },
    {
      exact: true,
      route: "/human-resource/approval/input-biaya-angkut/detail/:id",
      hak: ["HRDU", "HRDU_APR_REGAPPHBA"],
      page: DetailInputBiayaAngkut,
    },

    // Approval Input Faktur Penjualan
    {
      exact: true,
      route: "/human-resource/approval/input-faktur-penjualan",
      hak: ["HRDU", "HRDU_APR_REGAPPFP"],
      page: MainApprovalInputFakturPenjualan,
    },
    {
      exact: true,
      route: "/human-resource/approval/input-faktur-penjualan/detail/:id",
      hak: ["HRDU", "HRDU_APR_REGAPPFP"],
      page: DetailInputFakturPenjualan,
    },

    // Approval Input Penerimaan Barang
    {
      exact: true,
      route: "/human-resource/approval/input-penerimaan-barang",
      hak: ["HRDU", "HRDU_APR_REGAPPHP"],
      page: MainApprovalInputPenerimaanBarang,
    },
    {
      exact: true,
      route: "/human-resource/approval/input-penerimaan-barang/detail/:id",
      hak: ["HRDU", "HRDU_APR_REGAPPHP"],
      page: DetailInputPenerimaanBarang,
    },
  ],
};
