import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  ReadButton,
  UpdateButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  Checkbox,
} from "components";
import {
  DateConvert,
  RupiahConvert,
  PageNumber as TableNumber,
} from "utilities";
import { InputBiayaAngkutApi } from "api";
import { ButtonCreate } from "@bhawanadevteam/react-core";

const ApprovalPPA = () => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataPPA, setDataPPA] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [searchTime, setSearchTime] = useState(null);

  const getInitialData = () => {
    setIsPageLoading(true);

    InputBiayaAngkutApi.get({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        console.log("data", data);
        setDataPPA(data?.data ?? []);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
        searchConfig.status &&
          setAlertConfig({
            show: true,
            variant: "primary",
            text: `Hasil dari pencarian: ${searchConfig.key}`,
          });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: e.target.value,
        });
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        });
      }, 750)
    );
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const getStatusApprovalLabel = (status_approval) => {
    switch (status_approval) {
      case "APP":
        return "APPROVED";
      case "REJ":
        return "REJECT";
      case "REV":
        return "REVISI";
      case "VER":
        return "VERIFIED";
      default:
        return "PENDING";
    }
  };

  useEffect(() => {
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [searchConfig.key, paginationConfig.page, paginationConfig.dataLength]);

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <Th>Informasi Pembuat Pengaju</Th>
            <ThFixed>Informasi Faktur Biaya Angkut</ThFixed>
            <Th>Vendor Biaya Angkut</Th>
            <Th>Proyek</Th>
            <Th>Keterangan</Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {dataPPA.map((val, index) => (
            <Tr key={index}>
              <TdFixed>
                {TableNumber(
                  paginationConfig.page,
                  paginationConfig.dataLength,
                  index
                )}
              </TdFixed>
              <TdFixed>
                <div>{val?.nama_karyawan_pengaju ?? "-"}</div>
                <div>{val?.nama_jabatan_pengaju ?? "-"}</div>
              </TdFixed>
              <TdFixed>
                <div>
                  {val?.tanggal
                    ? DateConvert(new Date(val?.tanggal)).defaultDMY
                    : "-"}
                </div>
                <div>{val?.no_faktur ?? "-"}</div>
              </TdFixed>
              <Td>{val?.nama_vendor ?? "-"}</Td>
              <Td>{val?.nama_proyek ?? "-"}</Td>
              <Td>{val?.ket_proyek ?? "-"}</Td>
              <Td>
                <ButtonCreate
                  size="sm"
                  noText
                  icon
                  onClick={() =>
                    history.push(
                      `/human-resource/approval/input-biaya-angkut/detail/${val.id_trx}`,
                      {
                        no_transaksi: val.no_faktur,
                      }
                    )
                  }
                />
              </Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch
                placeholder="Cari RAE"
                // value={searchConfig.key}
                onChange={onInputSearchChange}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection></CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataPPA ? (
        dataPPA.length > 0 ? (
          <PageContent />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}
    </CRUDLayout>
  );
};

export default ApprovalPPA;
