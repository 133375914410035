import React from "react";

const ThFixed = ({
  children,
  colSpan,
  rowSpan,
  className,
  width,
  noPadding,
  style,
  fontSize,
  ...rest
}) => {
  return (
    <th
      {...rest}
      width={width}
      colSpan={colSpan}
      rowSpan={rowSpan}
      className="p-1 px-2 text-center align-middle"
      style={{
        width: "20px",
        fontSize: "14px",
      }}
    >
      {children}
    </th>
  );
};

export default ThFixed;
