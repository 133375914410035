import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  ReadButton,
  UpdateButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  Checkbox,
  ApprovalStatusButton,
} from "components";
import { DateConvert, PageNumber as TableNumber } from "utilities";
import { PermintaanAsetApi } from "api";

const HistoryApprovalPermintaanAset = () => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataSuratJalan, setDataSuratJalan] = useState([]);

  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [searchTime, setSearchTime] = useState(null);

  const getInitialData = () => {
    setIsPageLoading(true);

    PermintaanAsetApi.getHistory({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        const dataDeliveryOrder = data.data;
        setDataSuratJalan(dataDeliveryOrder ? dataDeliveryOrder : []);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
        // searchConfig.status &&
        //   setAlertConfig({
        //     show: true,
        //     variant: "primary",
        //     text: `Hasil dari pencarian: ${searchConfig.key}`,
        //   });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;
    if (searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: e.target.value,
        });
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        });
      }, 750)
    );
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const generateStatus = (status) => {
    if (status.toUpperCase() === "REV") {
      return {
        variant: "outline-revised",
        label: "REVISION",
      };
    }

    if (status.toUpperCase() === "APP") {
      return {
        variant: "outline-success",
        label: "APPROVED",
      };
    }

    if (status.toUpperCase() === "VER") {
      return {
        variant: "outline-verified",
        label: "VERIFIED",
      };
    }

    return {
      variant: "outline-dark",
      label: "PENDING",
    };
  };

  useEffect(() => {
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [searchConfig.key, paginationConfig.page, paginationConfig.dataLength]);

  const PageContent = () => {
    const ItemBarangCollapse = ({ data }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false);

      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data.map((brg, index) => {
              if (isReadMoreClick) {
                return (
                  <li key={index} index={index} className="text-capitalize">
                    {brg.nama_aset} | {brg.keperluan} | {brg.metode_pengadaan}
                  </li>
                );
              } else {
                return (
                  index <= 2 && (
                    <li key={index} index={index} className="text-capitalize">
                      {brg.nama_aset} | {brg.keperluan} | {brg.metode_pengadaan}
                    </li>
                  )
                );
              }
            })}
          </ul>
          {data.length <= 3 ? (
            ""
          ) : (
            <div
              className="text-primary"
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                // fontWeight: "bold",
              }}
              onClick={() => {
                setisReadMoreClick((prev) => !prev);
              }}
            >
              {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
            </div>
          )}
        </>
      );
    };
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <Th style={{ width: 200 }}>Informasi Pembuat Pengajuan</Th>
            <ThFixed>Informasi Permintaan Aset</ThFixed>
            <Th>Nama Item Aset</Th>
            <ThFixed>Status Approval</ThFixed>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {dataSuratJalan.map((val, index) => (
            <Tr key={index}>
              <TdFixed>
                {TableNumber(
                  paginationConfig.page,
                  paginationConfig.dataLength,
                  index
                )}
              </TdFixed>
              <Td>
                <div>{val.nama_karyawan ? val.nama_karyawan : "-"}</div>
                <div>{val.nama_jabatan ? val.nama_jabatan : "-"}</div>
              </Td>
              <TdFixed>
                <div className="text-left">
                  {val.tgl_permintaan_aset
                    ? DateConvert(new Date(val.tgl_permintaan_aset)).defaultDMY
                    : "-"}
                </div>
                <div className="text-left">
                  {val.no_transaksi ? val.no_transaksi : "-"}
                </div>
              </TdFixed>
              <Td>
                {val.detail ? (
                  val.detail.length > 0 ? (
                    <ItemBarangCollapse data={val.detail} />
                  ) : (
                    "-"
                  )
                ) : (
                  "-"
                )}
              </Td>
              <TdFixed>
                <ApprovalStatusButton
                  variant={generateStatus(val.status_approval).variant}
                >
                  {generateStatus(val.status_approval).label}
                </ApprovalStatusButton>
              </TdFixed>
              <Td>
                <div className="d-flex justify-content-center">
                  <ReadButton
                    onClick={() =>
                      history.push(
                        `/human-resource/approval/permintaan-aset/detail/${val.id_permintaan_aset}`,
                        {
                          no_permintaan_aset: val.no_transaksi,
                          tab: "history",
                        }
                      )
                    }
                  />
                </div>
              </Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch
                // value={searchConfig.key}
                onChange={onInputSearchChange}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection></CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataSuratJalan ? (
        dataSuratJalan.length > 0 ? (
          <PageContent />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}
    </CRUDLayout>
  );
};

export default HistoryApprovalPermintaanAset;
