import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Card, Nav } from "react-bootstrap";

// TABS
import ApprovalRAP from "./ApprovalRAP";
import HistoryApprovalRAP from "./HistoryApprovalRAP";

const MainApprovalRAP = ({ setNavbarTitle }) => {
  useEffect(() => {
    setNavbarTitle("Approval RAP");
    return () => {};
  }, []);

  const [tabs, setTabs] = useState("approval"); //State untuk menampung tabs yang aktif

  // Konfigurasi komponen tabs
  const tabsConfig = [
    {
      tab: "approval",
      label: "Approval",
      component: () => <ApprovalRAP />,
    },
    {
      tab: "history",
      label: "History",
      component: () => <HistoryApprovalRAP />,
    },
  ];

  // Menangani perubahan pada tabs
  const onTabsChangeHandler = (e, newPage) => {
    e.preventDefault();
    setTabs(newPage);
  };

  useEffect(() => {
    setTabs(tabsConfig[0].tab);
  }, []);

  const TabsNav = ({ tab, label }) => (
    <Nav.Item>
      <Nav.Link href={`#${tab}`} onClick={(e) => onTabsChangeHandler(e, tab)}>
        {label}
      </Nav.Link>
    </Nav.Item>
  );

  return (
    <Card>
      <Card.Header>
        <Nav variant="tabs" defaultActiveKey={`#${tabs}`}>
          <TabsNav tab="approval" label="Approval" />
          <TabsNav tab="history" label="History" />
        </Nav>
      </Card.Header>
      <Card.Body>
        {tabs === "approval" ? <ApprovalRAP /> : <HistoryApprovalRAP />}
      </Card.Body>
    </Card>
  );
};

export default MainApprovalRAP;
