import {
  ApprovalStatusButton,
  DataStatus,
  TBody,
  THead,
  Table,
  Td,
  TdFixed,
  Th,
  ThFixed,
  Tr,
} from "components";
import { useFormikContext } from "formik";
import React from "react";
import { DateConvert, RupiahConvert } from "utilities";
import { STATUS_APPROVAL } from "../__PengajuanPettyCashUtils__";

export const TablePengajuanPettyCashBelumDisetujui = () => {
  const { values } = useFormikContext();

  const totalPengajuan =
    values?.belum_disetujui?.length > 0
      ? values?.belum_disetujui?.reduce(
          (acc, curr) => acc + parseFloat(curr.nominal_pengajuan ?? 0),
          0
        )
      : 0;
  return (
    <Table>
      <THead>
        <Tr>
          <ThFixed>No.</ThFixed>
          <ThFixed>Informasi Pengajuan Petty Cash</ThFixed>
          <Th>Divisi</Th>
          <Th>Keperluan</Th>
          <ThFixed>Status Approval</ThFixed>
          <ThFixed>Pengajuan Petty Cash</ThFixed>
        </Tr>
      </THead>
      <TBody>
        {values?.belum_disetujui?.length > 0 ? (
          <>
            {values?.belum_disetujui?.map((val, ind) => (
              <Tr key={ind}>
                <TdFixed>{ind + 1}</TdFixed>
                <TdFixed>
                  <div>
                    {val?.tgl_pengajuan_petty_cash
                      ? DateConvert(new Date(val?.tgl_pengajuan_petty_cash))
                          .custom
                      : "-"}
                  </div>
                  <div>{val?.no_pengajuan_petty_cash ?? "-"}</div>
                </TdFixed>
                <Td>{val?.kode_modul ?? "-"}</Td>
                <Td>{val?.keperluan ?? "-"}</Td>
                <TdFixed>
                  <ApprovalStatusButton
                    variant={
                      STATUS_APPROVAL[val.status_approval ?? "PEN"]?.variant
                    }
                  >
                    {STATUS_APPROVAL[val.status_approval ?? "PEN"]?.label}
                  </ApprovalStatusButton>
                </TdFixed>
                <Td textRight>
                  {
                    RupiahConvert(
                      parseFloat(val?.nominal_pengajuan ?? 0).toString()
                    ).getWithComa
                  }
                </Td>
              </Tr>
            ))}
            <Tr>
              <Td className="text-right font-weight-bold" colSpan="5">
                TOTAL PENGAJUAN YANG BELUM DITERIMA
              </Td>
              <Td className="text-right font-weight-bold">
                {RupiahConvert(String(totalPengajuan ?? 0)).getWithComa}
              </Td>
            </Tr>
          </>
        ) : (
          <Tr>
            <Td colSpan={5}>
              <DataStatus text="Tidak Ada Data" />
            </Td>
          </Tr>
        )}
      </TBody>
    </Table>
  );
};
