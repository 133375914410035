import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { Formik, useFormikContext } from "formik";
import {
  Input,
  InfoItemHorizontal,
  Select,
  TextArea,
  ButtonBack,
  ButtonCreate,
  ButtonDetail,
  ButtonUpdate,
} from "components2";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { DateConvert } from "utilities";
import TabGambar from "./TabGambar";
import TabFile from "./TabFile";
import { DatePicker, InfoItemVertical, SelectSearch } from "components";
import { InputBuktiPenerimaan } from "./InputBuktiPenerimaan";

const InfoPurchaseOrderAset = () => {
  const { values } = useFormikContext();
  return (
    <>
      <Row>
        <Col>
          <InfoItemHorizontal
            label="Tgl. Purchase Order Aset"
            text={
              values?.tgl_purchase_order_aset
                ? DateConvert(new Date(values?.tgl_purchase_order_aset)).detail
                : "-"
            }
          />

          <InfoItemHorizontal
            label="No. Purchase Order Aset"
            text={values?.no_purchase_order_aset ?? "-"}
          />

          <InfoItemHorizontal
            label="Tgl. Seleksi Vendor Aset"
            text={
              values?.tgl_seleksi_vendor_aset
                ? DateConvert(new Date(values?.tgl_seleksi_vendor_aset)).detail
                : "-"
            }
          />

          <InfoItemHorizontal
            label="No. Seleksi Vendor Aset"
            text={values?.no_seleksi_vendor_aset ?? "-"}
          />
        </Col>
        <Col>
          <InfoItemHorizontal
            label="Grup Aset"
            text={values?.nama_grup_aset ?? "-"}
          />
          <InfoItemHorizontal
            label="Kategori Aset"
            text={values?.nama_kategori_aset ?? "-"}
          />
          <InfoItemHorizontal
            label="Jenis Aset"
            text={values?.nama_jenis_aset ?? "-"}
          />
          <InfoItemHorizontal
            label="Item Aset Yang Diminta"
            text={values?.nama_aset ?? "-"}
          />
          <InfoItemHorizontal
            label="Keperluan"
            text={values?.keperluan ?? "-"}
          />
        </Col>
      </Row>
      <Card className="mt-3">
        <Tab.Container defaultActiveKey="tabGambar">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="tabGambar">Gambar</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tabFile">File</Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Tab.Content>
            <Tab.Pane eventKey="tabGambar">
              <div className="m-2">
                <TabGambar readOnly />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="tabFile">
              <div className="m-2">
                <TabFile readOnly />
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Card>
    </>
  );
};

const InfoPenerimaanAset = () => {
  const { values } = useFormikContext();
  return (
    <Row>
      <Col>
        <InfoItemVertical
          label="Tgl. Penerimaan Aset"
          text={
            values?.tgl_penerimaan_aset
              ? DateConvert(new Date(values?.tgl_penerimaan_aset)).detail
              : "-"
          }
        />
        <InfoItemVertical
          label="No. Penerimaan Aset"
          text={values?.no_penerimaan_aset ?? "-"}
        />
        <InfoItemVertical
          label="Surat Jalan Vendor"
          text={values?.no_surat_jalan_vendor ?? "-"}
        />
        <InfoItemVertical
          label="Pentugas Penerimaan"
          text={values?.nama_petugas_penerimaan ?? "-"}
        />
      </Col>
      <Col>
        {values?.bukti_penerimaan && (
          <img
            style={{ maxWidth: 180 }}
            src={values?.bukti_penerimaan}
            alt=""
            className="mb-4 img-fluid"
          />
        )}
      </Col>
    </Row>
  );
};

export const FormPermintaanAsetContent = ({ action }) => {
  const { values, setValues, handleChange, errors, touched, handleSubmit } =
    useFormikContext();
  const history = useHistory();

  return (
    <Fragment>
      <div className="d-flex justify-content-between mb-2">
        <small className="font-weight-bold">
          {action === "create"
            ? "Tambah Data Penerimaan Aset"
            : action === "update"
            ? "Ubah Data Penerimaan Aset"
            : "Detail Data Penerimaan Aset"}
        </small>

        <ButtonBack
          size="sm"
          onClick={() =>
            history.push("/human-resource/approval/penerimaan-aset")
          }
        />
      </div>
      <Card>
        <Card.Body>
          <InfoPurchaseOrderAset />
          <hr />
          <InfoPenerimaanAset />
        </Card.Body>
      </Card>

      <div className="d-flex justify-content-end mt-3">
        {action === "create" && (
          <ButtonCreate text="Simpan" size="sm" onClick={handleSubmit} />
        )}
        {action === "update" && (
          <ButtonUpdate variant="success" size="sm" onClick={handleSubmit} />
        )}
      </div>
    </Fragment>
  );
};
