import * as Yup from "yup";

export const formValidationSchema = Yup.object().shape({
  tgl_pengajuan_petty_cash: Yup.string().required(
    "Pilih Tanggal Pengajuan Petty Cash"
  ),
  kode_modul: Yup.string().required("Pilih Divisi"),
  keperluan: Yup.string().required("Masukkan Keperluan"),
  nominal_pengajuan: Yup.string()
    .required("Masukkan Nominal Pengajuan Petty Cash")
    .test(
      "value should equal or smaller than maksimal pengajuan field",
      "Nominal pengajuan tidak boleh lebih dari maksimal nilai pengajuan",
      (item, values) =>
        parseFloat(item) <= parseFloat(values.parent.maksimal_pengajuan)
    ),
});
