import React, { Fragment } from 'react';
import { Form } from 'react-bootstrap';

export const CustomUploadFile = ({
  label,
  error,
  errorText,
  onChange,
  fileName,
}) => {
  return (
    <Fragment>
      <small className="text-capitalize mt-2">{label}</small>
      <Form.File custom accept="image/png, image/jpeg">
        <Form.File.Input
          size={'sm'}
          isInvalid={error}
          onChange={onChange ?? null}
        />
        <Form.File.Label data-browse="Upload">{fileName}</Form.File.Label>
        <Form.Control.Feedback type={!!error ? 'invalid' : ''}>
          {!!error && errorText}
        </Form.Control.Feedback>
      </Form.File>
    </Fragment>
  );
};
