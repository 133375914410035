// React
import React from "react";

// Form
import { Formik, useFormikContext } from "formik";

// Components
import { DatePicker, FilterModal } from "components";
import { Select } from "components2";

// API
import { useQuery } from "react-query";

import { DateConvert } from "utilities";
import PengajuanPettyCashApi from "../__PengajuanPettyCashApi__";

export const ModalFilter = ({ toggle }) => {
  const { values, handleSubmit, setValues } = useFormikContext();

  const { data: unitProduksi, isFetching: loadingUnitProduksi } = useQuery(
    ["unitProduksi", "dropdown"],
    () => PengajuanPettyCashApi.getDropdownUnitProduksi()
  );

  const { data: statusApproval, isFetching: loadingStatusApproval } = useQuery(
    ["statusApproval", "dropdown"],
    () => PengajuanPettyCashApi.getDropdownStatusApproval()
  );

  const onTanggalChange = (
    dates,
    values,
    setValues,
    value_awal,
    value_akhir
  ) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      [value_awal]: startDate,
      [value_akhir]: endDate,
    });
  };

  // HANDLE CHANGE
  const onResetButtonClick = (setValues) => {
    const myPromise = new Promise((resolve) => {
      setValues({
        tgl_pengajuan_petty_cash_start: undefined,
        tgl_pengajuan_petty_cash_end: undefined,
        id_unit_produksi: undefined,
        status_approval: undefined,
      });

      setTimeout(() => resolve(), 300);
    });

    myPromise.then(() => {});
  };

  return (
    <FilterModal
      isToggle
      toggleModal={toggle}
      onResetButtonClick={() => onResetButtonClick(setValues)}
      onFilterButtonClick={handleSubmit}
    >
      <DatePicker
        selectsRange
        label="Tgl. Pengajuan Petty Cash"
        placeholderText="Pilih Tgl. Pengajuan Petty Cash"
        startDate={
          values?.tgl_pengajuan_petty_cash_start
            ? new Date(values?.tgl_pengajuan_petty_cash_start)
            : ""
        }
        endDate={
          values?.tgl_pengajuan_petty_cash_end
            ? new Date(values?.tgl_pengajuan_petty_cash_end)
            : ""
        }
        onChange={(dates) =>
          onTanggalChange(
            dates,
            values,
            setValues,
            "tgl_pengajuan_petty_cash_start",
            "tgl_pengajuan_petty_cash_end"
          )
        }
        monthsShown={2}
      />

      <Select
        label="Unit Produksi"
        placeholder="Pilih unit produksi"
        name="id_unit_produksi"
        options={unitProduksi ?? []}
        loading={loadingUnitProduksi}
        onChange={async (val) =>
          setValues({
            ...values,
            id_unit_produksi: val.value,
          })
        }
        defaultValue={unitProduksi?.find(
          (val) => val.value === values.id_unit_produksi
        )}
      />

      <Select
        label="Status Approval"
        placeholder="Pilih status approval"
        name="status_approval"
        options={statusApproval ?? []}
        loading={loadingStatusApproval}
        onChange={async (val) =>
          setValues({
            ...values,
            status_approval: val.value,
          })
        }
        defaultValue={statusApproval?.find(
          (val) => val.value === values.status_approval
        )}
      />
    </FilterModal>
  );
};
