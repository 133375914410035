// React
import React from "react";

// Form
import { Formik, useFormikContext } from "formik";

// Components
import { DatePicker, FilterModal } from "components";
import { Select } from "components2";

// API
import { useQuery } from "react-query";
import PenerimaanPettyCashApi from "../__PenerimaanPettyCashApi__";
import { DateConvert } from "utilities";

export const ModalFilter = ({ toggle, preFormPage = false }) => {
  const { values, handleSubmit, setValues } = useFormikContext();

  const { data: unitProduksi, isFetching: loadingUnitProduksi } = useQuery(
    ["unitProduksi", "dropdown"],
    () => PenerimaanPettyCashApi.getDropdownUnitProduksi()
  );

  const { data: statusApproval, isFetching: loadingStatusApproval } = useQuery(
    ["statusApproval", "dropdown"],
    () => PenerimaanPettyCashApi.getDropdownStatusApproval()
  );

  const onTanggalChange = (
    dates,
    values,
    setValues,
    value_awal,
    value_akhir
  ) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      [value_awal]: startDate,
      [value_akhir]: endDate,
    });
  };

  // HANDLE CHANGE
  const onResetButtonClick = (setValues) => {
    const myPromise = new Promise((resolve) => {
      setValues({
        id_pekerja: undefined,
      });

      setTimeout(() => resolve(), 300);
    });

    myPromise.then(() => {});
  };

  return (
    <FilterModal
      isToggle
      toggleModal={toggle}
      onResetButtonClick={() => onResetButtonClick(setValues)}
      onFilterButtonClick={handleSubmit}
    >
      {!preFormPage && (
        <DatePicker
          selectsRange
          label="Tgl. Penerimaan Petty Cash"
          placeholderText="Pilih Tgl. Penerimaan Petty Cash"
          startDate={
            values?.tgl_penerimaan_petty_cash_start
              ? new Date(values?.tgl_penerimaan_petty_cash_start)
              : ""
          }
          endDate={
            values?.tgl_penerimaan_petty_cash_end
              ? new Date(values?.tgl_penerimaan_petty_cash_end)
              : ""
          }
          onChange={(dates) =>
            onTanggalChange(
              dates,
              values,
              setValues,
              "tgl_penerimaan_petty_cash_start",
              "tgl_penerimaan_petty_cash_end"
            )
          }
          monthsShown={2}
        />
      )}

      <DatePicker
        selectsRange
        label="Tgl. Bukti Mutasi"
        placeholderText="Pilih Tgl. Bukti Mutasi"
        startDate={
          values?.tgl_mutasi_petty_cash_start
            ? new Date(values?.tgl_mutasi_petty_cash_start)
            : ""
        }
        endDate={
          values?.tgl_mutasi_petty_cash_end
            ? new Date(values?.tgl_mutasi_petty_cash_end)
            : ""
        }
        onChange={(dates) =>
          onTanggalChange(
            dates,
            values,
            setValues,
            "tgl_mutasi_petty_cash_start",
            "tgl_mutasi_petty_cash_end"
          )
        }
        monthsShown={2}
      />

      <Select
        label="Unit Produksi"
        placeholder="Pilih unit produksi"
        name="id_unit_produksi"
        options={unitProduksi ?? []}
        loading={loadingUnitProduksi}
        onChange={async (val) =>
          setValues({
            ...values,
            id_unit_produksi: val.value,
          })
        }
        defaultValue={unitProduksi?.find(
          (val) => val.value === values.id_unit_produksi
        )}
      />

      <DatePicker
        selectsRange
        label="Tgl. Awal Petty Cash"
        placeholderText="Pilih Tgl. Awal Petty Cash"
        startDate={
          values?.tgl_awal_petty_cash_start
            ? new Date(values?.tgl_awal_petty_cash_start)
            : ""
        }
        endDate={
          values?.tgl_awal_petty_cash_end
            ? new Date(values?.tgl_awal_petty_cash_end)
            : ""
        }
        onChange={(dates) =>
          onTanggalChange(
            dates,
            values,
            setValues,
            "tgl_awal_petty_cash_start",
            "tgl_awal_petty_cash_end"
          )
        }
        monthsShown={2}
      />

      <DatePicker
        selectsRange
        label="Tgl. Akhir Petty Cash"
        placeholderText="Pilih Tgl. Akhir Petty Cash"
        startDate={
          values?.tgl_akhir_petty_cash_start
            ? new Date(values?.tgl_akhir_petty_cash_start)
            : ""
        }
        endDate={
          values?.tgl_akhir_petty_cash_end
            ? new Date(values?.tgl_akhir_petty_cash_end)
            : ""
        }
        onChange={(dates) =>
          onTanggalChange(
            dates,
            values,
            setValues,
            "tgl_akhir_petty_cash_start",
            "tgl_akhir_petty_cash_end"
          )
        }
        monthsShown={2}
      />

      <Select
        label="Status Approval"
        placeholder="Pilih status approval"
        name="status_approval"
        options={statusApproval ?? []}
        loading={loadingStatusApproval}
        onChange={async (val) =>
          setValues({
            ...values,
            status_approval: val.value,
          })
        }
        defaultValue={statusApproval?.find(
          (val) => val.value === values.status_approval
        )}
      />
    </FilterModal>
  );
};
