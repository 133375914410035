// React
import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";

// Component
import { Row, Col } from "react-bootstrap";
import {
  CRUDLayout,
  BackButton,
  DataStatus,
  Th,
  ThFixed,
  THead,
  TBody,
  Tr,
  Td,
  TdFixed,
} from "../../components";
import { AuthContext, DateConvert } from "../../utilities";

// API
import Axios from "axios";
import { ProfilApi } from "api";

const Profil = ({ setNavbarTitle }) => {
  const { state } = useContext(AuthContext);
  const { id } = state;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [dataKaryawan, setDataKaryawan] = useState([]);
  const [dataKeluarga, setDataKeluarga] = useState([]);

  // fetch data
  const getKaryawan = () => {
    setIsLoading(true);

    Axios.all([ProfilApi.getOne(id), ProfilApi.getKeluarga(id)])
      .then(
        Axios.spread((karyawan, keluarga) => {
          setDataKaryawan(karyawan.data.data);
          setDataKeluarga(keluarga.data.data);
        })
      )
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setNavbarTitle("Profil Saya");
    getKaryawan();

    return () => {
      setIsLoading(false);
    };
  }, [setNavbarTitle]);

  const ColumnData = ({ title, className, children }) => (
    <div className={className ? className : "mb-4"}>
      <h6
        className="ml-1 text-dark"
        style={{
          fontSize: "17px",
          marginBottom: "12.5px",
        }}
      >
        <b>{title}</b>
      </h6>
      <div className="bg-white p-2 px-3 shadow-sm rounded">{children}</div>
    </div>
  );

  const ShowData = ({ title, text, line }) => (
    <div className="mb-2">
      <small className="text-secondary" style={{ opacity: 0.8 }}>
        {title}
      </small>
      <div
        className="text-dark"
        style={{
          fontWeight: 500,
        }}
      >
        {text ? text : "-"}
      </div>
      {line && <hr className="my-2" />}
    </div>
  );

  const DataAkun = () => (
    <ColumnData title="Data Akun">
      <ShowData title="Username" text={dataKaryawan.username} line={true} />
    </ColumnData>
  );

  const DataPribadi = () => (
    <ColumnData title="Data Pribadi">
      <ShowData
        title="Nama Pekerja"
        text={dataKaryawan.nama_karyawan}
        line={true}
      />
      <ShowData title="No. KTP" text={dataKaryawan.no_ktp} line={true} />
      <Row>
        <Col lg="6">
          <ShowData
            title="Tempat Lahir"
            text={dataKaryawan.tempat_lahir}
            line={true}
          />
        </Col>

        <Col lg="6">
          <ShowData
            title="Tanggal Lahir"
            text={
              dataKaryawan.tgl_lahir
                ? DateConvert(new Date(dataKaryawan.tgl_lahir)).detail
                : ""
            }
            line={true}
          />
        </Col>

        <Col lg="6">
          <ShowData
            title="Jenis Kelamin"
            text={
              dataKaryawan.jenis_kelamin === "L" ? "Laki-laki" : "Perempuan"
            }
            line={true}
          />
        </Col>

        <Col lg="6">
          <ShowData
            title="Agama"
            text={
              dataKaryawan.agama === "HD"
                ? "Hindu"
                : dataKaryawan.agama === "IS"
                ? "Islam"
                : dataKaryawan.agama === "KP"
                ? "Kristen Protestan"
                : dataKaryawan.agama === "KK"
                ? "Kristen Katholik"
                : dataKaryawan.agama === "BD"
                ? "Budha"
                : dataKaryawan.agama === "KO"
                ? "Khonghucu"
                : "-"
            }
            line={true}
          />
        </Col>
      </Row>
      <ShowData
        title="Status Perkawinan"
        text={
          dataKaryawan.status_perkawinan === "KW"
            ? "Kawin"
            : dataKaryawan.status_perkawinan === "CH"
            ? "Cerai Hidup"
            : dataKaryawan.status_perkawinan === "CM"
            ? "Cerai Mati"
            : "Belum Kawin"
        }
        line={true}
      />
    </ColumnData>
  );

  const DataPendidikan = () => (
    <ColumnData title="Data Pendidikan">
      <Row>
        <Col lg="6">
          <ShowData
            title="Jenjang Pendidikan Terakhir"
            text={dataKaryawan.nama_jenjang_pendidikan}
            line={true}
          />
        </Col>

        <Col lg="6">
          <ShowData
            title="Tahun Lulus"
            text={dataKaryawan.tahun_selesai}
            line={true}
          />
        </Col>
      </Row>

      <ShowData
        title="Lembaga Pendidikan"
        text={dataKaryawan.lembaga_pendidikan}
      />
    </ColumnData>
  );

  const DataKontak = () => (
    <ColumnData title="Data Kontak">
      <ShowData title="No. Telepon" text={dataKaryawan.no_telp} line={true} />
      <ShowData title="No. HP" text={dataKaryawan.no_hp} line={true} />
      <ShowData title="Email" text={dataKaryawan.email} line={true} />
      <ShowData
        title="Kontak Darurat"
        text={dataKaryawan.kontak_darurat}
        line={true}
      />
      <ShowData
        title="Keterangan Kontak Darurat"
        text={dataKaryawan.keterangan_kontak_darurat}
      />
    </ColumnData>
  );

  const DataAlamat = () => (
    <>
      <ColumnData title="Data Alamat" className="mb-3">
        <Row>
          <Col lg="6">
            <ShowData
              title="Provinsi Asal"
              text={dataKaryawan.nama_provinsi_asal}
              line={true}
            />
          </Col>

          <Col lg="6">
            <ShowData
              title="Kabupaten Asal"
              text={dataKaryawan.nama_kabupaten_asal}
              line={true}
            />
          </Col>

          <Col lg="6">
            <ShowData
              title="Kecamatan Asal"
              text={dataKaryawan.nama_kecamatan_asal}
              line={true}
            />
          </Col>

          <Col lg="6">
            <ShowData
              title="Desa Asal"
              text={dataKaryawan.nama_desa_asal}
              line={true}
            />
          </Col>

          <Col lg="6">
            <ShowData
              title="Alamat Asal"
              text={dataKaryawan.alamat_asal}
              line={true}
            />
          </Col>

          <Col lg="6">
            <ShowData title="Kode Pos" text={dataKaryawan.kode_pos_asal} />
          </Col>

          <Col lg="6">
            <ShowData
              title="Provinsi Saat Ini"
              text={dataKaryawan.nama_provinsi_saat_ini}
              line={true}
            />
          </Col>

          <Col lg="6">
            <ShowData
              title="Kabupaten Saat Ini"
              text={dataKaryawan.nama_kabupaten_saat_ini}
              line={true}
            />
          </Col>

          <Col lg="6">
            <ShowData
              title="Kecamatan Saat Ini"
              text={dataKaryawan.nama_kecamatan_saat_ini}
              line={true}
            />
          </Col>

          <Col lg="6">
            <ShowData
              title="Desa Saat Ini"
              text={dataKaryawan.nama_desa_saat_ini}
              line={true}
            />
          </Col>

          <Col lg="6">
            <ShowData
              title="Alamat Saat Ini"
              text={dataKaryawan.alamat_saat_ini}
              line={true}
            />
          </Col>

          <Col lg="6">
            <ShowData title="Kode Pos" text={dataKaryawan.kode_pos_saat_ini} />
          </Col>
        </Row>
      </ColumnData>
    </>
  );

  const DataKepegawaian = () => (
    <ColumnData title="Data Pekerja">
      <Row>
        <Col lg="6">
          <ShowData title="No. NPWP" text={dataKaryawan.no_npwp} line={true} />
        </Col>

        <Col lg="6">
          <ShowData
            title="Jenis Pekerja"
            text={dataKaryawan.nama_jenis_pekerja}
            line={true}
          />
        </Col>

        <Col lg="6">
          <ShowData
            title="Status Pekerja"
            text={dataKaryawan.nama_status_pekerja}
            line={true}
          />
        </Col>

        <Col lg="6">
          <ShowData
            title="Detail Status Pekerja"
            text={dataKaryawan.nama_status_pekerja_detail}
            line={true}
          />
        </Col>

        <Col lg="6">
          <ShowData
            title="Unit Produksi"
            text={dataKaryawan.nama_unit_produksi}
            line={true}
          />
        </Col>

        <Col lg="6">
          <ShowData
            title="Lokasi"
            text={dataKaryawan.nama_lokasi_kerja}
            line={true}
          />
        </Col>
      </Row>

      <Row>
        <Col lg>
          <ShowData
            title="Jabatan"
            text={dataKaryawan.nama_jabatan}
            line={true}
          />
        </Col>
      </Row>

      <Row>
        <Col lg="6">
          <ShowData
            title="Tanggal Bergabung"
            text={
              dataKaryawan.tgl_bergabung
                ? DateConvert(new Date(dataKaryawan.tgl_bergabung)).detail
                : ""
            }
            line={true}
          />
        </Col>

        <Col lg="6">
          <ShowData
            title="Tanggal Pengangkatan"
            text={
              dataKaryawan.tgl_pengangkatan
                ? DateConvert(new Date(dataKaryawan.tgl_pengangkatan)).detail
                : ""
            }
          />
        </Col>
      </Row>
    </ColumnData>
  );

  const DataKeluarga = () => (
    <ColumnData title="Data Keluarga">
      {dataKeluarga ? (
        <CRUDLayout.Table>
          <THead>
            <Th>No.</Th>
            <Th>Nama Anggota Keluarga</Th>
            <Th>Status Anggota Keluarga</Th>
            <Th>Tanggal Lahir</Th>
            <Th>Keterangan</Th>
          </THead>

          <TBody>
            {dataKeluarga.map((val, index) => (
              <Tr>
                <Td>{index + 1}</Td>
                <Td>{val.jenis_anggota_keluarga}</Td>
                <Td>{val.nama_anggota_keluarga}</Td>
                <Td>{val.tgl_lahir}</Td>
                <Td>{val.keterangan}</Td>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
      ) : (
        <DataStatus text="Tidak ada data keluarga" />
      )}
    </ColumnData>
  );

  const DataBank = () => (
    <ColumnData title="Data Bank">
      <ShowData title="Bank" text={dataKaryawan.nama_bank} line={true} />
      <ShowData
        title="No. Rekening"
        text={dataKaryawan.no_rekening}
        line={true}
      />
      <ShowData title="Atas Nama" text={dataKaryawan.atas_nama} line={true} />
      <ShowData title="Cabang Bank" text={dataKaryawan.cabang} line={true} />
    </ColumnData>
  );

  return (
    <CRUDLayout>
      <CRUDLayout.Head></CRUDLayout.Head>
      {isLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <>
          <Row>
            <Col md>
              <DataAkun />
              <hr />
              <DataKepegawaian />
              <hr />
              <DataKontak />
              <hr />
              <DataPendidikan />
            </Col>
            <Col md>
              <DataPribadi />
              <hr />
              <DataAlamat />
              <hr />
              <DataBank />
            </Col>
          </Row>
          <hr />
          <DataKeluarga />
        </>
      )}
    </CRUDLayout>
  );
};

export default Profil;
